import React, { useState } from 'react';
import { Grid, Button, Box } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import { Search } from './components';
import { useTranslation } from 'utils/translation';
import { Theme } from 'theme';

const classes = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  wrapper: {
    flexGrow: 1,
  },
  search: (theme: Theme) => ({
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 480,
      flexBasis: 480,
    },
  }),
  filterButton: {
    marginLeft: 'auto',
  },
  filterIcon: {
    marginRight: 1,
  },
};

const SearchBar = (props: any) => {
  const { initialValue, onFilter, onSearch, className, placeholder, ...rest } =
    props;
  const { t } = useTranslation();

  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <Grid
      {...rest}
      sx={classes.root}
      className={className}
      container
      spacing={3}
    >
      <Grid item sx={classes.wrapper}>
        <Search
          sx={classes.search}
          onSearch={onSearch}
          placeholder={placeholder}
          initialValue={initialValue}
        />
      </Grid>
      {onFilter && (
        <Box>
          <Grid item>
            <Button
              sx={classes.filterButton}
              color="primary"
              onClick={handleFilterOpen}
              size="small"
              variant="outlined"
            >
              <FilterListIcon sx={classes.filterIcon} /> {t('Show filters')}
            </Button>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default SearchBar;
