import React, { forwardRef } from 'react';
import css from './textarea.module.scss';
import { TextareaAutosize as TextareaAutosizeBase } from '@mui/base/TextareaAutosize';
import clsx from 'clsx';

type Props = {
  border?: boolean;
} & React.ComponentProps<typeof TextareaAutosizeBase>;

const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { border = true, ...rest } = props;

  return (
    <TextareaAutosizeBase
      {...rest}
      ref={ref}
      className={clsx(css.root, border && css.border)}
    />
  );
});

export default Textarea;
