import { SnackBar } from './types';
import { useContext, useEffect, useRef } from 'react';
import { SnackbarContext } from './SnackbarsProvider';

const useSnackBarContext = () => {
  const context = useContext(SnackbarContext);

  if (null === context) {
    throw new Error(
      'SnackbarContext is null. ' +
        'Did you forget to wrap your component into SnackBarsProvider?',
    );
  }

  return context;
};

type Options = {
  cleanupOnUnmount?: boolean;
};

const useSnackbars = ({ cleanupOnUnmount = true }: Options = {}) => {
  const { add, remove } = useSnackBarContext();
  const items = useRef<SnackBar[]>([]);

  useEffect(() => {
    return () => {
      if (cleanupOnUnmount) {
        items.current = items.current.filter(remove);
      }
    };
  }, []);

  return {
    add: (item: SnackBar) => {
      items.current.push(item);
      add(item);

      return () => {
        remove(item);
      };
    },
  };
};

export default useSnackbars;
