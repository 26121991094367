import { RootState } from '../../../store';

export const selectActiveResourceModal = (state: RootState) => {
  const { activeModalId, resource } = state.ui.resourceModal;

  return {
    id: activeModalId,
    resource
  };
};
