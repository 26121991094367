import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { filesActions } from '../actions';
import { File, UploadedFile } from '../index';

export function useFilesActions() {
  const dispatch = useDispatch();
  const fetchFiles = (params: object = {}, meta: object = {}) =>
    dispatch(filesActions.fetch.trigger(params, meta));
  const readFile = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.read.trigger(file));
  const createFile = (data: any) => dispatch(filesActions.create.trigger(data));
  const addToUploadQueue = (items: any[]) =>
    dispatch(filesActions.addToUploadQueue(items));
  const updateFile = (file: Pick<File, 'id'> & Partial<File>) =>
    dispatch(filesActions.update.trigger(file));
  const deleteFile = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.delete.trigger(file));
  const getThumb = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.getThumb.trigger(file));
  const getPreview = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.getPreview.trigger(file));
  const download = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.download.trigger(file));
  const share = (file: Pick<File, 'id'>) =>
    dispatch(filesActions.share.trigger(file));
  const cancelUpload = (file: Pick<UploadedFile, 'id'>) =>
    dispatch(filesActions.cancelUpload.trigger(file));
  const cancelUploadAll = () =>
    dispatch(filesActions.cancelUploadAll.trigger());

  const clearErrors = () => dispatch(filesActions.clearErrors());

  const moveFile = ({
    file,
    dirId
  }: {
    file: Pick<File, 'id'> & Partial<File>;
    dirId: number;
  }) =>
    dispatch(
      filesActions.move.trigger({
        file,
        dirId
      })
    );

  const actions = {
    fetchFiles,
    readFile,
    updateFile,
    moveFile,
    createFile,
    deleteFile,
    getThumb,
    getPreview,
    clearErrors,
    download,
    share,
    addToUploadQueue,
    cancelUpload,
    cancelUploadAll
  };

  // eslint-disable-next-line
  return useMemo<typeof actions>(() => actions, [dispatch]);
}
