import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';

interface Props {
  images: string[];
  index: number;
  onClose(): void;
}

const PhotoGallery: React.FC<Props> = ({ images, index, onClose }) => {
  const [imgIndex, setIndex] = useState(index);

  const handlePrev = () => {
    setIndex((imgIndex + images.length - 1) % images.length);
  };

  const handleNext = () => {
    setIndex((imgIndex + 1) % images.length);
  };

  return (
    <Lightbox
      mainSrc={images[imgIndex]}
      nextSrc={
        images.length > 1 ? images[(imgIndex + 1) % images.length] : undefined
      }
      prevSrc={
        images.length > 1
          ? images[(imgIndex + images.length - 1) % images.length]
          : undefined
      }
      onMovePrevRequest={images.length > 1 ? handlePrev : undefined}
      onMoveNextRequest={images.length > 1 ? handleNext : undefined}
      onCloseRequest={onClose}
    />
  );
};

export default PhotoGallery;
