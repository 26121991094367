import React, { PropsWithChildren } from 'react';
import {
  QueryClient,
  QueryClientProvider as BaseQueryClientProvider,
} from '@tanstack/react-query';

export const queryClient = new QueryClient();

export function refetchActiveQueries() {
  return queryClient.refetchQueries({ type: 'active' });
}

const QueryClientProvider = ({ children }: PropsWithChildren) => (
  <BaseQueryClientProvider client={queryClient}>
    {children}
  </BaseQueryClientProvider>
);

export default QueryClientProvider;
