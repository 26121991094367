import produce from 'immer';
import {
  createResourceReducer,
  ResourceState
} from '../../@common/reducers/resource-reducer';
import { File } from '../index';
import { FILES_PREFIX } from '../constants';
import { dirsActions } from '../../dirs/actions';
import { filesActions } from '../actions';
import { basketActions } from '../../basket/actions';

const initialState: ResourceState<File> = {
  items: {},
  order: [],
  isFetching: false,
  isSubmitting: false,
  didCreate: false,
  didUpdate: false,
  pendingItemId: null,
  errors: null
};

const baseFileReducer = createResourceReducer<File>(FILES_PREFIX);

const filesReducer: typeof baseFileReducer = (
  state = initialState,
  action
): ResourceState<File> => {
  switch (action.type) {
    case dirsActions.READ.SUCCESS: {
      const dir = action.payload;
      const files = dir.files || [];
      const nextState = produce(state, draft => {
        files.map((file: File) => (draft.items[file.id] = file));
      });

      return baseFileReducer(nextState, action);
    }

    case basketActions.fetch.SUCCESS: {
      const { files = [] } = action.payload;

      return produce(state, draft => {
        files.forEach((file: File) => {
          if (!state.items[file.id]) {
            draft.items[file.id] = file;
          }
        });
      });
    }

    case filesActions.CREATE.SUCCESS: {
      const { payload } = action;

      return produce(state, draft => {
        draft.didCreate = true;
        draft.items[payload.id] = payload;

        if (!payload.directory) {
          draft.order.push(payload.id);
        }
      });
    }

    case filesActions.read.SUCCESS: {
      const { payload } = action;
      return produce(state, draft => {
        if (draft.items[payload.id]) {
          draft.items[payload.id] = {
            ...draft.items[payload.id],
            ...payload
          };
        } else {
          draft.items[payload.id] = payload;
        }
      });
    }

    case filesActions.getThumb.TRIGGER: {
      const { id } = action.payload;

      return produce(state, draft => {
        if (!draft.items[id]) {
          draft.items[id] = action.payload;
        }
      });
    }

    case filesActions.getThumb.SUCCESS: {
      const { id, url } = action.payload;

      return produce(state, draft => {
        if (id && url && draft.items[id]) {
          draft.items[id].thumbnailUrl = url;
        }
      });
    }

    case filesActions.getPreview.SUCCESS: {
      const { id, url } = action.payload;

      return produce(state, draft => {
        if (id && url && draft.items[id]) {
          draft.items[id].previewUrl = url;
        }
      });
    }

    case filesActions.move.REQUEST: {
      return produce(state, draft => {
        const { dirId, file } = action.payload;
        const prevDirId = file.directoryId;

        if (!prevDirId) {
          draft.order = draft.order.filter(fileId => fileId !== file.id);
        }

        if (!dirId) {
          draft.order.push(file.id);
        }
      });
    }

    case filesActions.share.SUCCESS: {
      const { id, url } = action.payload;

      return produce(state, draft => {
        if (id && url && draft.items[id]) {
          draft.items[id].shareUrl = url;
        }
      });
    }

    case filesActions.search.SUCCESS: {
      return produce(state, draft => {
        const { files = [] } = action.payload;

        files.forEach((file: File) => {
          if (!draft.items[file.id]) {
            draft.items[file.id] = file;
          }
        });
      });
    }

    default:
      return baseFileReducer(state, action);
  }
};

export { filesReducer };
