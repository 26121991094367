import React from 'react';
import { Theme, Divider, Menu, MenuItem, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'utils/translation';
import { useUserIsAdmin } from '../../../../modules/auth/hooks';
import { withConfirm } from 'components';
import { WithConfirmProps } from '../../../../components/Confirmation/withConfirm';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fab: {
    margin: theme.spacing(1.5),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

interface Props extends WithConfirmProps {
  onClose?: () => void;
  onDelete?: () => void;
  onRename?: () => void;
  onMove?: () => void;
}

export const DrawerActions: React.FC<Props> = ({
  onClose,
  onDelete,
  onRename,
  onMove,
  confirm,
}) => {
  const classes = useStyles();
  const isAdmin = useUserIsAdmin();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleDrawerClose() {
    handleClose();
    onClose && onClose();
  }

  const handleDelete = () => {
    const confirmation = confirm(() => {
      onDelete && onDelete();
    });

    confirmation();
    handleClose();
  };

  function handleRename() {
    handleClose();
    onRename && onRename();
  }

  function handleMove() {
    handleClose();
    onMove && onMove();
  }

  return (
    <div className={classes.actions}>
      <IconButton onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="drawer-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {onRename && isAdmin && (
          <MenuItem onClick={handleRename}>
            <EditIcon className={classes.icon} />
            {t('Rename')}
          </MenuItem>
        )}
        {onMove && isAdmin && (
          <MenuItem onClick={handleMove}>
            <OpenWithIcon className={classes.icon} />
            {t('Move')}
          </MenuItem>
        )}
        {onDelete && isAdmin && (
          <MenuItem onClick={handleDelete}>
            <DeleteIcon className={classes.icon} />
            {t('Delete')}
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleDrawerClose}>
          <CloseIcon className={classes.icon} />
          {t('Close')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withConfirm<Props>(DrawerActions);
