import React from 'react';
import { FieldProps } from './types';
import { Checkbox, FormControlLabel } from '@mui/material';

const CheckboxField: React.FC<FieldProps> = ({
  value,
  name,
  onChange,
  settings = {},
}) => {
  const label = settings.label || '';

  return (
    <FormControlLabel
      control={
        <Checkbox name={name} checked={Boolean(value)} onChange={onChange} />
      }
      label={label}
    />
  );
};

export { CheckboxField };
