import { useContext } from 'react';
import ModalContext from './ModalContext';

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      'Modal context is null. Did you forget to wrap your component into ModalProvider?',
    );
  }

  return context;
};
