import React from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Typography,
  DialogActions,
  IconButton,
} from '@mui/material';
import { ModalClose } from '../../../components';
import useRemoveReport from '../hooks/use-remove-report';
import { queryCache } from 'react-query';
import { useTranslation } from 'utils/translation';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'utils/router';
import { refetchReports } from '../hooks/use-reports';
import { Report } from '../types';
import DeleteForevericon from '@mui/icons-material/DeleteForever';

const RemoveReport = ({ onClose, id, name }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [mutate] = useRemoveReport();

  const onRemove = async () => {
    const { parent } = await mutate({ id });
    onClose();
    void refetchReports();

    navigate(`/report/${parent}`);
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogContent sx={{ minWidth: 300, px: 4 }}>
        <ModalClose onClose={onClose} />
        <Box sx={{ pt: 2, pb: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            {t('Remove')}
          </Typography>
          <Typography variant="subtitle1">
            {t('confirmation_delete_description', { subject: name })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ pb: 2, px: 3, justifyContent: 'space-between' }}>
        <Button variant="outlined" onClick={onClose} color="inherit">
          {t('Cancel')}
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={onRemove}
          endIcon={<DeleteForeverIcon />}
        >
          {t('Remove')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RemoveReportButton: React.FC<{ id: Report['id'] }> = ({ id }) => {
  const { t } = useTranslation();
  const [active, setActive] = React.useState(false);

  return (
    <>
      <IconButton size="small" onClick={() => setActive(true)}>
        <DeleteForevericon fontSize="inherit" />
      </IconButton>
      {active && <RemoveReport id={id} onClose={() => setActive(false)} />}
    </>
  );
};

export default RemoveReportButton;
