import React from 'react';
import get from 'lodash/get';
import TextInput from 'components/v2/Input/TextInput';
import { useFormContext } from 'react-hook-form';
import { AdapterProps } from '../types';
import InputLabel from '../../InputLabel/InputLabel';
import FormControl from 'components/v2/FormControl/FormControl';
import { useFormError } from '../utils';
import mergeRefs from '../../../../utils/merge-refs';

export type TextAdapterProps = AdapterProps['text'] &
  React.ComponentProps<typeof TextInput>;

const TextAdapter = React.forwardRef((props: TextAdapterProps, ref) => {
  const { label, name, rules, ...rest } = props;
  const { register, formState } = useFormContext();
  const error = useFormError(get(formState.errors, name));
  const defaultValue = get(formState.defaultValues, name);
  const { ref: formRef, ...inputProps } = register(name, rules);

  return (
    <FormControl defaultValue={defaultValue}>
      {label && <InputLabel>{label}</InputLabel>}
      <TextInput
        ref={mergeRefs(formRef, ref)}
        error={error}
        {...rest}
        {...inputProps}
      />
    </FormControl>
  );
});

export default TextAdapter;
