import { shallowEqual, useSelector } from 'react-redux';
import {
  selectFormState,
  selectUploadingItems,
  selectUploadingItemById,
  selectFileThumbnail,
  selectFile
} from '../selectors';
import { RootState } from '../../../store';
import { File } from '../index';

export * from './use-file-list';
export * from './use-files-actions';

export function useFilesFormState() {
  return useSelector(selectFormState, shallowEqual);
}

export function useUploadingFileListIds() {
  return useSelector(selectUploadingItems, shallowEqual);
}

export function useUploadingFile(id: string) {
  return useSelector(
    (state: RootState) => selectUploadingItemById(state, id),
    shallowEqual
  );
}

export function useSingleFile(id: File['id']) {
  return useSelector((state: RootState) => selectFile(state, id), shallowEqual);
}

export function useFileThumbnail(id: number) {
  return useSelector(
    (state: RootState) => selectFileThumbnail(state, id),
    shallowEqual
  );
}
