import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  dirsGroupAccessActions,
  dirsUserAccessActions,
  dirsUserBlockedActions
} from './actions';
import { DirGroupAccessPayload, DirUserAccessPayload } from '.';
import { Dir } from '../dirs';
import {
  selectDirGroupAccess,
  selectDirUsersAccess,
  selectDirUsersBlocked
} from './selectors';
import { RootState } from '../../store';

export function useDirUsersAccess(dir: Dir) {
  return useSelector(
    (state: RootState) => selectDirUsersAccess(state, dir),
    shallowEqual
  );
}

export function useUserAccessActions() {
  const dispatch = useDispatch();
  const actions = dirsUserAccessActions;

  const fetch = (data: Pick<DirGroupAccessPayload, 'dirId'>) =>
    dispatch(actions.fetch.trigger(data));
  const create = (data: DirUserAccessPayload) =>
    dispatch(actions.create.trigger(data));
  const remove = (data: DirUserAccessPayload) =>
    dispatch(actions.delete.trigger(data));

  return {
    fetch,
    create,
    remove
  };
}

export function useDirGroupsAccess(dir: Dir) {
  return useSelector(
    (state: RootState) => selectDirGroupAccess(state, dir),
    shallowEqual
  );
}

export function useGroupAccessActions() {
  const dispatch = useDispatch();
  const actions = dirsGroupAccessActions;

  const fetch = (data: Pick<DirGroupAccessPayload, 'dirId'>) =>
    dispatch(actions.fetch.trigger(data));
  const create = (data: DirGroupAccessPayload) =>
    dispatch(actions.create.trigger(data));
  const remove = (data: DirGroupAccessPayload) =>
    dispatch(actions.delete.trigger(data));

  return {
    fetch,
    create,
    remove
  };
}

export function useDirUsersBlocked(dir: Dir) {
  return useSelector(
    (state: RootState) => selectDirUsersBlocked(state, dir),
    shallowEqual
  );
}

export function useUserBlockedActions() {
  const dispatch = useDispatch();
  const actions = dirsUserBlockedActions;

  const fetch = (data: Pick<DirGroupAccessPayload, 'dirId'>) =>
    dispatch(actions.fetch.trigger(data));
  const create = (data: DirUserAccessPayload) =>
    dispatch(actions.create.trigger(data));
  const remove = (data: DirUserAccessPayload) =>
    dispatch(actions.delete.trigger(data));

  return {
    fetch,
    create,
    remove
  };
}

export type UsersActions =
  | ReturnType<typeof useUserBlockedActions>
  | ReturnType<typeof useUserAccessActions>;
export type UsersStateData =
  | ReturnType<typeof useDirUsersAccess>
  | ReturnType<typeof useDirUsersBlocked>;
