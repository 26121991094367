import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'utils/router';
import { useFiltersQueryParams } from '../../../utils/use-data-params';
import SearchField from '../SearchField/SearchField';

type Props = {
  isActive: boolean;
  onChange?(search: string): void;
  placeholder: string;
};

const SidebarSearch = ({ isActive, placeholder, onChange }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useFiltersQueryParams();
  const [query, setQuery] = useState(params.search || null);

  const onSearchChange = useCallback(
    debounce((search: string) => {
      onChange?.(search);
    }, 500),
    [onChange],
  );

  const onInputChange = (value: string) => {
    setQuery(value);
    onSearchChange(value);
  };

  return (
    <SearchField
      isActive={isActive}
      value={query}
      placeholder={placeholder}
      size={'large'}
      onChange={onInputChange}
      expand
    />
  );
};

export default SidebarSearch;
