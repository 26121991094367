import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'utils/router';
import { RootState } from '../../store';
import ErrorMobileUserAccessDenied from '../../views/ErrorMobileUserAccessDenied';
import { ROLES } from '../../modules/auth';

const AuthGuard = (props: any) => {
  const { children } = props;
  const user = useSelector((state: RootState) => state.auth.user, shallowEqual);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/auth/login');
    }
  }, [user]);

  if (!user) {
    return null;
  }

  if (
    Array.from(user.roles || []).some((role) => role === ROLES.MOBILE_APP_USER)
  ) {
    return <ErrorMobileUserAccessDenied />;
  }

  return <>{children}</>;
};

export default AuthGuard;
