import * as z from 'zod';

export const GroupSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const GroupsSchema = z.array(GroupSchema);

export type Group = z.infer<typeof GroupSchema>;
