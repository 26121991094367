import { useCallback } from 'react';
import { User } from '../users';
import { useCurrentUser } from './hooks';
import voters from './voters';
import app from 'config/app';

type Voters = typeof voters;
type Permission = keyof typeof voters;

type SkipUserParameter<
  P extends Permission,
  T extends PermissionContext<P> = PermissionContext<P>,
> = T extends [User, ...infer U] ? U : never;

type PermissionContext<P extends Permission> = Parameters<Voters[P]>;

const usePermissions = () => {
  const user = useCurrentUser();

  const can = <P extends Permission>(
    permission: P,
    ...context: SkipUserParameter<P>
  ) => {
    if (app.isStorybookEnv) {
      return true;
    }

    const checker = voters[permission] as (
      ...args: PermissionContext<P>
    ) => boolean;

    return checker.apply(null, [
      user,
      ...context,
    ] as unknown as PermissionContext<P>);
  };

  const canMemoized = useCallback(can, [user]);

  return {
    can: canMemoized,
  };
};

export default usePermissions;
