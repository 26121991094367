import React, { forwardRef, useState } from 'react';
import { Button as BaseButton } from '@mui/base/Button';
import Loader from '../Loader';
import clsx from 'clsx';
import css from './button.module.scss';
import { MemberOf } from '../../../utils/types';

// 'as const' makes possible to extract array values as TS types,
// so we can validate them on build time instead of runtime.
const fileFormats = [
  'word',
  'excel',
  'powerpoint',
  'pdf',
  'zip',
  'csv',
] as const;

type Variant =
  | 'default'
  | 'outlined'
  | 'text'
  | 'text-primary'
  | 'black'
  | 'icon';
type Size = 'xxs' | 'xs' | 'small' | 'default' | 'large';
type Color = 'default' | 'success' | 'error' | 'warning' | 'inherit';
export type FileFormat = MemberOf<typeof fileFormats>;

type ButtonProps = {
  size?: Size;
  variant?: Variant;
  color?: Color;
  fileFormat?: FileFormat;
  expand?: boolean;
  isBusy?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
} & React.ComponentProps<typeof BaseButton>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    size,
    variant = 'default',
    color = 'default',
    fileFormat,
    expand,
    isBusy,
    startIcon: defaultStartIcon,
    endIcon,
    className,
    children,
    disabled,
    ...rest
  } = props;

  let startIcon = defaultStartIcon;

  const getLoaderSize = () => {
    const sizes = {
      xxs: 8,
      xs: 10,
      small: 12,
      default: 16,
      large: 16,
    };

    return (size && sizes[size]) || 16;
  };

  return (
    <BaseButton
      ref={ref}
      className={clsx(
        className,
        css.button,
        size && css[`size-${size}`],
        variant && css[`variant-${variant}`],
        color && css[`color-${color}`],
        fileFormat && css[`file-format-${fileFormat}`],
        startIcon && !endIcon && !children && css['is-icon-button'],
        isBusy && css['is-busy'],
        expand && css.expanded,
      )}
      disabled={isBusy || disabled}
      {...rest}
    >
      {startIcon && !isBusy && (
        <span className={clsx(css.icon, css.start)}>{startIcon}</span>
      )}
      {isBusy && (
        <span className={css.loader}>
          <Loader size={getLoaderSize()} />
        </span>
      )}
      {children}
      {endIcon && <span className={clsx(css.icon, css.end)}>{endIcon}</span>}
    </BaseButton>
  );
});

type ButtonGroupProps = {
  align?: 'left' | 'center' | 'right' | 'justify';
};

const ButtonGroup: React.FC<
  ButtonGroupProps & React.HTMLProps<HTMLDivElement>
> = (props) => {
  const { align, children, ...rest } = props;

  return (
    <div className={clsx(css.group, align && css[`align-${align}`])} {...rest}>
      {children}
    </div>
  );
};

const LockableButton = ({
  onClick,
  ...props
}: ButtonProps & { onClick(event: any): Promise<any> }) => {
  const [locked, setLocked] = useState(false);

  const handleClick = async (event: MouseEvent) => {
    try {
      setLocked(true);
      await onClick(event);
    } finally {
      setLocked(false);
    }
  };

  return <Button {...props} onClick={handleClick} isBusy={locked} />;
};

export default Button;

export { Button, ButtonGroup, LockableButton };
