import React from 'react';
import Button from '../../../components/v2/Button/Button';
import useContextBar from '../../../components/v2/ContextBar/useContextBar';
import Icon from '../../../components/v2/Icon/Icon';
import { useTranslation } from 'utils/translation';
import { ExportActionsContextBar } from './ExportActionsContextBar';

export const ReportTableActions = () => {
  const { t } = useTranslation();
  const { open } = useContextBar();

  const openDownloadContextBar = () => {
    open({
      icon: <Icon name="fa fa-download fa-regular" />,
      title: 'Download',
      component: ExportActionsContextBar,
    });
  };

  return (
    <Button
      onClick={openDownloadContextBar}
      variant="text-primary"
      endIcon={<Icon name="fa fa-download fa-regular" />}
    >
      {t('Download')}
    </Button>
  );
};
