import { useTranslation } from 'react-i18next';
import {
  ChildNavigationItem,
  Navigation,
  NavigationItem,
} from '../../components/v2/Navigation/Navigation';
import Icon from '../../components/v2/Icon/Icon';
import {
  Sidebar,
  SidebarWrapper,
  SidebarNotice,
} from '../../components/v2/Sidebar/Sidebar';
import React, { useState } from 'react';
import SidebarSearch from '../../components/v2/Sidebar/SidebarSearch';
import usePermissions from '../../modules/auth/permissions';
import { useRemoveReportWithConfirmation } from '../../modules/reports/hooks/use-remove-report';
import useReports from '../../modules/reports/hooks/use-reports';
import { Report } from '../../modules/reports/types';
import { useLocation, useNavigate } from 'utils/router';
import { UpdateReportName } from 'modules/reports/components/SaveReport';
import ReportContextProvider from 'modules/reports/context/ReportContext';
import SystemSummary from '../../modules/ui/components/SystemSummary';
import { encode } from '../../utils/state-encoder';
import { route } from 'router';
import { useAppSettings } from 'modules/ui/hooks';
import BasketButton from 'modules/basket/components/BasketButton';
import config from 'config/app';
import Spacer from 'components/v2/Spacer/Spacer';

const ReportNavItem = ({ report }: { report: Report }) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(`/assets/report/${report.id}`);
  const navigate = useNavigate();
  const onRemove = useRemoveReportWithConfirmation(report);
  const [editing, setEditing] = useState(false);

  const actions: any[] = [];

  if (report.isEditable) {
    actions.push({
      label: 'Edit',
      icon: <Icon name="far fa-edit" />,
      action: () => setEditing(true),
    });
  }

  if (report.isRemovable) {
    actions.push({
      label: 'Remove',
      icon: <Icon name="far fa-trash" />,
      action: onRemove,
    });
  }

  const onClick = () => {
    navigate(route('report.index', { id: report.id }));
  };

  return (
    <>
      <ChildNavigationItem
        onClick={onClick}
        title={report.name}
        actions={actions}
        isActive={isActive}
      />
      {/* TODO: Report update form should be created with new Components */}
      {editing && (
        <ReportContextProvider {...report}>
          <UpdateReportName
            reportId={report.id}
            onClose={() => setEditing(false)}
          />
        </ReportContextProvider>
      )}
    </>
  );
};

const DashboardSidebar = () => {
  const { can } = usePermissions();
  const { t } = useTranslation();
  const { data: reports = [], isLoading } = useReports();
  const navigate = useNavigate();
  const location = useLocation();
  const { settings } = useAppSettings();
  const hasAssets = config.enableAssetsModule;

  const isActive = (path: string) => {
    return location.pathname.startsWith(`/assets/${path}`);
  };

  const handleSearch = React.useCallback(
    (search: string) => {
      const filters = encode({ search });
      navigate(`/assets/search?filters=${filters}`);
    },
    [navigate],
  );

  if (isLoading) {
    return null;
  }

  return (
    <Sidebar>
      <SidebarSearch
        isActive={isActive('search')}
        placeholder={t('report_search')}
        onChange={handleSearch}
      />
      <Navigation>
        {hasAssets && (
          <NavigationItem
            onClick={
              isActive('report')
                ? undefined
                : () => navigate(route('report.index', { id: reports[0]?.id }))
            }
            title={t('Assets Lists')}
            icon={<Icon name={'far fa-object-subtract'} />}
            isActive={isActive('report')}
          >
            {reports.map((report) => (
              <ReportNavItem report={report} key={report.id} />
            ))}
          </NavigationItem>
        )}
        {hasAssets && (
          <NavigationItem
            onClick={() => navigate('/assets/types')}
            isActive={isActive('types')}
            title={t('Assets Types')}
            icon={<Icon name={'far fa-object-intersect'} />}
          />
        )}
        {hasAssets && can('view device groups') && (
          <NavigationItem
            disabled={!settings.features.deviceGroups}
            onClick={() => navigate('/assets/groups')}
            title={t('Assets Groups')}
            isActive={isActive('groups')}
            icon={<Icon name={'far fa-object-union'} />}
          />
        )}
        {can('view service requests') && (
          <NavigationItem
            title={t('Service Tickets')}
            onClick={() => navigate('/service-tickets')}
            icon={<Icon name={'far fa-screwdriver-wrench'} />}
            isActive={location.pathname.startsWith('/service-tickets')}
          />
        )}
        {can('view system log') && (
          <NavigationItem
            title={t('Logbook')}
            onClick={() => navigate('/logs')}
            icon={<Icon name={'far fa-file-lines'} />}
          />
        )}
      </Navigation>
      <SidebarWrapper>
        <BasketButton />
      </SidebarWrapper>
      <Spacer size={2} grow />
      <SidebarNotice>
        <SystemSummary />
      </SidebarNotice>
    </Sidebar>
  );
};

export default DashboardSidebar;
