import * as React from 'react';
import { TablePagination as BaseTablePagination } from '@mui/base/TablePagination';
import { TablePaginationActions } from '@mui/base/TablePagination/TablePaginationActions';
import Icon from '../Icon/Icon';
import Select from '../Select/Select';
import css from './table-pagination.module.scss';

const defaultRowsPerPageOptions = [15, 25, 50, 100];

type Props = {
  colSpan?: number;
  count: number;
  page: number;
  setPage(page: number): void;
  perPage: number;
  setPerPage(page: number): void;
  rowsPerPageOptions?: typeof defaultRowsPerPageOptions;
};

function TablePagination(props: Props) {
  const {
    count,
    colSpan = 3,
    page,
    setPage,
    perPage,
    setPerPage,
    rowsPerPageOptions = defaultRowsPerPageOptions,
  } = props;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <BaseTablePagination
      labelRowsPerPage="Per page: "
      className={css.root}
      rowsPerPageOptions={rowsPerPageOptions}
      colSpan={colSpan}
      count={count}
      rowsPerPage={perPage}
      page={page - 1}
      slotProps={{
        toolbar: {
          className: css.toolbar,
        },
        spacer: {
          className: css.spacer,
        },
        selectLabel: {
          className: css['select-label'],
        },
        select: {
          className: css.select,
        },
        menuItem: {
          className: css.option,
        },
        displayedRows: {
          className: css['displayed-rows'],
        },
        actions: {
          className: css.actions,
          showFirstButton: true,
          showLastButton: true,
        },
      }}
      slots={{
        select: (props) => (
          <Select
            {...props}
            slotProps={{
              popup: { placement: 'top' },
            }}
            variant="inline"
            onChange={(event, value) =>
              props.onChange({
                ...event,
                target: {
                  value,
                },
              })
            }
          />
        ),
        menuItem: ({ ownerState, ...props }) => {
          return <Select.Option {...props} />;
        },
        actions: (props) => (
          <TablePaginationActions
            {...props}
            slots={{
              firstPageIcon: () => <Icon name={'fas fa-arrow-left-to-line'} />,
              backPageIcon: () => <Icon name={'fas fa-arrow-left-long'} />,
              nextPageIcon: () => <Icon name={'fas fa-arrow-right-long'} />,
              lastPageIcon: () => <Icon name={'fas fa-arrow-right-to-line'} />,
            }}
          />
        ),
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}

export default TablePagination;
