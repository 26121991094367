import get from 'lodash/get';
import React from 'react';
import Button from '../../components/v2/Button/Button';
import TopBar from '../../components/v2/TopBar/TopBar';
import { useTranslation } from 'utils/translation';
import { applyFilters } from '../../core';
import Filters from '../../core/filters';
import { useAppSettings } from '../../modules/ui/hooks';
import { useCurrentUser } from '../../modules/auth/hooks';
import { useUiVersion } from '../../modules/ui/UserInterfaceVersionProvider';
import getInitials from '../../utils/getInitials';
import OptionsButton from '../../components/v2/Button/OptionsButton';
import { useNavigate } from 'react-router-dom';
import usePermissions from 'modules/auth/permissions';
import Icon from 'components/v2/Icon/Icon';
import { useLocation } from 'utils/router';
import { CrudView } from '../../modules/@common/crud/types';
import config from 'config/app';
import app from '../../config/app';

export const LanguageSwitcher = () => {
  const { settings } = useAppSettings();
  const { i18n } = useTranslation();

  return (
    <OptionsButton
      variant="text"
      actions={settings.locales.map((locale: string) => ({
        action() {
          void i18n.changeLanguage(locale);
        },
        label: locale.toUpperCase(),
      }))}
    >
      {i18n.language.toUpperCase()}
    </OptionsButton>
  );
};

const DashboardTopBar = () => {
  const user = useCurrentUser();
  const { can } = usePermissions();
  const fullName = [user.firstName, user.lastName].filter((i) => i).join(' ');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { settings } = useAppSettings();
  const uiVersion = useUiVersion();
  const logoUrl = get(settings, 'newWebLogo') || '/images/logos/logo.svg';
  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  const componentViews = applyFilters<CrudView[]>(
    Filters.components.crudViews,
    [],
  );

  const links = [];

  if (config.enableAssetsModule) {
    links.push({
      label: t('Devices'),
      href: '/assets',
      active: isActive('/assets'),
    });
  }

  if (can('view files') && applyFilters(Filters.topBar.links.files, true)) {
    links.push({
      label: t('Files'),
      href: '/v2/',
      active:
        location.pathname === '/v2/' ||
        isActive('/files') ||
        isActive('/v2/directory'),
    });
  }

  if (can('view components') && componentViews.length > 0) {
    links.push({
      label: t('Components'),
      href: '/components',
      active: isActive('/components'),
    });
  }

  if (can('view users')) {
    links.push({
      label: t('Users'),
      href: '/v2/users',
      active: isActive('/v2/users'),
    });
  }

  const switchToOldUiVersion = () => {
    uiVersion.switchTo('default');
  };

  const actions = [];

  if (app.enableNewUiSwitcher) {
    actions.push(
      <Button variant={'black'} size={'xs'} onClick={switchToOldUiVersion}>
        {t('switch_to_the_old_ui')}
      </Button>,
    );
  }

  if (can('view settings')) {
    actions.push(
      <Button
        variant={'text'}
        size={'small'}
        onClick={() => navigate('/settings')}
        startIcon={<Icon name="far fa-gear" />}
      >
        {t('Settings')}
      </Button>,
    );
  }

  return (
    <TopBar
      logoUrl={logoUrl}
      links={links}
      actions={[...actions, <LanguageSwitcher />]}
      user={{ initials: getInitials(fullName || user.email) }}
    />
  );
};

export default DashboardTopBar;
