import React from 'react';
import moment from 'moment';

interface Props {
  date: string;
  format?: string;
  diff?: boolean;
  withTime?: boolean;
}

const DateFormat: React.FC<Props> = ({
  date,
  format,
  diff,
  withTime = false
}) => {
  let localeFormat = moment()
    .localeData()
    .longDateFormat('L');
  let timeFormat = moment()
    .localeData()
    .longDateFormat('LTS');

  if (format) {
    localeFormat = format;
  }

  if (diff) {
    return <>{moment().to(date)}</>;
  }

  const parts = [localeFormat];

  if (withTime) {
    parts.push(timeFormat);
  }

  return <>{moment(date).format(parts.join(' '))}</>;
};

export default DateFormat;
