import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from 'utils/render-routes';
import { Box, LinearProgress } from '@mui/material';

const Public = (props: any) => {
  const routes = props?.route?.routes || [];

  return (
    <Fragment>
      <Box
        component="main"
        sx={{
          height: '100%',
        }}
      >
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(routes)}
        </Suspense>
      </Box>
    </Fragment>
  );
};

export default Public;
