const wait = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const throwAfter = (ms: number, message: string = 'Test rejection.') =>
  new Promise((resolve, reject) => {
    setTimeout(() => reject(message), ms);
  });

export default wait;
