import React from 'react';
import HighlightText from '../../../components/Highlight/HighlightText';
import { useSearchContext } from '../../../components/v2/SearchField/SearchContext';

const HightLightQuery = ({
  value,
  rootRef,
  ...rest
}: { value: string } & any) => {
  const { query } = useSearchContext();

  if (!query || typeof value !== 'string') {
    return (
      <span ref={rootRef} {...rest}>
        {value}
      </span>
    );
  }

  return (
    <HighlightText rootRef={rootRef} {...rest} text={value} highlight={query} />
  );
};

export default HightLightQuery;
