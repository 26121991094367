import React, { Suspense, useEffect, useState } from 'react';
import { renderRoutes, RouteConfig } from 'utils/render-routes';
import { LinearProgress, Typography, Hidden, Box } from '@mui/material';
import { NavBar, TopBar, Modals } from './components';
import { UploadingList } from '../../modules/files/components/UploadingList';
import { FileUploaderDropzone } from '../../modules/files/components/FileUploaderDropzone';
import { GatewayProvider, GatewayDest } from 'react-gateway';
import { useDirsActions } from '../../modules/dirs/hooks';
import { useFilesActions } from '../../modules/files/hooks';
import manifest from '../../../package.json';
import {
  DashboardViewContextProvider,
  useDashboardView,
} from './DashboardViewContext';
import TestWarning from '../../components/TestWarning/TestWarning';
import { Theme } from 'theme';
import ScheduledDownloadsProvider from '../../modules/export/ScheduledDownloadsProvider';

const navbarWidth = '250px';

const classes = {
  root: {
    height: '100%',
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  topBar: {},
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    marginTop: 8,
  },
  navBar: {
    zIndex: 3,
    width: navbarWidth,
    minWidth: navbarWidth,
    flex: '0 0 auto',
    transition: 'transform .5s ease',
    paddingBottom: 4,
  },
  navBarFullScreen: {
    transform: 'translateX(-100%)',
  },
  content: (theme: Theme) => ({
    position: 'relative',
    overflowY: 'auto',
    flex: '1 1 auto',
    transition: 'margin .5s ease',
    [theme.breakpoints.up('lg')]: {
      maxWidth: `calc(100vw - ${navbarWidth})`,
      marginLeft: navbarWidth,
    },
  }),
  contentFullScreen: (theme: Theme) => ({
    flex: 1,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
      marginLeft: 0,
    },
  }),
  appVersion: {
    position: 'fixed',
    width: '240px',
    padding: '8px 16px',
    left: '0',
    bottom: '0',
    zIndex: 50,
    backgroundColor: '#fff',
  },
};

type Props = {
  route: {
    routes: RouteConfig[];
  };
};

const Dashboard = (props: Props) => {
  const { route } = props;
  const { mode } = useDashboardView();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const { fetchDirs } = useDirsActions();
  const { fetchFiles } = useFilesActions();

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  useEffect(() => {
    fetchDirs();
    fetchFiles();
    // eslint-disable-next-line
  }, []);

  return (
    <FileUploaderDropzone>
      {/* @ts-ignore */}
      <GatewayProvider>
        <Box sx={classes.root}>
          <TopBar
            sx={classes.topBar}
            onOpenNavBarMobile={handleNavBarMobileOpen}
            showLocaleSwitcher={mode === 'default'}
            showUserActions={mode === 'default'}
          />
          <Box sx={classes.container} className={'custom-scrollbar'}>
            <NavBar
              sx={{
                ...classes.navBar,
                ...(mode === 'fullScreen' && classes.navBarFullScreen),
              }}
              onMobileClose={handleNavBarMobileClose}
              openMobile={openNavBarMobile}
            />
            <Box
              component="main"
              sx={(theme) => ({
                ...classes.content(theme),
                ...(mode === 'fullScreen' && classes.contentFullScreen(theme)),
              })}
            >
              <Suspense fallback={<LinearProgress />}>
                {renderRoutes(route.routes)}
              </Suspense>
              <Hidden lgDown>
                <Typography sx={classes.appVersion} variant="caption">
                  {manifest.appName} v{manifest.version}
                </Typography>
              </Hidden>
            </Box>
          </Box>
          <Modals />
          {/* @ts-ignore */}
          <GatewayDest name="footer" />
        </Box>
      </GatewayProvider>
      <UploadingList />
      <TestWarning />
    </FileUploaderDropzone>
  );
};

const DashboardWithContext = (props: Props) => (
  <DashboardViewContextProvider>
    <Dashboard {...props} />
    <ScheduledDownloadsProvider />
  </DashboardViewContextProvider>
);

export default DashboardWithContext;
