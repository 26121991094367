import { useContext } from 'react';
import ModalContentContext from './ModalContentContext';

export const useModalContent = () => {
  const context = useContext(ModalContentContext);

  if (!context) {
    throw new Error(
      'ModalContentContext is null. Did you forget to wrap your component into ModalContentProvider?',
    );
  }

  return context;
};
