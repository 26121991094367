import {
  createResourceAction,
  createResourceActions
} from '../@common/actions/resource-actions';
import { FILES_PREFIX } from './constants';
import { createType, createAction } from '../@common/actions/action';

export const filesActions = {
  ...createResourceActions(FILES_PREFIX),
  uploadProgress: createAction(`${FILES_PREFIX}/UPLOAD_PROGRESS`),
  UPLOAD_PROGRESS: createType(`${FILES_PREFIX}/UPLOAD_PROGRESS`),
  addToUploadQueue: createAction(`${FILES_PREFIX}/ADD_TO_UPLOAD_QUEUE`),
  ADD_TO_UPLOAD_QUEUE: createType(`${FILES_PREFIX}/ADD_TO_UPLOAD_QUEUE`),
  cancelUpload: createResourceAction(`${FILES_PREFIX}/CANCEL_UPLOAD`),
  cancelUploadAll: createResourceAction(`${FILES_PREFIX}/CANCEL_UPLOAD_ALL`),
  move: createResourceAction(`${FILES_PREFIX}/MOVE`),
  getThumb: createResourceAction(`${FILES_PREFIX}/GET_THUMB`),
  getPreview: createResourceAction(`${FILES_PREFIX}/GET_PREVIEW`),
  download: createResourceAction(`${FILES_PREFIX}/DOWNLOAD`),
  share: createResourceAction(`${FILES_PREFIX}/SHARE`),
  search: createResourceAction(`${FILES_PREFIX}/SEARCH`)
};
