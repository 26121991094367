import { createColumnHelper } from '@tanstack/react-table';
import { skipCoversFilter } from 'modules/files/utils';
import React, { useCallback } from 'react';
import { useTranslation } from 'utils/translation';
import { useDirQuery, useDirsQuery } from '../../../modules/dirs/hooks';
import map from '../../../utils/map';
import { DataContextProvider } from '../Data/DataContext';
import DataTable from '../Data/DataTable';
import Empty from '../Empty/Empty';
import Loader from '../Loader/Loader';
import { NameCellContent } from './NameCellContent';
import { mapDirToTableRow, mapFileToTableRow, Resource } from './utils';

const { accessor, display } = createColumnHelper<Resource>();

export const DeviceTypeAssets = ({
  id,
  onItemClick,
}: {
  id: number;
  onItemClick(item: Resource['id']): void;
}) => {
  const { t } = useTranslation();
  const dir = useDirQuery(id);
  const childrenQuery = useDirsQuery({
    parentId: id,
    directoryType: 'inherited-type',
  });

  const refreshData = useCallback(
    () => Promise.all([dir.refetch(), childrenQuery.refetch()]),
    [dir.refetch, childrenQuery.refetch],
  );

  const columns = [
    accessor('name', {
      cell: ({ row: { original } }) => {
        return (
          <a
            onDoubleClick={
              original.type === 'file'
                ? undefined
                : () => onItemClick(original.id)
            }
          >
            <NameCellContent {...original} />
          </a>
        );
      },
    }),
    display({
      id: 'actions',
      cell: (props) => props.row.original.actions,
      meta: {
        align: 'right',
      },
    }),
  ];

  const allowedFiles = (dir?.data?.files || []).filter(skipCoversFilter);
  const directories = map(childrenQuery?.data?.data, mapDirToTableRow);
  const files = map(allowedFiles, mapFileToTableRow);
  const data = [...directories, ...files];

  if (childrenQuery.isLoading) {
    return <Loader />;
  }

  if (0 === data.length) {
    return <Empty.Directory />;
  }

  return (
    <DataContextProvider refresh={refreshData}>
      <DataTable
        data={data}
        columns={columns}
        slotProps={{
          root: { hideHeaders: true },
        }}
      />
    </DataContextProvider>
  );
};
