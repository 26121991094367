import React, { useEffect } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import {
  Drawer as BaseDrawer,
  Divider,
  Typography,
  Hidden,
  IconButton,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DrawerActions from './DrawerActions';
import {
  DirResource,
  FileResource,
  isDir,
  isFile,
  useResourceModal,
} from '../../../../modules/resources';
import { useDirsActions } from '../../../../modules/dirs/hooks';
import { useNavigate } from '../../../../utils/router';
import { shallowEqual, useSelector } from 'react-redux';
import { selectResource } from '../../../../modules/resources/selectors';
import { RootState } from '../../../../store';
import { DirectoryContents } from './DirectoryContents';
import { FileContents } from './FileContents';
import { Dir } from '../../../../modules/dirs';
import { File } from '../../../../modules/files';
import { useFilesActions } from '../../../../modules/files/hooks';
import { ResourceModal } from '../../../../modules/ui';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {},
  content: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  backButton: {
    marginLeft: theme.spacing(-1.5),
  },
  title: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    marginLeft: theme.spacing(1.5),
    maxWidth: 165,
  },
  drawerPaper: {
    position: 'fixed',
    top: (options: DrawerOptions) => options.offsetTop,
    height: (options: DrawerOptions) => `calc(100vh - ${options.offsetTop}px)`,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: (options: DrawerOptions) => options.width,
    },
    zIndex: 990,
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 2),
    ...theme.mixins.toolbar,
  },
}));

interface DrawerOptions {
  width: number;
  offsetTop: number;
}

interface Props {
  open: boolean;
  options: DrawerOptions;
  onClose: () => void;
  resource: DirResource | FileResource | null;
}

function Drawer({ open, onClose, options, resource: selectedResource }: Props) {
  const classes = useStyles(options);
  const navigate = useNavigate();
  const { deleteDir } = useDirsActions();
  const { deleteFile } = useFilesActions();
  const resource = useSelector((state: RootState) => {
    if (!selectedResource) {
      return null;
    }

    return selectResource(state, selectedResource.id, selectedResource.type);
  }, shallowEqual);
  const { openModal } = useResourceModal();

  useEffect(() => {
    if (!resource) {
      onClose();
    }
    // eslint-disable-next-line
  }, [resource]);

  if (!selectedResource) {
    return null;
  }

  const handleDelete = () => {
    if (isDir(selectedResource)) {
      const dir = resource as Dir;
      deleteDir(dir);
      onClose();

      setTimeout(() => {
        if (dir.parent && dir.parent.id) {
          navigate(`/directory/${dir.parent.id}`);
        } else {
          navigate('/');
        }
      }, 300);
    }

    if (isFile(selectedResource)) {
      const file = resource as File;
      deleteFile(file);
      onClose();
    }
  };

  const handleRename = () => {
    openModal(ResourceModal.Edit, selectedResource);
  };

  const handleMove = () => {
    openModal(ResourceModal.Move, selectedResource);
  };

  const renderIcon = () => {
    if (isDir(selectedResource)) {
      return <FolderIcon />;
    }

    if (isFile(selectedResource)) {
      return <InsertDriveFileIcon />;
    }

    return null;
  };

  const renderResourceLabel = () => {
    if (!resource) {
      return null;
    }

    if (isDir(selectedResource)) {
      return resource.title;
    }

    if (isFile(selectedResource)) {
      return resource.filename;
    }

    return null;
  };

  const navigateToDirectory = (dir: Dir) => {
    navigate(`/directory/${dir.id}`);
    onClose();
  };

  return (
    <BaseDrawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Hidden smUp>
          <IconButton
            className={classes.backButton}
            color="inherit"
            onClick={onClose}
            size="large"
          >
            <ChevronLeftIcon />
          </IconButton>
        </Hidden>
        <div className={classes.title}>
          {renderIcon()}
          <Typography noWrap variant="h6" className={classes.label}>
            {!resource ? <Skeleton width={100} /> : renderResourceLabel()}
          </Typography>
        </div>
        <DrawerActions
          onClose={onClose}
          onDelete={handleDelete}
          onRename={handleRename}
          onMove={handleMove}
        />
      </div>
      <Divider />
      {/* @ts-ignore */}
      <PerfectScrollbar>
        <div className={classes.content}>
          {resource && isDir(selectedResource) && (
            <DirectoryContents
              dir={resource as Dir}
              onNavigateClick={navigateToDirectory}
            />
          )}
          {resource && isFile(selectedResource) && (
            <FileContents file={resource as File} />
          )}
        </div>
      </PerfectScrollbar>
    </BaseDrawer>
  );
}

export default Drawer;
