import { takeLatest } from 'redux-saga/effects';
import { createApiHandler } from '../@common/sagas/create-api-handler';
import { dirsLayerActions } from './actions';
import { fetchIntelligentFields, updateIntelligentFields } from './service/api';

const handleIntelligentFieldsFetch = createApiHandler({
  routine: dirsLayerActions.fetch,
  provider: fetchIntelligentFields
});

const handleIntelligentFieldsUpdate = createApiHandler({
  routine: dirsLayerActions.update,
  provider: updateIntelligentFields
});

export default function*() {
  yield takeLatest(
    dirsLayerActions.fetch.TRIGGER,
    handleIntelligentFieldsFetch
  );
  yield takeLatest(
    dirsLayerActions.update.TRIGGER,
    handleIntelligentFieldsUpdate
  );
}
