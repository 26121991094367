import React from 'react';
import { useTranslation } from 'utils/translation';
import { QRCode as QRSvg } from 'react-qr-svg';
import downloadFile from 'utils/blob-download';

const QRCode: React.FC<{ value: string; onClick?(): void }> = ({
  value,
  onClick,
}) => {
  const { t } = useTranslation();

  const handleDownloadQR = (event: any) => {
    const svg = event.currentTarget.cloneNode(true);
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    downloadFile(svg.outerHTML, `${value}.svg`, 'image/svg');
  };

  return (
    <div title={t('Click to download')}>
      <QRSvg
        onClick={onClick || handleDownloadQR}
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="Q"
        value={value}
        style={{
          display: 'block',
          width: '100px',
          cursor: 'pointer',
        }}
      />
    </div>
  );
};

export default QRCode;
