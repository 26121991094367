import { RefObject, useEffect, useLayoutEffect, useRef } from 'react';

function useKeepScrollPosition(ref: RefObject<HTMLElement>) {
  const listScrollRef = useRef<number | undefined>(ref.current?.scrollTop);

  const onScroll = (event: any) => {
    listScrollRef.current = event.currentTarget?.scrollTop;
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('scroll', onScroll);
    } else {
      listScrollRef.current = 0;
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('scroll', onScroll);
      }
    };
  });

  useLayoutEffect(() => {
    if (listScrollRef.current && ref.current) {
      ref.current.scrollTop = listScrollRef.current;
    }
  });
}

export default useKeepScrollPosition;
