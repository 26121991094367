import React from 'react';
import { Route, Routes, Location } from 'react-router-dom';

interface RouteConfig {
  key?: React.Key | undefined;
  location?: Location | undefined;
  component?: React.ComponentType<any> | React.ComponentType | undefined | any;
  path?: string;
  exact?: boolean | undefined;
  strict?: boolean | undefined;
  routes?: RouteConfig[] | undefined;
  render?: ((props: any) => React.ReactNode) | undefined;

  [propName: string]: any;
}

function renderRoutes(routes: RouteConfig[]) {
  return routes ? (
    <Routes>
      {routes.map(({ key, path, component: Component, ...route }, index) => (
        <Route
          key={key || index}
          path={path}
          element={<Component route={route} />}
        />
      ))}
    </Routes>
  ) : null;
}

export { renderRoutes };

export type { RouteConfig };
