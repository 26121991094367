import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useContextMenu from '../../../../../components/v2/ContextMenu/useContextMenu';
import TreeItem from '../../../../../components/v2/TreeView/TreeItem';
import TreeView from '../../../../../components/v2/TreeView/TreeView';
import repeat from '../../../../../utils/repeat';
import { Dir } from '../../../../dirs';
import { useDirectoryActions } from '../../../../dirs/components/DirectoryActions';
import { useDirsActions, useLoadedDirs } from '../../../../dirs/hooks';
import { useDrawerSelection } from '../../providers/DrawerSelectionProvider';

type TreeNode = {
  id: string;
  label: string;
  children: TreeNode[];
};

let id = -1;
const getPlaceholderId = () => (--id).toString();

const createPlaceholderNode = () => ({
  id: getPlaceholderId(),
  label: '',
  isPlaceholder: true,
  children: [],
});

const NavItem = (props: any) => {
  const { itemId } = props;
  const actions = useDirectoryActions({ id: +itemId });
  const { ref } = useContextMenu<HTMLDivElement>({ actions });

  return <TreeItem {...props} ref={ref} />;
};

const TreeNavigation = () => {
  const { dirs, isLoading } = useLoadedDirs();
  const { fetchDirs } = useDirsActions();
  const navigate = useNavigate();
  const drawerSelection = useDrawerSelection();
  const child: { [key: string]: Dir[] } = {};

  const onItemClick = (event: React.MouseEvent, itemId: string) => {
    event.preventDefault();
    event.stopPropagation();

    // ignore placeholder IDs which are negative
    if (Number(itemId) > 0) {
      navigate(`/v2/directory/${itemId}`);
      fetchDirs({ parentId: +itemId });
      drawerSelection.setItem({ type: 'directory', id: +itemId });
    }

    return false;
  };

  useEffect(() => {
    fetchDirs();
  }, []);

  const mapToTreeNode = (item: Dir): TreeNode => {
    const itemId = item.id.toString();
    const loadedChildren = (child[itemId] || []).map(mapToTreeNode);
    const placeholderChildren = repeat(
      item.childrenCount,
      createPlaceholderNode,
    );

    return {
      id: itemId,
      label: item.title,
      children:
        loadedChildren.length === 0 ? placeholderChildren : loadedChildren,
    };
  };

  const data = dirs
    .filter((item) => {
      const parentId = String(item.parentId || '');

      if (parentId) {
        child[parentId] = child[parentId] || [];
        child[parentId].push(item);
      }

      return parentId === '';
    })
    .map(mapToTreeNode);

  if (isLoading) {
    return <TreeView items={repeat(7, createPlaceholderNode)} />;
  }

  return (
    <TreeView
      items={data}
      onItemClick={onItemClick}
      slots={{
        item: NavItem,
      }}
      onItemExpand={(event, itemId) => {
        fetchDirs({ parentId: +itemId });
      }}
    />
  );
};

export default TreeNavigation;
