import React from 'react';
import { Helmet } from 'react-helmet';
import app from 'config/app';

const GoogleAnalytics = () => {
  const { googleAnalyticsId } = app;

  if (!googleAnalyticsId) {
    return null;
  }

  return (
    // @ts-ignore
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${googleAnalyticsId}');
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
