import { get } from 'lodash';
import React from 'react';

const initial = {
  filters: {},
  actions: {},
  plugins: {},
  store: null,
};

const Quodoc = { ...initial } as any;

let booted = false;
let booting = false;

export function reset() {
  Quodoc.filters = {};
  Quodoc.actions = {};
  Quodoc.plugins = {};
  Quodoc.store = null;
}

export function addPlugin(id: string, factory: Function) {
  return (Quodoc.plugins[id] = factory);
}

async function tryInstallPlugin(id: string) {
  const factory = await import(`../plugins/${id}`);

  if (typeof factory.default === 'undefined') {
    throw new Error(
      `Plugin "${id}" is not callable, it should be a function.
       Please ensure the file located at src/plugins/${id}.ts(x) declares default export of function.`,
    );
  }

  return addPlugin(id, factory.default);
}

export async function bootstrap({ store }: any, force = false) {
  if ((booted || booting) && !force) {
    console.log('App already booted or during booting');
    return;
  }

  booting = true;
  reset();
  Quodoc.store = store;
  const pluginsRaw = get(process.env, 'REACT_APP_QUODOC_PLUGINS', null);

  return new Promise<void>(async (done) => {
    if (pluginsRaw) {
      await Promise.all(
        String(pluginsRaw)
          .split(',')
          .map(async (plugin) => {
            try {
              console.log('REGISTER ', plugin);
              await tryInstallPlugin(plugin);
              console.log('REGISTERED ', plugin);
            } catch (e) {
              console.error('Error occurs during requiring plugin: ' + plugin);
              console.error(e);
            }
          }),
      );
    }

    await Promise.all(
      Object.keys(Quodoc.plugins).map((id) => {
        return Quodoc.plugins[id].call(null, [Quodoc]);
      }),
    );

    booting = false;
    booted = true;
    done();
  });
}

type FilterCallback<Payload, Context, Result = Payload> = (
  payload: Payload,
  context: Context,
) => Result;

export function addFilter<Payload = any, Context = any, Result = Payload>(
  id: string,
  callback: FilterCallback<Payload, Context, Result>,
) {
  Quodoc.filters[id] = Quodoc.filters[id] || [];
  Quodoc.filters[id].push(callback);
}

export function removeFilter(id: string, callback: any) {
  Quodoc.filters[id] = Quodoc.filters[id] || [];
  Quodoc.filters[id] = Quodoc.filters[id].filter(
    (item: any) => item !== callback,
  );
}

export function applyFilters<T, Context = any>(
  id: string,
  value: T,
  context?: Context,
): T {
  const filters = Quodoc.filters[id] || [];

  return filters.reduce((prev: T, current: any) => {
    return current(prev, context);
  }, value) as T | any;
}

export const useFilter = <Payload = any, Context = any>(
  filter: string,
  callback: FilterCallback<Payload, Context>,
) => {
  React.useEffect(() => {
    addFilter(filter, callback);

    return () => removeFilter(filter, callback);
  }, [filter, callback]);
};
