import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { IconButton, Menu, Theme, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    color: theme.palette.text.primary,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
}));

const MenuActions: React.FC<PropsWithChildren> = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
};

const Item: React.FC<any> = ({ className, ...props }) => {
  const classes = useStyles();

  return <MenuItem {...props} className={clsx(classes.item, className)} />;
};

const Icon: React.FC<{ icon: any }> = ({ icon: Icon, ...props }) => {
  const classes = useStyles();

  return <Icon {...props} className={clsx(classes.icon)} />;
};

export default Object.assign(MenuActions, {
  Item,
  Icon,
});
