import React from 'react';
import { File } from '..';
import useFileShareQuery from '../hooks/use-file-share-query';
import Share from 'components/v2/Share/Share';
import Loader from 'components/v2/Loader';
import Section from 'components/v2/Section/Section';

type Props = {
  file: File;
};

const ShareFile = ({ file }: Props) => {
  const { data = null, isLoading } = useFileShareQuery(file);

  if (isLoading) {
    return (
      <Section>
        <Loader />
      </Section>
    );
  }

  return <Share url={data!.url} />;
};

export default ShareFile;
