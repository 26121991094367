import React from 'react';

interface Props {
  config: any;
}

const PwaDynamicManifest: React.FC<Props> = ({ config }) => {
  React.useEffect(() => {
    const manifest = {
      short_name: 'Quodoc',
      name: 'Quodoc - Intelligent dispatching system',
      icons: [
        {
          src: `${config.webFavicon}`,
          sizes: '64x64 32x32 24x24 16x16 192x192 512x512',
          type: 'image/png'
        }
      ],
      start_url: '.',
      display: 'standalone',
      theme_color: `${config.webColor}`,
      background_color: '#ffffff'
    };

    const stringManifest = JSON.stringify(manifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const element = document.getElementById('app-manifest');

    if (element) {
      element.setAttribute('href', url);
    }
  }, [config]);

  return null;
};

export default PwaDynamicManifest;
