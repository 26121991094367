import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'utils/translation';
import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material';
import Tabs from '../../../../components/Tabs/Tabs';
import get from 'lodash/get';
import { useAppFeatures } from '../../../../modules/ui/selectors/app';

interface FormValues {
  title: string | object;
  [key: string]: string | object;
}

type FieldProps = {
  autoFocus?: boolean;
  getError(name: string): string;
  handleChange(event: React.ChangeEvent<any>): void;
  values: FormValues;
};

const TitleFormControl: React.FC<FieldProps & { name: string }> = ({
  name,
  getError,
  values,
  handleChange,
  autoFocus = false,
}) => {
  const inputRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <FormControl error={Boolean(getError(name))} fullWidth>
      <InputLabel>{t('Title')}</InputLabel>
      <Input
        name={name}
        value={get(values, name)}
        onChange={handleChange}
        inputRef={inputRef}
        autoComplete="off"
      />
      {getError(name) && (
        <FormHelperText>{t(`${getError(name)}`)}</FormHelperText>
      )}
    </FormControl>
  );
};

const DirectoryTitleField: React.FC<React.PropsWithChildren<FieldProps>> = (
  props,
) => {
  const { translatableDirectories } = useAppFeatures();

  if (translatableDirectories) {
    return (
      <Tabs
        tabs={['NL', 'FR', 'EN']}
        contents={[
          <TitleFormControl key="nl" name="title.nl" {...props} />,
          <TitleFormControl key="fr" name="title.fr" {...props} />,
          <TitleFormControl key="en" name="title.en" {...props} />,
        ]}
      />
    );
  }

  return <TitleFormControl name="title" {...props} />;
};

export default DirectoryTitleField;
