import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { AppBar, IconButton, Toolbar, Hidden, Input, Box } from '@mui/material';
import ButtonModern from 'components/v2/Button/Button';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'utils/router';
import { matchPath } from 'react-router-dom';
import { LocaleSwitcher } from 'components';
import { CurrentUserActions } from '../../../../modules/auth';
import { useAppSettings } from '../../../../modules/ui/hooks';
import app from 'config/app';
import { Theme } from 'theme';
import { useTranslation } from 'utils/translation';
import { useUiVersion } from '../../../../modules/ui/UserInterfaceVersionProvider';

const classes = {
  root: {
    boxShadow: 'none',
  },
  toolbar: (theme: Theme) => ({
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
    },
  }),
  flexGrow: {
    flexGrow: 1,
  },
  logoWrapper: (theme: Theme) => ({
    width: 202,
    height: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
  }),
  logo: (theme: Theme) => ({
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '202px',
      maxHeight: '64px',
    },
  }),
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 400,
    height: 36,
    marginLeft: 3,
    pt: 0,
    px: 2,
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: 2,
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: (theme: Theme) => ({
    zIndex: theme.zIndex.appBar + 100,
  }),
  searchPopperContent: {
    marginTop: 1,
  },
  logoutButton: {
    marginLeft: 1,
  },
  logoutIcon: {
    marginRight: 1,
  },
  mobileMenuIcon: {
    marginLeft: -1.5,
  },
};

const TopBar = (props: any) => {
  const {
    onOpenNavBarMobile,
    sx,
    showLocaleSwitcher = true,
    showUserActions = true,
    ...rest
  } = props;
  const { t } = useTranslation();
  const { settings } = useAppSettings();
  const logoUrl = get(settings, 'webLogo') || '/images/logos/logo.svg';
  const location = useLocation();
  const navigate = useNavigate();
  let initialSearch = '';
  const uiVersion = useUiVersion();
  const result = matchPath('/search/:search', location.pathname);

  if (result) {
    initialSearch = String(result?.params?.search);
  }

  const searchRef = useRef(null);
  const [searchValue, setSearchValue] = useState(
    decodeURIComponent(initialSearch || ''),
  );

  useEffect(() => {
    if (!location.pathname.startsWith('/search')) {
      setSearchValue('');
    }
  }, [location.pathname]);

  const isFilesPage =
    location.pathname === '/' ||
    location.pathname === app.myDiskPath ||
    location.pathname.startsWith('/search') ||
    location.pathname.startsWith('/directory');

  const shouldShowSearchBar = isFilesPage;

  const updateSearchQuery = useCallback(
    debounce((query: string) => {
      if (query) {
        navigate(`/search/${encodeURIComponent(query)}`);
      } else {
        navigate('');
      }
    }, 750),
    [],
  );

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
    updateSearchQuery(event.target.value);
  };

  const handleSwitchToNewUi = () => {
    uiVersion.switchTo('v2');

    if (isFilesPage) {
      navigate('/assets');
    }
  };

  return (
    <AppBar
      position="fixed"
      {...rest}
      sx={{
        ...classes.root,
        ...sx,
      }}
      color="primary"
    >
      <Toolbar sx={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            sx={classes.mobileMenuIcon}
            color="inherit"
            onClick={onOpenNavBarMobile}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <Box sx={classes.logoWrapper}>
            <Box sx={classes.logo} component="img" alt="Quodoc" src={logoUrl} />
          </Box>
        </RouterLink>
        <Hidden mdDown>
          {shouldShowSearchBar && (
            <Box sx={classes.search} ref={searchRef}>
              <SearchIcon sx={classes.searchIcon} />
              <Input
                sx={classes.searchInput}
                disableUnderline
                onChange={handleSearchChange}
                placeholder="Search..."
                value={searchValue}
                autoFocus={!!initialSearch}
              />
            </Box>
          )}
        </Hidden>
        <Box sx={classes.flexGrow} />
        <Hidden mdDown>
          {app.enableNewUiSwitcher && (
            <ButtonModern
              size="xs"
              variant="black"
              onClick={handleSwitchToNewUi}
            >
              {t('switch_to_the_new_ui')}
            </ButtonModern>
          )}
        </Hidden>
        {showLocaleSwitcher && <LocaleSwitcher />}
        {showUserActions && <CurrentUserActions />}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
