import { eventChannel, END } from 'redux-saga';
import { upload } from '../service/api';
import { QueuedFile } from '../index';

export function createUploadFileChannel(
  file: File & QueuedFile,
  dirId?: number
) {
  return eventChannel(emitter => {
    const onProgress = ({ progress }: any) => {
      emitter({ progress });
    };

    const [promise, abort] = upload({
      file,
      dirId,
      onUploadProgress: onProgress
    });

    promise
      .then(response => {
        emitter({
          success: true,
          response
        });
        emitter(END);
      })
      .catch(error => {
        emitter({ error });
        emitter(END);
      });

    return () => {
      abort();
    };
  });
}
