import React, { Suspense } from 'react';
import Dashboard from '../../components/v2/Layout/Dashboard';
import { LinearProgress } from '@mui/material';
import { renderRoutes, RouteConfig } from '../../utils/render-routes';
import DashboardSidebar from './Sidebar';
import DashboardTopBar from './TopBar';
import ScheduledDownloadsProvider from '../../modules/export/ScheduledDownloadsProvider';
import { UploadingList } from '../../modules/files/components/UploadingList';
import { GatewayProvider, GatewayDest } from 'react-gateway';

type Props = {
  route: {
    routes: RouteConfig[];
  };
  dashboardProps?: React.ComponentProps<typeof Dashboard>;
};

const AssetsDashboard = (props: Props) => {
  const { route, dashboardProps = {} } = props;

  return (
    // @ts-ignore
    <GatewayProvider>
      <Dashboard
        {...dashboardProps}
        topBar={<DashboardTopBar />}
        sidebar={<DashboardSidebar />}
      >
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
        <UploadingList />
        <ScheduledDownloadsProvider />
        {/* @ts-ignore */}
        <GatewayDest name="footer" />
      </Dashboard>
    </GatewayProvider>
  );
};

export default AssetsDashboard;
