import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { ObjectTypeForm } from './ObjectTypeForm';
import { ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AddObjectTypeModal = ({ open, onClose }: Props) => {
  const fullScreen = useShouldModalBeFullscreen();

  return (
    <Dialog
      aria-labelledby="create-new-object-type-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <DialogContent>
        <ModalClose onClose={onClose} />
        <ObjectTypeForm onCancel={onClose} />
      </DialogContent>
    </Dialog>
  );
};
