import React from 'react';
import css from './link.module.scss';
import { Link as BaseLink } from 'react-router-dom';
import clsx from 'clsx';

const Link = ({
  to,
  color,
  size,
  ...rest
}: React.ComponentProps<typeof BaseLink> & { size?: 'small' | 'default' }) => (
  <BaseLink
    {...rest}
    to={to}
    className={clsx(
      css.root,
      color && css[`color-${color}`],
      size && css[`size-${size}`],
    )}
  />
);

export default Link;
