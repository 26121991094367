import React, { PropsWithChildren } from 'react';

type ViewMode = 'default' | 'fullScreen';

type DashboardViewContextType = {
  mode: ViewMode;
  setDefault(): void;
  setFullScreen(): void;
};

const DashboardViewContext =
  React.createContext<DashboardViewContextType | null>(null);

export const DashboardViewContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [mode, setMode] = React.useState<ViewMode>('default');

  const contextValue = React.useMemo(() => {
    const setDefault = () => {
      if (mode !== 'default') {
        setMode('default');
      }
    };

    const setFullScreen = () => {
      if (mode !== 'fullScreen') {
        setMode('fullScreen');
      }
    };

    return {
      mode,
      setDefault,
      setFullScreen,
    };
  }, [mode, setMode]);

  return (
    <DashboardViewContext.Provider value={contextValue}>
      {children}
    </DashboardViewContext.Provider>
  );
};

export const useDashboardView = () => {
  const context = React.useContext(DashboardViewContext);

  if (!context) {
    throw new Error(
      'DashboardViewContext is null. Did you forget to render DashboardViewContextProvider?',
    );
  }

  return context;
};
