import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useResourceContextMenu from '../../../../../modules/resources/hooks/use-resource-context-menu';
import {
  Resource,
  transformDirectoryToResource,
} from '../../../../../modules/resources';
import { Dir } from '../../../../../modules/dirs';
import ContextMenuContainer from '../../../../../views/MyDisk/components/ContextMenu/ContextMenuContainer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
  labelRoot: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  labelIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  labelText: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontWeight: 'inherit',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

interface Props {
  directory: Dir;
  children: ReactNode;
}

export const DirectoryLabel = ({ directory, children, ...other }: Props) => {
  const classes = useStyles();
  const {
    openContextMenu,
    closeContextMenu,
    isOpen: isContextMenuOpen,
    x,
    y,
    resource,
  } = useResourceContextMenu();

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    openContextMenu({
      resource: transformDirectoryToResource(directory),
      x: event.clientX,
      y: event.clientY,
    });
  };

  return (
    <div
      className={classes.labelRoot}
      {...other}
      onContextMenu={handleContextMenu}
    >
      <Typography variant="body2" noWrap className={classes.labelText}>
        {children}
      </Typography>
      <ContextMenuContainer
        open={isContextMenuOpen}
        onClose={closeContextMenu}
        resource={resource as Resource}
        x={x as number}
        y={y as number}
      />
    </div>
  );
};
