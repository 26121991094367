import React from 'react';

type Values = {
  [key: string]: any;
};

const shouldBeVisible = (condition: string, values: Values) => {
  if (!condition) {
    return true;
  }

  const [name, operator, value] = condition.split(':');
  const relatedValue = values[name];

  if (!relatedValue) {
    console.warn('Related field value found: ', name);
    return true;
  }

  switch (operator) {
    case 'eq': {
      return relatedValue === value;
    }
    case 'neq': {
      return relatedValue !== value;
    }
    case 'in': {
      if (typeof relatedValue === 'string') {
        return Array.from(value.split(',') || []).includes(relatedValue);
      }

      break;
    }
    case 'not_in': {
      if (typeof relatedValue === 'string') {
        return !Array.from(value.split(',') || []).includes(relatedValue);
      }

      return true;
    }
    default: {
      throw new Error(`Unsupported operator: ${operator}`);
    }
  }
};

const ConditionalFieldCheckerModern: React.FC<React.PropsWithChildren<any>> = ({
  values,
  condition,
  children,
}) => {
  const isVisible = React.useMemo(
    () => shouldBeVisible(condition, values),
    [values, condition],
  );

  if (!isVisible) {
    return null;
  }

  return children;
};

export default ConditionalFieldCheckerModern;
