import React, { useRef } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';
import FileIntelligentLayerValues from './FileIntelligentLayerValues';
import { File } from 'modules/files';

interface Props {
  open: boolean;
  onClose: () => void;
  file: File;
}

export const FileIntelligentLayerModal = ({ open, onClose, file }: Props) => {
  const { t } = useTranslation();
  const fullScreen = useShouldModalBeFullscreen();
  const contentRef = useRef<HTMLDivElement>(null);

  const handleError = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Dialog
      aria-labelledby="file-intelligent-layer-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
    >
      <DialogContent ref={contentRef}>
        <ModalClose onClose={onClose} />
        <Typography align="center" gutterBottom variant="h3">
          {t('Intelligence Layer')}
        </Typography>
        <FileIntelligentLayerValues
          key={file.id}
          file={file}
          onCancel={onClose}
          onSubmitError={handleError}
        />
      </DialogContent>
    </Dialog>
  );
};
