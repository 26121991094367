import { createResourceActionTypes } from '../actions/resource-actions';
import { Action } from '../actions/action';
import { AnyAction } from 'redux';
import produce from 'immer';
import { Entity } from '../crud/types';

export interface ResourceState<T extends Entity> {
  items: {
    [key: string]: T;
    [key: number]: T;
  };
  order: number[];
  isFetching: boolean;
  isFetchingChildren?: boolean;
  isSubmitting: boolean;
  didCreate: boolean;
  didUpdate: boolean;
  pendingItemId: number | string | null;
  errors: any;
}

export function createResourceReducer<T extends Entity>(prefix: string) {
  if (!prefix) {
    throw new Error('Action prefix is required to create resource reducer');
  }

  const initialState: ResourceState<T> = {
    items: {},
    order: [],
    isFetching: false,
    isSubmitting: false,
    didCreate: false,
    didUpdate: false,
    pendingItemId: null,
    errors: null,
  };

  const Types = createResourceActionTypes(prefix);

  return function resourceReducer(
    state = initialState,
    { type, payload, meta }: Action | AnyAction,
  ) {
    if (!type) {
      return state;
    }

    switch (type) {
      case Types.CLEAR_ERRORS: {
        return produce(state, (draft) => {
          draft.errors = null;
        });
      }

      // Fetch
      case Types.FETCH.REQUEST: {
        return produce(state, (draft) => {
          if (!meta.refresh) {
            draft.isFetching = true;
          }
        });
      }

      case Types.FETCH.SUCCESS: {
        return produce(state, (draft) => {
          const { order } = payload;
          draft.order = order || ([] as number[]);

          Object.keys(payload.items).forEach((key) => {
            draft.items[key] = {
              ...state.items[key],
              ...payload.items[key],
            };
          });
        });
      }

      case Types.FETCH.FULFILL: {
        return {
          ...state,
          isFetching: false,
        };
      }

      // Create
      case Types.CREATE.REQUEST: {
        return {
          ...state,
          isSubmitting: true,
          didCreate: false,
          errors: initialState.errors,
        };
      }

      case Types.CREATE.SUCCESS: {
        return {
          ...state,
          didCreate: true,
          items: {
            ...state.items,
            [payload.id]: payload,
          },
          order: [...state.order, payload.id],
        };
      }

      case Types.CREATE.FAILURE: {
        return {
          ...state,
          errors: payload,
        };
      }

      case Types.CREATE.FULFILL: {
        return {
          ...state,
          didCreate: false,
          isSubmitting: false,
        };
      }

      // Read
      case Types.READ.REQUEST: {
        return produce(state, (draft) => {
          if (!meta.refresh) {
            draft.pendingItemId = payload.id;
          }
        });
      }

      case Types.READ.SUCCESS: {
        return produce(state, (draft) => {
          draft.items[payload.id] = payload;
        });
      }

      case Types.READ.FAILURE: {
        return produce(state, (draft) => {
          draft.errors = payload;
        });
      }

      case Types.READ.FULFILL: {
        return produce(state, (draft) => {
          draft.pendingItemId = null;
        });
      }

      // Update
      case Types.UPDATE.REQUEST: {
        const { id } = payload;

        return {
          ...state,
          didUpdate: false,
          isSubmitting: true,
          pendingItemId: id,
        };
      }

      case Types.UPDATE.SUCCESS: {
        const { id } = payload;

        return produce(state, (draft) => {
          draft.didUpdate = true;
          draft.items[id] = Object.assign(draft.items[id] || {}, payload);
        });
      }

      case Types.UPDATE.FAILURE: {
        return {
          ...state,
          errors: payload,
        };
      }

      case Types.UPDATE.FULFILL: {
        return {
          ...state,
          isSubmitting: false,
          didUpdate: false,
          pendingItemId: null,
        };
      }

      case Types.PATCH_UPDATE.SUCCESS: {
        const { id, ...attributes } = payload;

        return produce(state, (draft) => {
          draft.didUpdate = true;

          if (draft.items[id]) {
            draft.items[id] = {
              ...draft.items[id],
              ...attributes,
            };
          }
        });
      }

      case Types.PATCH_UPDATE.FULFILL: {
        return produce(state, (draft) => {
          draft.didUpdate = false;
          draft.isSubmitting = false;
        });
      }

      // Delete
      case Types.DELETE.REQUEST: {
        const { id } = payload;

        return produce(state, (draft) => {
          if (state.items[id]) {
            delete draft.items[id];
          }

          draft.order = draft.order.filter((itemId) => itemId !== id);
        });
      }

      default:
        return state;
    }
  };
}
