export type Route = keyof RouteParameters;

type Factory<R extends Route> = (typeof routes)[R];

export type Params<R extends Route> = Parameters<Factory<R>>[0];

type RouteParameters = {
  'report.index': { id: string };
  'report.item.details': { id: string; item: string | number };
  'assets.types': never;
  'assets.types.details': { id: string | number };
};

export type RouteCollection = {
  [Route in keyof RouteParameters]: (params: RouteParameters[Route]) => string;
};

export const routes: RouteCollection = {
  'report.index': ({ id }) => `/assets/report/${id}`,
  'report.item.details': ({ id, item }) => `/assets/report/${id}/view/${item}`,
  'assets.types': () => '/assets/types',
  'assets.types.details': ({ id }) => `/assets/types/${id}`,
} as const;
