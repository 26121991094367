import React, { forwardRef, useEffect, useState } from 'react';
import Alert from './Alert';
import AlertProps from './types/AlertProps';

interface Props {
  timeout?: number;
}

const AutoCloseAlert = forwardRef<any, AlertProps & Props>((props, ref) => {
  const [show, setShow] = useState(true);
  const timeout = props.timeout ?? 5000;

  const handleClose = () => {
    setShow(false);
    props.onClose && props.onClose();
  };

  useEffect(() => {
    const timeoutID = setTimeout(handleClose, timeout);

    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  return show && <Alert {...props} onClose={handleClose} />;
});

export default AutoCloseAlert;
