import React, { useEffect, useRef } from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormikBag, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'utils/translation';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0,
  },
}));

export interface FormValues {
  name: string;
}

interface OtherProps {
  onCancel: () => void;
  onSubmit: (
    values: FormValues,
    bag: FormikBag<OtherProps, FormValues>,
  ) => void;
  validate?: (values: FormValues) => void | object | Promise<any>;
  initialValues?: Partial<FormValues>;
}

const BaseResourceForm = (props: OtherProps & FormikProps<FormValues>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const nameRef = useRef();

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,

    onCancel,
  } = props;

  useEffect(() => {
    if (nameRef.current) {
      // @ts-ignore
      nameRef.current.focus();
    }
  }, []);

  const getError = (field: keyof FormValues) => {
    if (touched[field] && errors[field]) {
      return errors[field];
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography align="center" gutterBottom variant="h3">
        {t('Change name')}
      </Typography>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <FormControl error={Boolean(getError('name'))} fullWidth>
            <InputLabel>{t('Name')}</InputLabel>
            <Input
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              inputRef={nameRef}
              autoComplete="off"
            />
            {getError('name') && (
              <FormHelperText>{t(`${getError('name')}`)}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <CardActions className={classes.actions}>
        <Button onClick={onCancel} variant="contained">
          {t('Cancel')}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('Save')}
        </Button>
      </CardActions>
    </form>
  );
};

const buildValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Required'),
  });
};

export const ResourceForm = withFormik<OtherProps, FormValues>({
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      name: initialValues.name || '',
    };
  },
  validationSchema: buildValidationSchema,
  validate: (values, { validate }) => {
    if (validate) {
      return validate(values);
    }
  },
  handleSubmit: (values, bag) => {
    const { onSubmit } = bag.props;
    onSubmit(values, bag);
  },
})(BaseResourceForm);
