import { useState } from 'react';

interface PaginationArguments<T> {
  items: T[];
  page?: number;
  perPage?: number;
}

export function usePagination<T>({
  items,
  page: initialPage = 0,
  perPage = 10,
}: PaginationArguments<T>) {
  const [page, setPage] = useState<number>(initialPage);
  const currentPageItems = items.slice(page * perPage, (page + 1) * perPage);

  return {
    page,
    setPage,
    perPage,
    currentPageItems,
  };
}

export function useTablePagination<T>({ items }: { items: T[] }) {
  const [perPage, setPerPage] = useState(50);
  const possiblePerPage: Array<number | { value: number; label: string }> = [
    25, 50, 100,
  ].filter((item) => item < items.length);
  possiblePerPage.push({ value: -1, label: 'All' });

  const { page, setPage, currentPageItems } = usePagination<T>({
    items,
    perPage,
  });

  const changePage = (page: number) => {
    setPage(page);
  };

  const changePerPage = (perPage: number | string) => {
    if (perPage === 'All') {
      setPerPage(items.length);
    } else {
      setPerPage(+perPage);
    }

    setPage(0);
  };

  return {
    page,
    perPage,
    currentPageItems,
    possiblePerPage,
    changePage,
    changePerPage,
  };
}
