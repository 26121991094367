import React from 'react';
import { useTranslation } from 'utils/translation';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import emptyImage from 'assets/images/empty.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(80),
    margin: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  image: {
    maxWidth: theme.spacing(30),
  },
  title: {
    margin: theme.spacing(2, 0),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

interface Props {
  query: string;
}

const NoSearchResults: React.FC<Props> = ({ query }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} alt="" src={emptyImage} />
      <Typography className={classes.title} variant="h3">
        {t('No search results for "{{query}}"', { query })}
      </Typography>
    </div>
  );
};

export { NoSearchResults };
