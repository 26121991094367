import React from 'react';
import Select from 'components/v2/Select/Select';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldArgs, SelectField } from '../types';
import InputLabel from '../../InputLabel/InputLabel';
import FormControl from 'components/v2/FormControl/FormControl';
import { useFormError } from '../utils';
import FormError from '../../FormError/FormError';

export type SelectAdapterProps = FieldArgs<SelectField>;

const SelectAdapter = (props: SelectAdapterProps) => {
  const { label, name, rules, options } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = useFormError(errors[name]);

  return (
    <FormControl error={!!error}>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field: { ref, value, ...field } }) => (
          <Select
            {...field}
            value={value || null}
            onChange={(event, value) => field.onChange(value)}
          >
            <Select.Option value={null} disabled={rules?.required}>
              ---
            </Select.Option>
            {options.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        )}
        control={control}
        name={name}
        rules={rules}
      />
      <FormError error={error} />
    </FormControl>
  );
};

export default SelectAdapter;
