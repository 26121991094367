import { useState } from 'react';
import { useKeyboardKey } from './use-keyboard-key';

export interface Selection<T> {
  selectedItems: T[];
  isSelected: (item: T) => boolean;
  resetLastSelected: () => void;
  setLastSelected: (item: T) => void;
  selectAll: () => void;
  deselectAll: () => void;
  selectOne: (item: T) => void;
}

export function useSelection<T>(
  items: T[],
  createComparator: (item: T) => (current: T) => boolean
) {
  const { isKeyDown: isShiftKey } = useKeyboardKey('Shift');
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [lastSelected, setLastSelected] = useState<Nullable<T>>(null);

  const selectAll = () => {
    setSelectedItems(items);
  };

  const deselectAll = () => {
    setSelectedItems([]);
  };

  const resetLastSelected = () => {
    setLastSelected(null);
  };

  const selectOne = (item: T) => {
    const nextSelectedItems = getNextValue(item);

    setSelectedItems(nextSelectedItems);
    setLastSelected(item);
  };

  const isSelected = (resource: T) =>
    selectedItems.findIndex(createComparator(resource)) !== -1;

  const getNextValue = (resource: T) => {
    const selectedIndex = selectedItems.findIndex(createComparator(resource));
    const hasBeenSelected = selectedIndex !== -1;

    if (isShiftKey) {
      const newSelectedItems = getNewSelectedItems(resource);

      if (!hasBeenSelected) {
        const additionalItems = newSelectedItems.filter(item => {
          return selectedItems.findIndex(createComparator(item)) === -1;
        });

        return [...selectedItems, ...additionalItems];
      }

      return selectedItems.filter(item => {
        const comparator = createComparator(item);
        return (
          comparator(resource) || newSelectedItems.findIndex(comparator) === -1
        );
      });
    }

    return hasBeenSelected
      ? selectedItems.filter(item => {
          const comparator = createComparator(resource);
          return !comparator(item);
        })
      : [...selectedItems, resource];
  };

  const getNewSelectedItems = (resource: T) => {
    if (lastSelected === null) {
      return [resource];
    }

    const currentSelectedIndex = items.findIndex(createComparator(resource));
    const lastSelectedIndex = items.findIndex(createComparator(lastSelected));

    return items.slice(
      Math.min(lastSelectedIndex, currentSelectedIndex),
      Math.max(lastSelectedIndex, currentSelectedIndex) + 1
    );
  };

  return {
    selectedItems,
    isSelected,
    resetLastSelected,
    setLastSelected,
    selectAll,
    deselectAll,
    selectOne
  };
}
