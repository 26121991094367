import React, { useCallback } from 'react';
import { useEffect } from 'react';

type Args = {
  onChange(event: Event): void;
  multiple?: boolean;
  accept?: string;
};

const appendHiddenInput = ({ multiple = true, onChange, accept }: Args) => {
  const input = document.createElement('input');
  input.style.display = 'none';
  input.type = 'file';
  input.multiple = multiple;
  if (accept) {
    input.accept = accept;
  }

  const listener = (event: Event) => {
    onChange(event);
    cleanup();
  };

  const cleanup = () => {
    input.removeEventListener('change', listener);
    document.body.removeChild(input);
  };

  input.addEventListener('change', listener);
  input.addEventListener('cancel', cleanup);
  document.body.appendChild(input);

  return input;
};

export default function useHiddenFileInput() {
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    return () => {
      if (inputRef.current && document.body.contains(inputRef.current)) {
        document.body.removeChild(inputRef.current);
      }
    };
  }, []);

  return useCallback(({ multiple = false, onChange, accept }: Args) => {
    appendHiddenInput({ multiple, onChange, accept }).click();
  }, []);
}
