import React, { useEffect } from 'react';
import { Dir } from 'modules/dirs';
import { Typography } from '@mui/material';
import { Loader } from 'components';
import {
  useDirIntelligentLayerActions,
  useDirIntelligenceFields,
} from 'modules/intelligent-layer/hooks';
import { useTranslation } from 'utils/translation';
import FieldsForm from 'modules/intelligent-layer/components/FieldsForm';

interface Props {
  dir: Dir;
  onCancel(): void;
  onSubmitError(error?: any): void;
}

const DirectoryIntelligentLayerValues: React.FC<Props> = ({
  dir,
  onCancel,
  onSubmitError,
}) => {
  const { t } = useTranslation();
  const { fetch, update } = useDirIntelligentLayerActions();
  const { isFetching, isSubmitting, fields, error, didUpdate } =
    useDirIntelligenceFields(dir.id);

  useEffect(() => {
    fetch({
      id: dir.id,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (didUpdate) {
      onCancel();
    }
    // eslint-disable-next-line
  }, [didUpdate]);

  useEffect(() => {
    if (error) {
      onSubmitError(error);
    }
    // eslint-disable-next-line
  }, [error]);

  const onSubmit = (payload: any) => {
    update({
      dirId: dir.id,
      fields: payload,
    });
  };

  const hasFields = Array.from(fields || []).length > 0;

  if (isFetching) {
    return <Loader />;
  }

  if (!hasFields) {
    return (
      <Typography variant="subtitle1" align="center">
        {t('This directory does not have any fields yet')}
      </Typography>
    );
  }

  return (
    <FieldsForm
      fields={fields}
      error={error}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

export { DirectoryIntelligentLayerValues };
