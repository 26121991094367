import { useState, useEffect } from 'react';
import get from 'lodash/get';

export enum Direction {
  ASC = 'asc',
  DESC = 'desc'
}

interface SortableArgs<T> {
  direction?: Direction;
  field?: T;
  id: string;
}

function compareByValue(val1: any, val2: any) {
  if (val1 > val2) {
    return 1;
  }

  if (val1 < val2) {
    return -1;
  }

  return 0;
}

export function compareDates<T>(a: T, b: T, field: keyof T) {
  const valA = new Date(get(a, field, 0) as any).getTime();
  const valB = new Date(get(b, field, 0) as any).getTime();

  return compareByValue(valA, valB);
}

export function compareValues<T>(a: T, b: T, field: keyof T | string) {
  const valA = get(a, field, 0);
  const valB = get(b, field, 0);

  return compareByValue(valA, valB);
}

export function localeCompare<T>(a: T, b: T, field: keyof T | string) {
  const valA = get(a, field, '')
    .toString()
    .toLowerCase();
  const valB = get(b, field, '')
    .toString()
    .toLowerCase();

  return valA.localeCompare(valB);
}

const resolveDefaults = (key: string) => {
  try {
    const json = localStorage.getItem(key);
    if (!json) {
      return null;
    }
    return JSON.parse(json);
  } catch {
    return null;
  }
};

export function useSortable<T>(
  { direction: initialDirection, field: initialField, id }: SortableArgs<T> = {
    id: 'global'
  }
) {
  const storageKey = `_quodoc_sortable_${id}`;
  const defaults = resolveDefaults(storageKey) || {};
  const [direction, setDirection] = useState<Direction>(
    defaults.direction || initialDirection || Direction.ASC
  );
  const [field, setSortableField] = useState<T | undefined>(
    defaults.field || initialField
  );

  useEffect(() => {
    if (id) {
      localStorage.setItem(
        storageKey,
        JSON.stringify({
          direction,
          field
        })
      );
    }
  }, [id, direction, field, storageKey]);

  const isActive = (attribute: T) => attribute === field;

  const setField = (attribute: T) => {
    if (field !== attribute) {
      setSortableField(attribute);
    }

    setDirection(direction === Direction.ASC ? Direction.DESC : Direction.ASC);
  };

  return {
    direction,
    setDirection,
    field,
    setField,
    isActive
  };
}
