import { routes, Route, Params } from './routes';

// some route does not have any params, so we have to allow making them optional
export function route<R extends Route>(name: R, params?: Params<R>): string;

export function route<R extends Route>(name: R, params: Params<R>): string {
  const route = routes[name];

  if (!route) {
    throw new Error(`Route ${name} does not exists`);
  }

  return route(params as any);
}
