import React from 'react';
import { RenderRegistry } from './RenderRegistry';
import { registry } from './registry';

export function render<Key extends keyof RenderRegistry>(
  name: Key,
  args: React.ComponentProps<RenderRegistry[Key]>,
) {
  const Component = registry[name] as React.FC<
    React.ComponentProps<RenderRegistry[Key]>
  >;

  if (Component) {
    return <Component {...args} />;
  }
}
