import clsx from 'clsx';
import React from 'react';
import css from './breadcrumbs.module.scss';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';

type Breadcrumb = {
  title: string;
  link?: string;
  onClick?(): void;
};

type Props = {
  items: Breadcrumb[];
  limit?: number;
};

const Breadcrumbs: React.FC<Props> = ({ items: baseItems, limit }) => {
  const [expanded, setExpanded] = React.useState(false);

  let items = baseItems;
  const isLimited = !expanded && limit && items.length > limit + 1;

  if (items.length === 0) {
    return null;
  }

  if (isLimited) {
    const remainingStartIndex = items.length - limit;
    items = items.slice(0, 1).concat(items.slice(remainingStartIndex));
  }

  const isLast = (index: number) => index === items.length - 1;

  return (
    <ul className={css.root}>
      {items.map(({ title, link, onClick }, index) => (
        <React.Fragment key={index}>
          <li className={css.item}>
            <span className={css['title-wrapper']} title={title}>
              {!isLast(index) && link ? (
                <Link to={link} className={css.link}>
                  {title}
                </Link>
              ) : (
                <span
                  onClick={onClick}
                  className={clsx(css.title, onClick && css.link)}
                >
                  {title}
                </span>
              )}
            </span>
            {!isLast(index) && (
              <Icon name={'far fa-chevron-right'} className={css.divider} />
            )}
          </li>
          {isLimited && index === 0 && (
            <li className={css.item}>
              <a className={css.link} onClick={() => setExpanded(true)}>
                ...
              </a>
              <Icon name={'far fa-chevron-right'} className={css.divider} />
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
