import http from '../../../utils/http';

const postFeedbackChannel = (id: number, formData: FormData) => {
  return http.post(`/api/feedback-channel/web-app/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export type FeedbackArgs = {
  title: string;
  type: string;
  comment: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  files?: File[];
};

export const postSubmitFeedback = (id: number, data: FeedbackArgs) => {
  const formData = new FormData();
  formData.append('title', data.title);
  formData.append('type', data.type);
  formData.append('comment', data.comment);
  formData.append('contactFirstName', data.contactFirstName);

  if (data.contactLastName) {
    formData.append('contactLastName', data.contactLastName);
  }

  if (data.contactEmail) {
    formData.append('contactEmail', data.contactEmail);
  }

  if (data.files) {
    (data.files || []).forEach((file) => formData.append('files[]', file));
  }

  return postFeedbackChannel(id, formData);
};

export type MaintenanceRequestArgs = {
  title: string;
  type: string;
  comment: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
};

export const postSubmitMaintenanceRequest = (
  id: number,
  data: MaintenanceRequestArgs,
) => {
  const formData = new FormData();
  formData.append('title', data.title);
  formData.append('type', data.type);
  formData.append('comment', data.comment);
  formData.append('contactFirstName', data.contactFirstName);

  if (data.contactLastName) {
    formData.append('contactLastName', data.contactLastName);
  }

  if (data.contactEmail) {
    formData.append('contactEmail', data.contactEmail);
  }

  return postFeedbackChannel(id, formData);
};
