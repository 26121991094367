export default {
  styleOverrides: {
    root: {},
    input: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: 'unset',
        WebkitTextFillColor: 'unset',
      },
    },
    notchedOutline: {
      borderColor: 'rgba(0,0,0,0.15)',
    },
  },
};
