import React, { createContext, PropsWithChildren } from 'react';
import { SnackBar } from './types';
import Snackbar from './Snackbar';

type ContextType = {
  add(item: SnackBar): void;
  remove(item: SnackBar): void;
};

export const SnackbarContext = createContext<ContextType | null>(null);

const SnackbarsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [items, setItems] = React.useState<Array<SnackBar>>([]);

  const add = React.useCallback(
    (item: SnackBar) => {
      setItems((prev) => [...prev, item]);
    },
    [setItems],
  );

  const remove = React.useCallback(
    (item: SnackBar) => {
      setItems((prev) => prev.filter((i) => i !== item));
    },
    [setItems],
  );

  return (
    <SnackbarContext.Provider value={{ add, remove }}>
      {children}
      {items.map((item, index) => (
        <Snackbar key={index} {...item} />
      ))}
    </SnackbarContext.Provider>
  );
};

export default SnackbarsProvider;
