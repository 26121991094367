import { RESOURCE_TYPE } from './index';
import { selectDir } from '../dirs/selectors';
import { selectFile } from '../files/selectors';
import { RootState } from '../../store';

export function selectResource(state: RootState, id: number, type: string) {
  if (type === RESOURCE_TYPE.DIRECTORY) {
    return selectDir(state, id);
  }

  if (type === RESOURCE_TYPE.FILE) {
    return selectFile(state, id);
  }

  return null;
}
