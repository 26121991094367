import { RootState } from '../../store';
import { Dir } from './index';

function root(state: RootState) {
  return state.dirs;
}

export function selectLoadedDirs(state: RootState) {
  return Object.values(root(state).items).sort((a, b) => {
    if (a.title && b.title) {
      return a.title.localeCompare(b.title);
    }

    return 0;
  });
}

export function selectIsFetching(state: RootState) {
  return root(state).isFetching || root(state).isFetchingChildren;
}

export function selectRootDirs(state: RootState) {
  return state.rootDirs.order
    .map((id: number) => selectDir(state, id))
    .filter((item: Dir | undefined) => item);
}

export function selectIsRootDirsLoading(state: RootState) {
  return state.rootDirs.isLoading;
}

export function selectDir(state: RootState, id: number) {
  return root(state).items[id];
}

export function selectDirChildren(state: RootState, id: number) {
  const dir = selectDir(state, id);

  if (dir && dir.childrenIds) {
    return dir.childrenIds
      .map((id: number) => root(state).items[id])
      .filter((item: Dir | undefined) => item)
      .sort((a: Dir, b: Dir) => a.position - b.position);
  }

  return [];
}

export function selectDirSiblings(state: RootState, id: number) {
  const dir = root(state).items[id];
  if (!dir.parentId) {
    return selectRootDirs(state).filter((item: Dir) => item.id !== id);
  }

  const parent = root(state).items[dir.parentId];

  if (!parent) {
    return [];
  }

  return (parent.childrenIds || [])
    .filter((childId: number) => childId !== id)
    .map((id: number) => selectDir(state, id));
}

export function selectFormState(state: RootState) {
  return {
    isSubmitting: root(state).isSubmitting,
    didCreate: root(state).didCreate,
    didUpdate: root(state).didUpdate,
    errors: root(state).errors
  };
}

export function isDirFetching(state: RootState, id: number) {
  if (!id) {
    return false;
  }

  return Number(root(state).pendingItemId) === Number(id);
}

export function isDirDownloading(state: RootState) {
  // @ts-ignore
  return root(state).isDownloading;
}
