import { useDispatch } from 'react-redux';
import { basketActions } from '../actions';
import { BasketDirPayload, BasketFilePayload } from '../index';

export function useBasketActions() {
  const dispatch = useDispatch();
  const fetchBasket = () => dispatch(basketActions.fetch.trigger());
  const download = () => dispatch(basketActions.download.trigger());
  const addFile = (data: BasketFilePayload) =>
    dispatch(basketActions.addFile.trigger(data));
  const removeFile = (data: BasketFilePayload) =>
    dispatch(basketActions.removeFile.trigger(data));
  const addDirectory = (data: BasketDirPayload) =>
    dispatch(basketActions.addDirectory.trigger(data));
  const removeDirectory = (data: BasketDirPayload) =>
    dispatch(basketActions.removeDirectory.trigger(data));

  return {
    fetchBasket,
    download,
    addFile,
    removeFile,
    addDirectory,
    removeDirectory
  };
}
