import React from 'react';
import Info from '../Info/Info';
import { useTranslation } from 'utils/translation';
import { Stack } from '@mui/material';

const ServerDataError = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Info type="error" message={t('server_data_error_message')} />
    </Stack>
  );
};

export default ServerDataError;
