import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '../../theme';
import { useAuthActions } from '../../modules/auth/hooks';
import { useTranslation } from 'utils/translation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  subtitle: {
    margin: theme.spacing(2, 0),
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ErrorMobileUserAccessDenied: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { logout } = useAuthActions();

  const handleBackHome = () => {
    logout();
  };

  return (
    <main className={classes.root}>
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h2'}>
        {t('This login only gives you access to the mobile APP')}
      </Typography>
      <Typography
        className={classes.subtitle}
        align="center"
        variant="subtitle2"
      >
        {t('Contact your system administrator for more info')}
      </Typography>
      <div className={classes.imageContainer}>
        <img
          alt={t('Access Denied')}
          className={classes.image}
          src="/images/undraw_authentication_fsn5.svg"
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button color="primary" onClick={handleBackHome} variant="outlined">
          {t('Back')}
        </Button>
      </div>
    </main>
  );
};

export default ErrorMobileUserAccessDenied;
