import palette from '../palette';
import typography from '../typography';
import { Components } from '@mui/material';

export default {
  defaultProps: {
    sx: {
      '& a': {
        color: 'inherit',
      },
    },
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...typography.body1,
      borderBottom: `1px solid ${palette.divider}`,
      ...(ownerState.variant === 'head' && {
        fontSize: '12px',
        color: typography.body2.color,
      }),
      ...(ownerState.variant === 'body' && {
        fontSize: '14px',
      }),
    }),
    head: {
      ...typography.body2,
    },
  },
} as Components['MuiTableCell'];
