import React, { Fragment, useEffect } from 'react';
import { Drawer, Paper, Hidden, IconButton, Box } from '@mui/material';
import { useLocation } from 'utils/router';
import NavBarActions from './components/NavBarActions';
import { DirectoryTree } from './components/DirectoryTree';
import CloseIcon from '@mui/icons-material/Close';
import { BasketNavItem } from './components/Basket/BasketNavItem';
import { ReportNav } from './components/Reports/ReportNav';
import { useTranslation } from 'utils/translation';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { HasRole, ROLES } from '../../../../modules/auth';
import { ReactComponent as UsersIcon } from '../../../../assets/icons/users.svg';
import { ReactComponent as DnsIcon } from '../../../../assets/icons/dns.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings.svg';
import { ReactComponent as HelpIcon } from '../../../../assets/icons/help.svg';
import { useAppSettings } from '../../../../modules/ui/hooks';
import { applyFilters, Filters } from '../../../../core';
import { Theme } from '../../../../theme';
import { useCurrentUser } from '../../../../modules/auth/hooks';
import config from 'config/app';
import Nav from './Nav';

const classes = {
  root: (theme: Theme) => ({
    height: 'calc(100% - 64px)',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
    },
  }),
  content: {
    flexGrow: 1,
    paddingTop: 0,
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  divider: {
    marginTop: 2,
  },
  treeContainer: {
    maxHeight: '50vh',
    position: 'relative',
    overflow: 'hidden',
  },
  mobileNavClose: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1100,
  },
};

type ItemsMap = {
  [key: string]: React.ReactElement | null;
};

const NavBar: React.FC<any> = (props) => {
  const { openMobile, onMobileClose, sx, ...rest } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const { settings } = useAppSettings();
  const user = useCurrentUser();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const pathname = location.pathname || '';
  const isDiskOpen =
    pathname === '/' ||
    pathname.startsWith('/directory') ||
    pathname.startsWith(config.myDiskPath);

  const itemsMap = applyFilters<ItemsMap>(Filters.navbar.items, {
    reports: <ReportNav />,
    users: (
      <HasRole roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_EMPLOYEE]}>
        <Nav.Item
          title={t(applyFilters(Filters.navbar.usersTitle, 'Users'))}
          href="/users"
          icon={<UsersIcon />}
        />
      </HasRole>
    ),
    disk: (
      <Nav.Item
        title={t(applyFilters(Filters.navbar.myDiskTitle, 'My Disk'))}
        href={config.myDiskPath}
        icon={<DnsIcon />}
        open={isDiskOpen}
      >
        <Box sx={{ pl: 3 }}>
          <DirectoryTree />
        </Box>
      </Nav.Item>
    ),
    notifications: (
      <HasRole roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_EMPLOYEE]}>
        <Nav.Item
          title={settings.feedback.name || 'Notifications'}
          href="/notifications"
          icon={<NotificationsIcon />}
        />
      </HasRole>
    ),
    basket: <BasketNavItem />,
    settings: (
      <HasRole roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_EMPLOYEE]}>
        <Nav.Item
          title={applyFilters(Filters.navbar.settingsTitle, 'Settings')}
          href="/settings"
          icon={<SettingsIcon />}
        />
      </HasRole>
    ),
    help: settings.helpPageUrl ? (
      <Nav.Item
        title={t('Help')}
        href={settings.helpPageUrl}
        icon={<HelpIcon />}
        external
      />
    ) : null,
  });

  const items = applyFilters(
    Filters.navbar.itemsOrder,
    ['reports', 'disk', 'basket', 'notifications', 'users', 'settings', 'help'],
    { user },
  );

  const navbarContentBefore = applyFilters(Filters.navbar.content.before, null);
  const navbarContentAfter = applyFilters(Filters.navbar.content.after, null);
  const navbarExtraItems = applyFilters(Filters.navbar.extraItems, null);

  const navbarContent = (
    <Box sx={classes.content}>
      {navbarContentBefore}
      <Nav>
        {items.map((id) => (
          <React.Fragment key={id}>{itemsMap[id] || null}</React.Fragment>
        ))}
        {navbarExtraItems}
      </Nav>
      {navbarContentAfter}
    </Box>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box {...rest} sx={(theme) => ({ ...classes.root(theme), ...sx })}>
            <Box sx={classes.mobileNavClose}>
              <IconButton color="inherit" onClick={onMobileClose} size="large">
                <CloseIcon />
              </IconButton>
            </Box>
            <NavBarActions />
            {navbarContent}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Paper
          {...rest}
          sx={(theme) => ({ ...classes.root(theme), ...sx })}
          elevation={1}
          square
        >
          <NavBarActions />
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

export default NavBar;
