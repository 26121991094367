import { useMutation } from 'react-query';
import http from '../../../utils/http';

export function useUpdateReport() {
  return useMutation<any, any, any>(({ id, name }) =>
    http.put<never, any>(`/api/reports/${id}/update`, {
      name,
    }),
  );
}

export default function useSaveReport() {
  return useMutation<any, any, any>(({ id, filters, name, active }) =>
    http.post<never, any>(`/api/reports/${id}/save`, {
      name,
      filters,
      active,
    }),
  );
}
