import { useState, useEffect, useRef } from 'react';

function useWatch(value: string | number | any) {
  const [isChanged, setIsChanged] = useState(false);
  const initialValueRef = useRef(value);

  useEffect(() => {
    if (!isChanged && value !== initialValueRef.current) {
      setIsChanged(true);
    }
  }, [value, isChanged]);

  return {
    isChanged,
    prev: initialValueRef.current,
    current: value,
  };
}

export default useWatch;
