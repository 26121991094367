import React from 'react';
import { ModalProps } from './Modal';
import { ModalComponent } from './types';

export type ModalConfig = Omit<ModalProps, 'open' | 'children' | 'onClose'>;

export type ModalContextType = {
  open(component: ModalComponent, props?: ModalConfig): void;
  close(component: ModalComponent): void;
};

const ModalContext = React.createContext<ModalContextType | null>(null);

export default ModalContext;
