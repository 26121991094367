import React from 'react';
import SortableTree, {
  FullTree,
  NodeData,
  TreeIndex,
  TreeNode
} from '@nosferatu500/react-sortable-tree';
import theme from './Theme';

interface TreeProps {
  treeData: any[];
  isVirtualized?: boolean;
  visibleLength: number;
  maxHeight?: number;
  onChange?: (treeData: any[]) => void;
  onBeforeChildrenRender?: ({ node }: any) => any | null;
  onMoveNode?(data: NodeData & FullTree & any): void;
  onVisibilityToggle?(data: any): void;
  getNodeKey?(data: TreeNode & TreeIndex): string | number;
  generateNodeProps?(data: any): { [index: string]: any };
  canDrag?: ((data: any) => boolean) | boolean;
}

const defaultProps = {
  onBeforeChildrenRender: () => {},
  onChange: () => {},
  onMoveNode: () => {},
  onVisibilityToggle: () => {}
};

export default function Tree(props: TreeProps) {
  const {
    isVirtualized = false,
    treeData,
    maxHeight,
    getNodeKey,
    onChange,
    canDrag,
    onMoveNode,
    onVisibilityToggle,
    generateNodeProps,
    visibleLength
  } = { ...defaultProps, ...props };

  const itemHeight = 30;
  const winHeight = (window && window.innerHeight) * 0.45;
  const itemsHeight = visibleLength * itemHeight;
  let height = Math.min(winHeight, itemsHeight);

  return (
    <div
      className="custom-scrollbar"
      style={{ height: isVirtualized ? height : '100%' }}>
      <SortableTree
        // @ts-ignore
        canDrag={canDrag}
        isVirtualized={isVirtualized}
        treeData={treeData}
        onChange={onChange}
        onVisibilityToggle={onVisibilityToggle}
        onMoveNode={onMoveNode}
        // @ts-ignore
        getNodeKey={getNodeKey}
        generateNodeProps={generateNodeProps}
        // @ts-ignore
        theme={theme}
      />
    </div>
  );
}
