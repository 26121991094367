import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'utils/router';
import { useTranslation } from 'utils/translation';
import css from './page.module.scss';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const APP_NAME = process.env.REACT_APP_APP_NAME;

interface PageProps {
  title: string;
  heading?: string;
  onBack?: React.EventHandler<any>;
  children: any;
  [key: string]: any;
}

const Page = (props: PageProps) => {
  const { t } = useTranslation();
  const { title, heading, onBack, children, ...rest } = props;
  const location = useLocation();

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    // @ts-ignore
    if (window.gtag) {
      // @ts-ignore
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: location.pathname,
        page_name: title,
      });
    }
  }, [title, location]);

  return (
    <div className={css.page}>
      {/* @ts-ignore */}
      <Helmet>
        <title>{`${APP_NAME} - ${title}`}</title>
      </Helmet>
      {onBack && (
        <Button
          onClick={onBack}
          className={css['back-button']}
          size="small"
          variant="text"
          startIcon={<Icon name="fa-regular fa-arrow-left" />}
        >
          {t('Back')}
        </Button>
      )}
      {heading && (
        <Typography variant={'h1'} className={css.heading}>
          {heading}
        </Typography>
      )}
      <div className={css.content}>{children}</div>
    </div>
  );
};

export default Page;
