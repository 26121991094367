import React, { useRef } from 'react';
import { LazyImage as LazyImageBase } from 'react-lazy-images';
import {
  LazyImageRenderPropArgs,
  RefArg,
} from 'react-lazy-images/dist/LazyImage';

const wait = (seconds: number) =>
  new Promise((rs) => setTimeout(rs, seconds * 1000));

type RetryableProps = {
  src: string;
  attempt: number;
  onRetry?(attempt: number): void;
};

const RetryableLoader: React.FC<RetryableProps> = ({
  attempt = 1,
  onRetry,
}) => {
  const isMounted = useRef(false);

  React.useEffect(() => {
    isMounted.current = true;

    (async () => {
      const delay = Math.pow(2, attempt);
      console.log('Retrying to load image after seconds: ', delay);
      await wait(delay);

      if (onRetry && isMounted.current) {
        onRetry(attempt);
      }
    })();

    return () => {
      isMounted.current = false;
    };
  }, [onRetry, attempt]);

  return null;
};

type Props = {
  src: string;
  placeholder: (
    args: LazyImageRenderPropArgs & RefArg,
  ) => React.ReactElement<{}>;
  error: React.ComponentType;
};

const LazyImage: React.FC<Props> = ({
  src,
  placeholder,
  error: ErrorComponent,
}) => {
  const [index, update] = React.useState(1);

  const onRetry = React.useCallback(
    (attempt: number) => {
      // just update `key` here, so that lazy image component
      // will try to re-load image again
      if (attempt <= 5) {
        update((prev) => prev + 1);
      }
    },
    [update],
  );

  return (
    <LazyImageBase
      key={index}
      debounceDurationMs={300}
      src={src}
      alt=""
      placeholder={placeholder}
      error={() => (
        <>
          <ErrorComponent />
          <RetryableLoader attempt={index} src={src} onRetry={onRetry} />
        </>
      )}
      actual={({ imageProps }: any) => <img {...imageProps} alt="" />}
    />
  );
};

export default LazyImage;
