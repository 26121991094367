import { RootState } from '../../store';

const root = (state: RootState) => state.groups;

interface SelectGroupsParams {
  search?: string;
}

export function selectGroups(state: RootState, params?: SelectGroupsParams) {
  const orderIds = (state.groups.order || []) as number[];
  const items = orderIds.map((id: number) => state.groups.items[id]);

  if (params && params.search) {
    return items.filter(item =>
      item.name.toLowerCase().includes((params.search || '').toLowerCase())
    );
  }

  return items;
}

export function isFetching(state: RootState) {
  return state.groups.isFetching;
}

export function selectFormState(state: RootState) {
  return {
    isSubmitting: root(state).isSubmitting,
    didCreate: root(state).didCreate,
    didUpdate: root(state).didUpdate,
    errors: root(state).errors
  };
}
