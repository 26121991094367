import React, { useRef, useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Tooltip,
  Box,
  ClickAwayListener,
} from '@mui/material';
import { useTranslation } from 'utils/translation';
import ButtonModern from 'components/v2/Button/Button';
import TextInputModern from 'components/v2/Input/TextInput';

interface Props {
  buttonClassName?: string;
  value: string;
  isModern?: boolean;
}

const ReadOnlyCopyField: React.FC<Props> = ({
  buttonClassName,
  value,
  isModern,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const BtnComponent = isModern ? ButtonModern : Button;
  const InputComponent = isModern ? TextInputModern : TextField;

  const handleCopyClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      setCopied(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setCopied(false)}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={9} lg={10}>
          <Tooltip
            title={t('Copied to clipboard!') as string}
            placement="top-end"
            open={copied}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <InputComponent
              onClick={handleCopyClipboard}
              // @ts-ignore
              ref={isModern ? inputRef : undefined}
              inputRef={inputRef}
              inputProps={{
                readOnly: true,
              }}
              variant={'outlined'}
              fullWidth
              value={value}
            />
          </Tooltip>
        </Grid>
        <Grid item md={3} lg={2}>
          <Box ml={1}>
            <BtnComponent
              className={buttonClassName}
              variant="outlined"
              color="primary"
              onClick={handleCopyClipboard}
            >
              {t('Copy')}
            </BtnComponent>
          </Box>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};

export default ReadOnlyCopyField;
