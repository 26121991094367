import { useEffect, useRef } from 'react';

const useAutoFocus = <Element extends HTMLInputElement>() => {
  const ref = useRef<Element>();

  useEffect(() => {
    if (ref.current) {
      ref.current?.focus();
    }
  }, []);

  return (node: Element) => {
    ref.current = node;
  };
};

export default useAutoFocus;
