import React, { useState } from 'react';
import ExportActions from '../../../components/v2/Export/ExportActions';
import { useFiltersContext } from '../../../components/v2/Filters/FiltersContext';
import Section from '../../../components/v2/Section/Section';
import { useTableContext } from '../../../components/v2/Table/TableContextProvider';
import { useSortableQueryParams } from '../../../utils/use-data-params';
import ExportFilesModal from '../../reports/components/ExportFilesModal';
import { useReportContext } from '../../reports/context/ReportContext';
import useReportExport from '../../reports/hooks/use-report-export';
import useReportServiceMatrix from '../../reports/hooks/use-report-export-service-matrix';
import { ExportFormat } from '../../reports/types';

export const ExportActionsContextBar = () => {
  const report = useReportContext();
  const { id, name, exportActions } = report;
  const { committedValues: params } = useFiltersContext();
  const { field, direction } = useSortableQueryParams();
  const { attributes } = useTableContext();
  const [exporting, setExporting] = useState(false);

  const exportArgs = {
    id,
    params,
    order: field,
    direction: direction,
    attributes: attributes,
  };

  const [doExport] = useReportExport(exportArgs);
  const [doCreateServiceMatrix] = useReportServiceMatrix(exportArgs);

  const onDataExportClick = (format: ExportFormat) => {
    return doExport({ format, name });
  };

  const onFilesExportClick = () => {
    setExporting(true);
  };

  const onServiceMatrixCreateClick = (format: ExportFormat) => {
    return doCreateServiceMatrix({ format });
  };

  return (
    <Section>
      <ExportActions
        onDataExportClick={
          exportActions.results ? onDataExportClick : undefined
        }
        onFilesExportClick={
          exportActions.attachments ? onFilesExportClick : undefined
        }
        onServiceMatrixExportClick={
          exportActions.serviceMatrix ? onServiceMatrixCreateClick : undefined
        }
      />
      {exporting && (
        <ExportFilesModal
          report={report}
          onClose={() => setExporting(false)}
          params={params}
        />
      )}
    </Section>
  );
};
