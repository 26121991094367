import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Snackbar as MUISnackbar,
  SnackbarContent,
  colors,
} from '@mui/material';
import { Theme } from '../../theme';
import { SnackVariant } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: colors.green[600],
  },
  error: {
    backgroundColor: colors.red[600],
  },
  info: {
    backgroundColor: colors.grey[600],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

interface Props {
  open: boolean;
  onClose(): void;
  icon?: any;
  message: string;
  autoHideDuration?: number;
  variant: SnackVariant;
}

const getIcon = (variant: SnackVariant) => {
  const icons: { [key: string]: React.ComponentType } = {
    success: CheckIcon,
    error: WarningIcon,
  };

  if (icons[variant]) {
    return icons[variant];
  }

  return () => null;
};

const Snackbar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    variant,
    icon: Icon,
    message,
    autoHideDuration = 5000,
    ...restProps
  } = props;
  let SnackIcon = Icon;

  if (!SnackIcon) {
    SnackIcon = getIcon(variant);
  }

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      open={open}
    >
      <SnackbarContent
        className={classes[variant]}
        message={
          <span className={classes.message}>
            <SnackIcon className={classes.icon} />
            {message}
          </span>
        }
        {...restProps}
      />
    </MUISnackbar>
  );
};

export default Snackbar;
