import React from 'react';
import DeviceServiceForm from '../../../../components/v2/DeviceServiceForm/DeviceServiceForm';
import { useModal } from '../../../../components/v2/Modal/useModal';
import { Dir } from '../../../../modules/dirs';
import { useTranslation } from 'utils/translation';
import { useFeedbackItemInfo, useSubmitMaintenanceRequest } from '../hooks';
import { Device, MaintenanceRequestValue } from '../type';
import ServiceRequestForm from './ServiceRequestForm';
import Loader from 'components/v2/Loader';
import Section from 'components/v2/Section/Section';
import useSnackbars from '../../../../components/v2/Snackbar/use-snackbars';

type Props = {
  id: Dir['id'];
  onSuccess?(): void;
};

const MaintenanceRequestForm = ({ id, onSuccess }: Props) => {
  const { t } = useTranslation();
  const mutate = useSubmitMaintenanceRequest(id);
  const { data = null, isLoading } = useFeedbackItemInfo(id);

  const onSubmit = async (values: MaintenanceRequestValue) => {
    await mutate.mutateAsync({
      ...values,
      comment: values.issueDescription || '',
      title: t('maintenance_request'),
      type: t('maintenance_request'),
    });

    onSuccess?.();
  };

  if (isLoading) {
    return (
      <Section>
        <Loader />
      </Section>
    );
  }

  return (
    <DeviceServiceForm attributes={data!.fields} image={data?.cover}>
      <ServiceRequestForm
        action={onSubmit}
        enableDescription
        labels={{
          description: t('service_request_note'),
        }}
      />
    </DeviceServiceForm>
  );
};

export const useMaintenanceRequest = (id: Device['id']) => {
  const { t } = useTranslation();
  const modal = useModal();
  const snackbars = useSnackbars();

  return () => {
    modal.open(({ close }) => (
      <MaintenanceRequestForm
        id={id}
        onSuccess={() => {
          snackbars.add({
            variant: 'success',
            message: t('maintenance_request_sent_successfully'),
          });

          close();
        }}
      />
    ));
  };
};

export default MaintenanceRequestForm;
