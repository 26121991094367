import React, { CSSProperties } from 'react';

const defaultHightLightStyle: Partial<CSSProperties> = {
  fontWeight: 'bold',
};

type Props = {
  text: string;
  highlight: string;
  highLightStyle?: Partial<CSSProperties>;
  rootRef?: any;
};

const HighlightText: React.FC<Props> = ({
  text,
  highlight,
  highLightStyle = defaultHightLightStyle,
  rootRef,
  ...rest
}) => {
  const safeRegex = (highlight + '').replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&');
  const parts = String(text).split(new RegExp(`(${safeRegex})`, 'gi'));

  return (
    <span {...rest} ref={rootRef}>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase() ? highLightStyle : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export default HighlightText;
