import React from 'react';
import { isDir, isFile, useResourceModal } from '../../../modules/resources';
import { ResourceModal } from '../../../modules/ui';
import {
  ShareFileModal,
  DirPermissionsModal,
  MoveFileFormModal,
  UpdateDirectoryFormModal,
  UpdateFileFormModal,
  DirectoryIntelligentLayerModal,
  MoveDirectoryFormModal,
  FileIntelligentLayerModal
} from './Form';

const ResourceModals = () => {
  const { resource, activeModalId, closeModal } = useResourceModal();

  if (!resource) {
    return null;
  }

  switch (activeModalId) {
    case ResourceModal.Edit: {
      if (isDir(resource)) {
        return (
          <UpdateDirectoryFormModal
            open={true}
            onClose={closeModal}
            resource={resource}
          />
        );
      }
      if (isFile(resource)) {
        return (
          <UpdateFileFormModal
            open={true}
            onClose={closeModal}
            resource={resource}
          />
        );
      }
      break;
    }
    case ResourceModal.Permissions: {
      if (isDir(resource)) {
        return (
          <DirPermissionsModal
            open={true}
            onClose={closeModal}
            dir={resource}
          />
        );
      }
      break;
    }
    case ResourceModal.Move: {
      if (isFile(resource)) {
        return (
          <MoveFileFormModal open={true} onClose={closeModal} file={resource} />
        );
      }
      if (isDir(resource)) {
        return (
          <MoveDirectoryFormModal
            open={true}
            onClose={closeModal}
            dir={resource}
          />
        );
      }
      break;
    }

    case ResourceModal.Share: {
      if (isFile(resource)) {
        return (
          <ShareFileModal open={true} onClose={closeModal} file={resource} />
        );
      }
      break;
    }

    case ResourceModal.IntelligentLayer: {
      if (isDir(resource)) {
        return (
          <DirectoryIntelligentLayerModal
            open={true}
            onClose={closeModal}
            dir={resource}
          />
        );
      }

      if (isFile(resource)) {
        return (
          <FileIntelligentLayerModal
            open={true}
            onClose={closeModal}
            file={resource}
          />
        );
      }
      break;
    }
    default:
      break;
  }

  return null;
};

export default ResourceModals;
