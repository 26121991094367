import React, { useEffect, useMemo } from 'react';
import { useDirsActions, useSingleDir } from '../modules/dirs/hooks';
import { File } from '../modules/files';
import { Select } from 'components';
import { ValueType } from 'react-select/src/types';
import { useFileList } from '../modules/files/hooks';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type Props = {
  value: number;
  multiple?: boolean;
  directoryId: number;
  onChange(file: File): void;
};

const usePreviewStyles = makeStyles(() => ({
  preview: {
    width: '85px',
    height: '55px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const SmallFilePreview: React.FC<{ file: File }> = ({ file }) => {
  const classes = usePreviewStyles();

  return (
    <Box mr={1} py={0.5}>
      <div className={classes.preview}>
        <img className={classes.image} src={file.thumbnailUrl} alt="" />
      </div>
    </Box>
  );
};

const DirectoryFilePicker: React.FC<Props> = ({
  value,
  onChange,
  multiple,
  directoryId,
}) => {
  const { readDir } = useDirsActions();
  const { dir } = useSingleDir(directoryId);
  const { files = [] } = useFileList(directoryId);

  useEffect(() => {
    readDir({ id: directoryId });
  }, [readDir, directoryId]);

  const handleChange = (option: ValueType<any>) => {
    const file = files.find((item) => item.id === option.value);
    if (file) {
      onChange(file);
    }
  };

  const options = useMemo(() => {
    return files
      .filter((file) =>
        ['jpg', 'jpeg', 'png', 'webp', 'bmp', 'svg'].includes(file.type),
      )
      .map((file) => ({
        label: file.filename,
        value: file.id,
        leftSlot: () => <SmallFilePreview file={file} />,
      }));
  }, [files]);

  const currentValue = value
    ? options.find((item) => +item.value === +value)
    : null;

  if (!dir) {
    return null;
  }

  return (
    <Select
      value={currentValue}
      onChange={handleChange}
      multiple={multiple}
      options={options}
    />
  );
};

export default DirectoryFilePicker;
