import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'utils/translation';
import { Button, CardActions, Typography, Box } from '@mui/material';
import { Dir } from 'modules/dirs';
import DirectorySelector from './DirectorySelector';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  divider: {
    height: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0,
  },
}));

interface MoveFormProps {
  onCancel: () => void;
  onSubmit: (dirId: number) => void;
  title: string;
  filter?(dir: Dir): boolean;
}

export const MoveForm: React.FC<MoveFormProps> = ({
  onCancel,
  onSubmit,
  title,
  filter,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dir, setDir] = React.useState<Nullable<Dir>>(null);

  const handleSubmit = () => {
    if (dir) {
      onSubmit(dir.id);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className={classes.root}>
      <Typography align="center" gutterBottom variant="h3">
        {title}
      </Typography>
      <Box py={2}>
        <DirectorySelector
          value={dir}
          onChange={setDir}
          filter={filter}
          expanded
        />
      </Box>
      <div className={classes.divider} />
      <CardActions className={classes.actions}>
        <Button variant="contained" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={!dir}
          type="submit"
          color="primary"
          onClick={handleSubmit}
        >
          {t('Move')}
        </Button>
      </CardActions>
    </div>
  );
};
