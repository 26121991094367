export const SHOW_RESOURCE_MODAL = '@UI/RESOURCE_MODAL/SHOW';
export const HIDE_RESOURCE_MODAL = '@UI/RESOURCE_MODAL/HIDE';

export enum ResourceModal {
  Edit = 'Edit',
  Move = 'Move',
  Share = 'Share',
  Permissions = 'Permissions',
  IntelligentLayer = 'IntelligentLayer'
}
