import { createResourceAction } from '../@common/actions/resource-actions';
import {
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  FETCH_USER,
  SWITCH_USER,
  RESET_USER
} from './constants';
import { LoginPayload } from './index';
import { createAction } from '../@common/actions/action';

export const login = createResourceAction<LoginPayload>(LOGIN);
export const logout = createResourceAction(LOGOUT);
export const refreshToken = createResourceAction(REFRESH_TOKEN);
export const fetchUser = createResourceAction(FETCH_USER);

export const switchUser = createAction<{ username: string }>(SWITCH_USER);

export const resetUser = createAction<void>(RESET_USER);
