import React from 'react';
import { File } from '../index';
import { useModal } from '../../../components/v2/Modal/useModal';
import ShareFile from '../components/ShareFile';

const useFileShare = (file: File) => {
  const modal = useModal();

  return () => {
    modal.open(() => <ShareFile file={file} />, { size: 'small' });
  };
};

export default useFileShare;
