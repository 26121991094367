import React, { useEffect } from 'react';
import { File } from 'modules/files';
import { Typography } from '@mui/material';
import { Loader } from 'components';
import {
  useFileIntelligenceFields,
  useUpdateFileIntelligenceFields,
} from 'modules/intelligent-layer/hooks';
import { useTranslation } from 'utils/translation';
import FieldsForm from 'modules/intelligent-layer/components/FieldsForm';

interface Props {
  file: File;
  onCancel(): void;
  onSubmitError(error?: any): void;
}

const FileIntelligentLayerValues: React.FC<Props> = ({
  file,
  onCancel,
  onSubmitError,
}) => {
  const { t } = useTranslation();
  const {
    data = [],
    isLoading,
    isError,
    refetch,
  } = useFileIntelligenceFields(file);
  const mutation = useUpdateFileIntelligenceFields(file);
  let errorMessage = null;

  if (mutation.isError) {
    errorMessage = 'An unexpected error occurs';

    if (mutation.error?.response?.status === 400) {
      errorMessage = mutation.error?.response?.data?.message;
    }
  }

  useEffect(() => {
    if (mutation.isError) {
      onSubmitError();
    }
    // eslint-disable-next-line
  }, [mutation.isError]);

  useEffect(() => {
    if (mutation.isSuccess) {
      onCancel();
    }
    // eslint-disable-next-line
  }, [mutation.isSuccess]);

  const onSubmit = async (payload: any) => {
    await mutation.mutateAsync(payload);
    await refetch();
  };

  const fields = Array.from(data || []);
  const hasFields = fields.length > 0;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Typography variant="subtitle1" align="center">
        {t('An unexpected error occurs')}
      </Typography>
    );
  }

  if (!hasFields) {
    return (
      <Typography variant="subtitle1" align="center">
        {t('This file does not have any fields')}
      </Typography>
    );
  }

  return (
    <FieldsForm
      fields={fields}
      error={errorMessage}
      isSubmitting={false}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

export default FileIntelligentLayerValues;
