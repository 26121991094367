import React from 'react';
import { useTranslation } from 'utils/translation';
import Info from '../Info/Info';

type Props = {
  message?: string;
};

const Empty = ({ message }: Props) => (
  <Info message={message} type={'primary'} />
);

const Directory = () => {
  const { t } = useTranslation();

  return <Empty message={t('directory_is_empty')} />;
};

const General = () => {
  const { t } = useTranslation();

  return <Empty message={t('no_data_found')} />;
};

export default Object.assign(Empty, {
  Directory,
  General,
});
