import { queryCache, useMutation } from 'react-query';
import useConfirmation from '../../../components/v2/Confirmation/use-confirmation';
import { route } from '../../../router';
import http from '../../../utils/http';
import { useTranslation } from 'utils/translation';
import { useNavigate } from 'utils/router';
import { Report } from '../types';

export default function useRemoveReport() {
  return useMutation<any, any, any>(({ id }) =>
    http.delete<never, any>(`/api/reports/${id}`),
  );
}

export const useRemoveReportWithConfirmation = ({ id, name }: Report) => {
  const { t, i18n } = useTranslation();
  const [mutate] = useRemoveReport();
  const navigate = useNavigate();
  const confirm = useConfirmation();

  const onRemove = async () => {
    const { parent } = await mutate({ id });
    await queryCache.refetchQueries(`api_available_reports_${i18n.language}`);
    navigate(route('report.index', { id: parent }));
  };

  return () => {
    void confirm({
      title: t('Remove'),
      question: t('confirmation_delete_description', { subject: name }),
      confirmText: t('Remove'),
      cancelText: t('Cancel'),
      onConfirm: onRemove,
    });
  };
};
