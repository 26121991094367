export default {
  styleOverrides: {
    gutterBottom: {
      marginBottom: 8,
    },
    h3: {
      marginTop: 8,
      marginBottom: 16,
    },
  },
};
