import { useCallback, useState } from 'react';
import { get } from 'lodash';
import normalizeErrors from '../util/normalize-api-errors';

interface ApiCallArgs {
  provider: (...args: any) => Promise<any>;
  initialIsLoading?: boolean;
  resetState?: boolean;
}

const extractSaveErrorMessage = (error: any) => {
  if (!error) {
    return null;
  }

  if ([400].includes(error.code)) {
    return error.message || '';
  }
};

export function useApiCall({
  provider,
  initialIsLoading = false,
  resetState = true
}: ApiCallArgs) {
  const [response, setResponse] = useState<any>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(initialIsLoading);

  const reset = () => {
    if (resetState) {
      setResponse(null);
      setSuccess(false);
      setError(null);
      setIsLoading(false);
    }
  };

  const request = async (...args: any) => {
    try {
      reset();
      setIsLoading(true);
      const result = await provider(...args);
      setResponse(result);
      setSuccess(true);

      return result;
    } catch (error) {
      const errorResponse = get(error, 'response.data');

      if (!errorResponse) {
        throw error;
      }

      setError(errorResponse);
      setValidationErrors(normalizeErrors(errorResponse.errors));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    response,
    success,
    error,
    errorMessage: extractSaveErrorMessage(error),
    validationErrors,
    request: useCallback(request, [provider]),
    reset
  };
}
