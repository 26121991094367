import debounce from 'lodash/debounce';
import React, { useCallback } from 'react';
import TextInput from '../../Input/TextInput';
import { AdapterProps, SearchAdapterProps } from '../types';
import InputLabel from '../../InputLabel/InputLabel';
import css from '../filters.module.scss';

type Value = string;

const SearchAdapter = (
  props: AdapterProps<Value> & SearchAdapterProps<Value>,
) => {
  const { name, label, onChange, defaultValue = '' } = props;

  const handleChange = useCallback(
    debounce((event) => {
      event.persist();
      onChange?.(event.target.value);
    }, 250),
    [onChange],
  );

  return (
    <div className={css.filter}>
      <InputLabel className={css.label}>{label}</InputLabel>
      <TextInput
        variant="filter"
        name={name}
        defaultValue={defaultValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchAdapter;
