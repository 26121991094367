import React from 'react';
import { DatePicker as BaseDatePicker } from '@mui/x-date-pickers/DatePicker';
import css from './date-picker.module.scss';
import clsx from 'clsx';

export type Value = number | null;

type Props = {
  modifier?: 'default' | 'filter';
};

const DatePicker: React.FC<
  Props & React.ComponentProps<typeof BaseDatePicker>
> = (props) => {
  const { modifier = 'default' } = props;

  return (
    <BaseDatePicker
      {...props}
      slotProps={{
        toolbar: {
          className: css.field,
        },
        field: {
          className: css.field,
        },
        textField: {
          className: clsx(css['text-field'], css[modifier]),
          size: 'small',
          inputProps: { className: css.input },
        },
        actionBar: {
          actions: ['today', 'clear'],
        },
      }}
    />
  );
};

export default DatePicker;
