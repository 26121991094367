import { User } from '../users';
import { userHasRole } from './hooks';
import { ROLES } from './index';

type AuthChecker = (user: User) => boolean;

export const isUser = (user: User) => userHasRole(user, [ROLES.USER]);

export const isAdmin = (user: User) => userHasRole(user, [ROLES.ROLE_ADMIN]);

export const isEmployee = (user: User) =>
  userHasRole(user, [ROLES.ROLE_EMPLOYEE]);

export const isSuperAdmin = (user: User) =>
  userHasRole(user, [ROLES.ROLE_SUPER_ADMIN]);

export const isOneOf =
  (...checkers: AuthChecker[]) =>
  (user: User) => {
    return checkers.some((item) => item(user));
  };
