import React from 'react';
import { useUiModal } from '../../../../modules/ui/hooks';
import {
  CREATE_DIRECTORY_MODAL_ID,
  CREATE_OBJECT_TYPE_MODAL_ID,
} from '../../../../modules/ui';
import { Box, Button, Divider, Theme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'utils/translation';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as CreateDocumentIcon } from '../../../../assets/icons/add-document.svg';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    minWidth: theme.spacing(27),
    minHeight: theme.spacing(4),
    padding: theme.spacing(1, 4),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const GeneralActions = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { show } = useUiModal();
  const classes = useStyles();
  const { t } = useTranslation();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleCreateDirClick = () => {
    show(CREATE_DIRECTORY_MODAL_ID);
    handleClose();
  };

  const handleCreateObjectTypeClick = () => {
    show(CREATE_OBJECT_TYPE_MODAL_ID);
    handleClose();
  };

  const handleNewUploadClick = () => {
    const input = document.getElementById('file-upload-input');
    if (input) {
      input.click();
    }
    handleClose();
  };

  const standardActions = [
    {
      id: 'New folder',
      icon: CreateNewFolderIcon,
      action: handleCreateDirClick,
    },
    {
      id: 'New upload',
      icon: CloudUploadIcon,
      action: handleNewUploadClick,
    },
  ];

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleClick}>
        <AddIcon />
        {t('Add')}
      </Button>
      <Menu
        id="navbar-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={handleCreateObjectTypeClick}
        >
          <CreateDocumentIcon className={classes.icon} />
          {t('New Object Type')}
        </MenuItem>
        <Box my={1} mx={4}>
          <Divider />
        </Box>
        {standardActions.map(({ id, icon: Icon, action }) => (
          <MenuItem
            className={classes.menuItem}
            key={id}
            onClick={() => {
              action && action();
              handleClose();
            }}
          >
            <Icon className={classes.icon} />
            {t(id)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default GeneralActions;
