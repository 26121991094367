import { createAction, createType } from '../../@common/actions/action';
import * as actionTypes from '../constants/resourceModal';
import { ResourceModal } from '../constants/resourceModal';
import { Resource } from '../../resources';

export type ShowResourceModalPayload = {
  id: ResourceModal;
  resource: Resource;
};

export const showResourceModal = createAction<ShowResourceModalPayload>(
  actionTypes.SHOW_RESOURCE_MODAL
);
export const hideResourceModal = createAction(actionTypes.HIDE_RESOURCE_MODAL);

export const ResourceModalActionTypes = {
  SHOW: createType(actionTypes.SHOW_RESOURCE_MODAL),
  HIDE: createType(actionTypes.HIDE_RESOURCE_MODAL)
};
