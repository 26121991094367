import { RootState } from '../../../store';

export const selectCurrentDir = (state: RootState) => {
  return state.ui.disk.selectedDirId;
};

export const selectPerPage = (state: RootState) => {
  return state.ui.disk.perPage;
};

export const selectUIError = (state: RootState) => {
  return state.ui.disk.error;
};
