import React, { forwardRef, Suspense } from 'react';
import Loader from '../Loader/Loader';
import { EditorProps } from './TinyMceEditor';

// load editor via lazy import so all JS (~ 1MB+) will be on seperated chunk
const LazyEditor = React.lazy(() => import('./TinyMceEditor'));

const Editor = forwardRef<HTMLDivElement, EditorProps>((props, ref) => {
  return (
    <Suspense fallback={<Loader />}>
      <LazyEditor {...props} ref={ref} />
    </Suspense>
  );
});

export default Editor;
