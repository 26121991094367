import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import ListItem from './ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function SortableItem({ disabled, ...props }: any) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.id,
    disabled,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      {...props}
      {...attributes}
      rightIcon={
        disabled ? null : (
          <ListItemIcon>
            <IconButton {...listeners}>
              <MenuIcon />
            </IconButton>
          </ListItemIcon>
        )
      }
    />
  );
}

export default SortableItem;
