import React from 'react';
import { render } from '../../../core/render';
import { File } from '../../../modules/files';
import { Dir } from '../../../modules/dirs';
import { Action } from '../../ActionSheet/ActionSheet';

type BaseResource = {
  id: number;
  name: string;
  date: string;
  actions: React.ReactNode;
  contextActions?: Action[];
  icon: React.ReactNode;
};

export type FileResource = BaseResource & {
  type: 'file';
  original: File;
};

export type DirectoryResource = BaseResource & {
  type: 'directory';
  original: Dir;
};

export type Resource = FileResource | DirectoryResource;

export const mapFileToTableRow = (item: File): FileResource => ({
  id: item.id,
  name: `${item.filename}.${item.type}`,
  date: item.createdAt,
  actions: render('file.actions', { file: item }),
  icon: render('file.icon', { file: item }),
  type: 'file',
  original: item,
});

export const mapDirToTableRow = (item: Dir): DirectoryResource => ({
  id: item.id,
  name: item.title,
  date: item.createdAt,
  actions: render('directory.actions', { dir: item }),
  icon: render('directory.icon', { dir: item }),
  type: 'directory',
  original: item,
});
