import React, { useMemo, useRef, useState } from 'react';
import Modal from './Modal';
import ModalContext, { ModalConfig } from './ModalContext';
import ModalContentContext from './ModalContentContext';
import { ModalComponent } from './types';

const ModalProvider = ({ children }: React.PropsWithChildren) => {
  const [stack, setStack] = useState<ModalComponent[]>([]);
  const extraProps = useRef(new WeakMap());
  const contentRef = useRef<HTMLDivElement | null>(null);

  const value = useMemo(() => {
    const open = (component: ModalComponent, props: ModalConfig = {}) => {
      extraProps.current.set(component, props);
      setStack((prev) => [...prev, component]);

      return () => close(component);
    };

    const close = (component: ModalComponent) => {
      extraProps.current.delete(component);
      setStack(stack.filter((item) => item !== component));
    };

    return {
      open,
      close,
    };
  }, [stack, setStack]);

  const contentContext = useMemo(
    () => ({
      scrollToTop: () => {
        contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
      },
    }),
    [contentRef.current],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {stack.length > 0 &&
        stack.map((Component, index) => {
          const props = extraProps.current.get(Component) || {};

          return (
            <Modal
              key={index}
              {...props}
              open
              onClose={() => {
                value.close(Component);
                props.onClose?.();
              }}
            >
              <ModalContentContext.Provider value={contentContext}>
                <Modal.Content rootRef={contentRef}>
                  <Component close={() => value.close(Component)} />
                </Modal.Content>
              </ModalContentContext.Provider>
            </Modal>
          );
        })}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
