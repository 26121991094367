import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import http from '../../utils/http';
import {
  hideModal,
  resetCurrentDir,
  setCurrentDir,
  setPerPage,
  setSettings as setSettingsAction,
  SettingsPayload,
  showModal,
} from './actions';
import {
  selectCurrentDir,
  selectPerPage,
  selectUIError,
} from './selectors/disk';
import { selectActiveModal } from './selectors/modal';
import { ViewMode } from './types';

export function useSystemConfigQuery() {
  return useQuery('app_system_config', () =>
    http.get<never, SettingsPayload>('system/config'),
  );
}

export function useAppSettings() {
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: RootState) => state.ui.app,
    shallowEqual,
  );
  const setSettings = (settings: any) => dispatch(setSettingsAction(settings));

  return {
    settings,
    setSettings,
  };
}

export function useUiModal() {
  const dispatch = useDispatch();
  const activeModal = useSelector(selectActiveModal, shallowEqual);
  const show = useCallback(
    (id: string) => dispatch(showModal({ id })),
    [dispatch],
  );
  const hide = useCallback(() => dispatch(hideModal()), [dispatch]);

  return {
    activeModal,
    show,
    hide,
  };
}

export function useDiskPagination() {
  const dispatch = useDispatch();
  const set = (perPage: number) =>
    dispatch(
      setPerPage({
        perPage,
      }),
    );
  const perPage = useSelector(selectPerPage, shallowEqual);

  return {
    setPerPage: set,
    perPage,
  };
}

export function useCurrentDir() {
  const dispatch = useDispatch();
  const currentDirId = useSelector(selectCurrentDir, shallowEqual);
  const setCurrent = (id: string | number) => dispatch(setCurrentDir({ id }));
  const reset = () => dispatch(resetCurrentDir());

  return {
    currentDirId,
    setCurrent,
    reset,
  };
}

export function useResultsViewMode() {
  const persistKey = '@app.view_mode';
  const defaultMode =
    (localStorage.getItem(persistKey) as unknown as ViewMode) ||
    (ViewMode.Table as ViewMode);
  const [viewMode, setView] = useState<ViewMode>(defaultMode);
  const changeViewMode = (mode: ViewMode) => {
    localStorage.setItem(persistKey, String(mode));
    setView(mode);
  };

  return {
    viewMode: [ViewMode.Table, ViewMode.Grid, ViewMode.Contextual].includes(
      viewMode,
    )
      ? viewMode
      : ViewMode.Table,
    changeViewMode,
  };
}

export function useUIError() {
  return useSelector(selectUIError, shallowEqual);
}
