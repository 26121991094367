import React from 'react';
import useReportSpec from '../../reports/hooks/use-report-spec';
import ReportContextProvider, {
  useReportContext,
} from '../../reports/context/ReportContext';
import ResourceModals from '../../../views/MyDisk/components/ResourceModals';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { FiltersContextProvider } from '../../../components/v2/Filters/FiltersContext';

const SkeletonLoader = () => (
  <Box sx={{ p: 2, flexGrow: 1 }}>
    <Skeleton height="35px" width="30%" />
    <Box mb={1} />
    <Skeleton height="15px" width="40%" />
    <Box mb={1} />
    <Skeleton height="15px" width="10%" />
    <Skeleton height="300px" />
  </Box>
);

export const ReportConfigProvider = ({
  id,
  children,
}: React.PropsWithChildren<{ id: string }>) => {
  const { data, isLoading } = useReportSpec({ id });

  if (!data || isLoading) {
    return <SkeletonLoader />;
  }

  return <ReportContextProvider {...data}>{children}</ReportContextProvider>;
};

export const ReportFiltersProvider = ({
  children,
}: React.PropsWithChildren) => {
  const { defaultValues } = useReportContext();

  return (
    <FiltersContextProvider defaultValues={defaultValues}>
      {children}
    </FiltersContextProvider>
  );
};

export default ({ children, id }: React.PropsWithChildren<{ id: string }>) => (
  <ReportConfigProvider id={id}>
    <ReportFiltersProvider>{children}</ReportFiltersProvider>
    <ResourceModals />
  </ReportConfigProvider>
);
