import { colors } from '@mui/material';
import typography from '../typography';

export default {
  styleOverrides: {
    root: {
      backgroundColor: colors.grey[50],
      fontSize: '12px',
      color: typography.body1.color,
    },
  },
};
