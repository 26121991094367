import React from 'react';
import { useSelector } from 'react-redux';
import { selectSwitchedUsername } from '../selectors';
import { useUserSwitchActions } from '../hooks';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Card,
  Typography,
  CardContent,
  CardActions,
} from '@mui/material';
import { Trans } from 'react-i18next';
import { useTranslation } from 'utils/translation';

const SwitchedUserNotification = () => {
  const { t } = useTranslation();
  const switchedUser = useSelector(selectSwitchedUsername);
  const { resetUser } = useUserSwitchActions();

  if (switchedUser) {
    return (
      <Box
        sx={{
          position: 'fixed',
          left: 2,
          bottom: 2,
          zIndex: 10000,
        }}
      >
        <Card raised>
          <CardContent sx={{ px: 2, pt: 2 }}>
            <Typography variant="body2">
              <Trans i18nKey="acting_as_user">
                {/* @ts-ignore */}
                You are acting as user <strong>{{ switchedUser }}</strong>
              </Trans>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => resetUser()}
            >
              <ArrowBackIcon />
              {t('leave_switched_user_account')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    );
  }

  return null;
};

export default SwitchedUserNotification;
