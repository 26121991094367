import http from '../../../utils/http';

interface SearchParams {
  query?: string;
}

export function search(params: SearchParams) {
  return http.get('api/search', {
    params: {
      ...params,
      limit: 100
    }
  });
}
