import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { useNavigate } from 'utils/router';
import { RootState } from '../../store';

const GuestGuard = (props: any) => {
  const { children } = props;
  const user = useSelector((state: RootState) => state.auth.user, shallowEqual);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  if (user) {
    return null;
  }

  return <>{children}</>;
};

export default GuestGuard;
