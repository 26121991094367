import React from 'react';
import { FieldProps } from './types';
import { FormControl, InputLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const AutocompleteField: React.FC<FieldProps> = ({
  value,
  errorMessage,
  name,
  multiple,
  settings = {},
  form,
}) => {
  const options = Array.from(settings.options || []) as any[];

  const handleChange = (event: React.ChangeEvent<{}>, item: any) => {
    if (item) {
      form.setFieldValue(name, item.value, false);
    } else {
      form.setFieldValue(name, null, false);
    }
  };

  const resolveValue = (value: any) => {
    if (value && typeof value === 'object' && 'value' in value) {
      return value.value;
    }

    return value;
  };

  const isOptionSelected = (option: any, value: any) => {
    if (Array.isArray(value)) {
      const multipleValue = value.map(resolveValue);
      return multipleValue.includes(option.value);
    }

    return option.value.toString() === (resolveValue(value) || '').toString();
  };

  const selectedOptions = options
    .filter((option) => isOptionSelected(option, value))
    .map((item) => item.label);

  return (
    <FormControl fullWidth variant="outlined" error={!!errorMessage}>
      {errorMessage && <InputLabel>{errorMessage}</InputLabel>}
      <Autocomplete
        id={name}
        freeSolo
        options={options}
        multiple={multiple}
        onChange={handleChange}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={isOptionSelected}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            inputProps={{
              ...params.inputProps,
              // @ts-ignore
              value: params.inputProps.value || selectedOptions.join(','),
            }}
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};

export { AutocompleteField };
