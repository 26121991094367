import React, { useEffect, useRef, useState } from 'react';
import { useCancelDownload, useScheduledDownloads } from './hooks';
import { Item } from './types';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  MenuList,
  MenuItem,
  Tooltip,
  ListItemText,
  ListItemIcon,
  IconButton,
  Collapse,
  Grow,
  CircularProgress,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'utils/translation';
import useWatch from 'utils/use-watch';

const DownloadItem = (item: Item & { onCancel(): void }) => {
  const { t } = useTranslation();
  const downloadedRef = useRef(false);
  const { isChanged, current } = useWatch(item.status);

  const handleDownload = () => {
    if (item.status === 'success') {
      window.open(item.downloadUrl);
    }
  };

  useEffect(() => {
    if (isChanged && current === 'success' && !downloadedRef.current) {
      downloadedRef.current = true;
      handleDownload();
    }
  }, [isChanged, current]);

  return (
    <MenuItem key={item.id}>
      <ListItemIcon>
        <IconButton onClick={item.onCancel}>
          <CloseIcon />
        </IconButton>
      </ListItemIcon>
      <ListItemText sx={{ pr: 2 }}>{item.filename}</ListItemText>
      <ListItemIcon>
        {item.status === 'success' && (
          <IconButton onClick={() => handleDownload()}>
            <GetAppIcon />
          </IconButton>
        )}
        {item.status === 'failed' && (
          <Tooltip placement="top-start" title={t('unexpected_error')}>
            <ErrorIcon />
          </Tooltip>
        )}
        {item.status === 'canceled' && <CloseIcon />}
        {['new', 'queued', 'pending'].includes(item.status) && (
          <IconButton disabled>
            <CircularProgress size={22} />
          </IconButton>
        )}
      </ListItemIcon>
    </MenuItem>
  );
};

const ScheduledDownloadsProvider = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const { data = [], isLoading, refetch } = useScheduledDownloads();
  const [cancelDownload] = useCancelDownload();
  const hasItemsPending = data?.some((item) =>
    ['queued', 'pending'].includes(item.status),
  );

  const handleCancelDownload = async (id: Item['id']) => {
    await cancelDownload(id);
    await refetch();
  };

  if (data?.length === 0) {
    return null;
  }

  return (
    <Box sx={{ position: 'fixed', right: 0, bottom: 0, p: 2, zIndex: 1200 }}>
      <Grow appear in={data?.length > 0} timeout={500}>
        <Card raised sx={{ minWidth: '300px' }}>
          <CardHeader
            title={t(hasItemsPending ? 'download_preparing' : 'download_ready')}
            action={
              <IconButton onClick={() => setExpanded((prev) => !prev)}>
                <ExpandMore
                  sx={(theme) => ({
                    transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                    transition: theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                  })}
                />
              </IconButton>
            }
          />
          <Collapse in={expanded}>
            <CardContent sx={{ p: 0, maxHeight: '50vh', overflowY: 'auto' }}>
              <MenuList>
                {data?.map((item) => (
                  <DownloadItem
                    {...item}
                    key={item.id}
                    onCancel={() => handleCancelDownload(item.id)}
                  />
                ))}
              </MenuList>
            </CardContent>
          </Collapse>
        </Card>
      </Grow>
    </Box>
  );
};

export default ScheduledDownloadsProvider;
