import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@mui/base/useAutocomplete/useAutocomplete';

type Props = {
  label?: string;
  placeholder?: string;
  value: string[];
  onChange(
    event: any,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails,
  ): void;
  style?: object;
};

const ChipInput = (props: Props) => {
  const { label, placeholder, value, onChange, style } = props;

  return (
    <Autocomplete
      multiple
      freeSolo
      value={value}
      options={[]}
      onChange={onChange}
      style={style}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default ChipInput;
