import { useContext } from 'react';
import ContextBarContext from './ContextBarContext';

const useContextBar = () => {
  const context = useContext(ContextBarContext);

  if (null === context) {
    throw new Error(
      'ContextBarContext not found. Did you forget to wrap your components into ContextBarProvider?',
    );
  }

  return context;
};

export default useContextBar;
