import React from 'react';
import { AdapterProps } from '../types';
import Typography from '../../Typography/Typography';
import Fields from '../Fields';

export type GroupAdapterProps = AdapterProps['group'];

const TextAdapter = (props: GroupAdapterProps) => {
  const { label, items } = props;

  return (
    <>
      <Typography variant="h3">{label}</Typography>
      <Fields fields={items} />
    </>
  );
};

export default TextAdapter;
