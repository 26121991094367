import React from 'react';
import css from './top-bar.module.scss';
import classNames from 'classnames';
import AccountButton from '../AccountButton/AccountButton';
import { Link } from 'react-router-dom';

type Link = {
  label: string;
  href: string;
  active?: boolean;
};

type Props = {
  logoUrl?: string;
  links?: Link[];
  actions?: React.ReactElement[];
  user?: {
    initials: string;
  };
};

const TopBar: React.FC<Props> = (props) => {
  const { logoUrl, links = [], actions = [], user } = props;

  return (
    <div className={css.root}>
      {logoUrl && (
        <a href="/" className={css.logo}>
          <img src={logoUrl} alt="" />
        </a>
      )}
      {links.length > 0 && (
        <ul className={css.navigation}>
          {links.map(({ label, href, active }) => (
            <li key={label} className={css['navigation-item']}>
              <Link
                className={classNames(
                  css['navigation-link'],
                  active && css['is-active'],
                )}
                to={href}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      )}
      {/* Settings / Language Switcher */}
      {actions.length > 0 && (
        <ul className={css.actions}>
          {actions.map((action, index) => (
            <li key={index} className={css.action}>
              {action}
            </li>
          ))}
        </ul>
      )}
      {/* Avatar / user actions */}
      {user && (
        <div className={css['account-button']}>
          <AccountButton name={user.initials} />
        </div>
      )}
    </div>
  );
};

export default TopBar;
