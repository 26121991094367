import http from '../../../utils/http';
import {
  CreateDirPayload,
  Dir,
  MoveDirPayload,
  RenameDirPayload,
} from '../index';
import { Pagination } from '../../@common/crud/types';
import { File } from '../../files';

interface FetchParams {
  preload?: boolean;
  parentId?: number;
  page?: number;
  perPage?: number;
}

export function fetchDirs(params: FetchParams) {
  return http.get<never, Pagination<Dir>>('api/directory', { params });
}

export function createDir({ title, parent, directoryType }: CreateDirPayload) {
  return http.post('api/directory/', {
    title,
    parent,
    directoryType,
  });
}

export function updateDir(data: Partial<Dir>) {
  return http.put(`api/directory/${data.id}`, data);
}

export function patchUpdateDir(data: Partial<Dir> | RenameDirPayload) {
  return http.patch(`api/directory/${data.id}`, data);
}

export function moveDir(data: MoveDirPayload) {
  return http.put(`api/directory/move/${data.id}`, data);
}

export function readDir({ id }: Pick<Dir, 'id'>) {
  return http.get<never, Dir>(`api/directory/${id}`);
}

export function getCover({ id }: Pick<Dir, 'id'>) {
  return http.get<never, File[]>(`api/directory/${id}/cover`);
}

export function deleteDir({ id }: Pick<Dir, 'id'>) {
  return http.delete(`api/directory/${id}`);
}

export async function searchDirs(params: object = {}) {
  const response = await http.get<never, { data: Dir[] }>(
    'api/directory/search',
    { params },
  );

  return response.data;
}
