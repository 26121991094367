import { queryCache, useMutation } from 'react-query';
import http from '../../../utils/http';
import { refetchScheduledDownloadsWithDelay } from '../../export/hooks';
import { Report } from '../types';
import { useState } from 'react';

type Params = {
  type: 'flat' | 'structured';
  params: object;
};

const useExportFiles = (report: Report) => {
  const [loaded, setLoaded] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const [download] = useMutation<any, unknown, Params>(
    ({ type, params = {} }) => {
      setLoading(true);
      setLoaded(0);

      return http
        .get<never, any>(`/api/reports/${report.id}/export-files-schedule`, {
          params: {
            type,
            params: JSON.stringify(params),
          },
        })
        .then(async (data) => {
          refetchScheduledDownloadsWithDelay();

          return data;
        })
        .finally(() => {
          setLoading(false);
        });
    },
  );

  return {
    download,
    loaded,
    isLoading,
  };
};

export default useExportFiles;
