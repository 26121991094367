import React, { useMemo } from 'react';
import { useCurrentDir } from '../../../../modules/ui/hooks';
import { useSelector } from 'react-redux';
import { selectDir } from '../../../../modules/dirs/selectors';
import { RootState } from '../../../../store';
import { TableView } from './TableView';
import { Box, Button, Grid, Typography } from '@mui/material';
import GeneralDirectoryActions from './GeneralDirectoryActions';
import ObjectTypeActions from './ObjectTypeActions';
import UniqueObjectActions from './UniqueObjectActions';
import { Dir } from '../../../../modules/dirs';
import {
  isDir,
  isUniqueObjectDir,
  Resource,
} from '../../../../modules/resources';
import { useTranslation } from 'utils/translation';
import AddIcon from '@mui/icons-material/Add';
import { applyFilters } from '../../../../core';

const GroupTitle: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Typography component="h1" variant="h3">
    {children}
  </Typography>
);

const GeneralDirContents: React.FC<{ currentDir?: Dir } | any> = ({
  currentDir,
  ...props
}) => (
  <>
    {currentDir && (
      <Box mb={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <GroupTitle>Toesteltype</GroupTitle>
          </Grid>
          <Grid item>
            <GeneralDirectoryActions />
          </Grid>
        </Grid>
      </Box>
    )}
    <TableView {...props} />
  </>
);

const isObjectId = (resource: Resource) => {
  return isDir(resource) && isUniqueObjectDir(resource);
};

type NewObjectCallback = (() => void) | null;

const ObjectTypeContents: React.FC<{ currentDir: Dir } | any> = ({
  currentDir,
  resources,
  ...props
}) => {
  const { t } = useTranslation();
  const objectIdResources = useMemo(() => {
    return resources.filter(isObjectId);
  }, [resources]);

  const nonObjectIdResources = useMemo(() => {
    return resources.filter((resource: Resource) => !isObjectId(resource));
  }, [resources]);

  const newUniqueObjectCallback = applyFilters<NewObjectCallback>(
    'contextual_view_new_unique_object_callback',
    null,
  );

  return (
    <>
      {nonObjectIdResources.length > 0 && (
        <>
          <Box mb={2}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <GroupTitle>{currentDir.title}</GroupTitle>
              </Grid>
              <Grid item>
                <ObjectTypeActions />
              </Grid>
            </Grid>
          </Box>
          <TableView {...props} resources={nonObjectIdResources} />
        </>
      )}
      {objectIdResources.length > 0 && (
        <>
          <Box mb={2}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <GroupTitle>{t('Unique Objects')}</GroupTitle>
              </Grid>
              <Grid item>
                {newUniqueObjectCallback && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={newUniqueObjectCallback}
                  >
                    <AddIcon />
                    {t('Add')}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
          <TableView {...props} resources={objectIdResources} />
        </>
      )}
    </>
  );
};

const UniqueObjectContents: React.FC<{ currentDir: Dir } | any> = ({
  currentDir,
  resources,
  ...props
}) => {
  return (
    <>
      {resources.length > 0 && (
        <>
          <Box mb={2}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <GroupTitle>{currentDir.title}</GroupTitle>
              </Grid>
              <Grid item>
                <UniqueObjectActions />
              </Grid>
            </Grid>
          </Box>
          <TableView {...props} resources={resources} />
        </>
      )}
    </>
  );
};

const views = {
  'object-type': ObjectTypeContents,
  'unique-object': UniqueObjectContents,
};

const ContextualView = (props: any) => {
  const { i18n } = useTranslation();
  const { currentDirId } = useCurrentDir();
  const currentDir = useSelector((state: RootState) => {
    if (!currentDirId) {
      return null;
    }

    return selectDir(state, currentDirId);
  });

  const CurrentView = currentDir
    ? views[currentDir.directoryType as keyof typeof views]
    : null;
  const ActualView = CurrentView || GeneralDirContents;

  return (
    <div>
      <ActualView key={i18n.language} currentDir={currentDir} {...props} />
    </div>
  );
};

export default ContextualView;
