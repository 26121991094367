import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import SortableList from './SortableList';
import { ButtonGroup } from '@mui/material';

function not<T extends Item>(a: readonly T[], b: readonly T[]) {
  return a.filter((item) => !b.some((second) => second.id === item.id));
}

function intersection<T extends Item>(a: readonly T[], b: readonly T[]) {
  return a.filter((item) => b.some((second) => second.id === item.id));
}

export type Item = {
  id: string | number;
  label: string;
};

type Props<T extends Item> = {
  available: T[];
  selected?: T[];
  onSubmit?(selected: readonly T[]): void;
};

export default function TransferList<T extends Item>({
  available,
  selected = [],
  onSubmit,
}: Props<T>) {
  const [checked, setChecked] = React.useState<readonly T[]>([]);
  const [left, setLeft] = React.useState<readonly T[]>(
    not(available, selected),
  );
  const [right, setRight] = React.useState<readonly T[]>(selected);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const createToggle = (item: T) => () => {
    const currentIndex = checked.findIndex((i) => i.id === item.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleSubmit = () => {
    onSubmit && onSubmit(right);
  };

  const customList = (
    items: readonly T[],
    onChange?: any,
    disabled: boolean = false,
  ) => {
    return (
      <Paper sx={{ maxHeight: '80vh', overflow: 'auto' }}>
        <List dense component="div" role="list">
          <SortableList
            disabled={disabled}
            items={items as T[]}
            onChange={onChange}
            itemProps={(item) => ({
              onCheckboxChange: createToggle(item),
              checked: Boolean(checked.find((i) => item.id === i.id)),
            })}
          />
        </List>
      </Paper>
    );
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid xs={5} item>
        {left.length > 0 && customList(left, undefined, true)}
      </Grid>
      <Grid item xs={2} sx={{ minWidth: '100px' }}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {right.length > 0 && customList(right, setRight)}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'space-around' }}
      >
        <ButtonGroup>
          <Button onClick={handleSubmit} variant="outlined">
            Save
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
