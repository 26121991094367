import React from 'react';
import FluentForm from '../../../../components/v2/Form/FluentForm';
import Form from '../../../../components/v2/Form/Form';
import { FieldType } from '../../../../components/v2/Form/types';
import Section from '../../../../components/v2/Section/Section';
import Typography from '../../../../components/v2/Typography/Typography';
import { useCurrentUser } from '../../../../modules/auth/hooks';
import { ServiceRequestValues } from '../type';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'utils/translation';
import Spacer from 'components/v2/Spacer/Spacer';
import { empty } from 'utils/array';

type Props = {
  action(values: ServiceRequestValues): Promise<void>;
  issueTypes?: string[];
  enableDescription?: boolean;
  enableAttachments?: boolean;
  labels?: {
    description?: string;
  };
};

const ServiceRequestForm = ({
  action,
  issueTypes = [],
  enableDescription = false,
  enableAttachments = false,
  labels: { description = '' } = {},
}: Props) => {
  const { t } = useTranslation();
  const user = useCurrentUser();

  const defaultValues = {
    contactFirstName: user?.firstName,
    contactLastName: user?.lastName,
    contactEmail: user?.email,
  };

  return (
    <Section>
      <FluentForm action={action} defaultValues={defaultValues}>
        <Typography variant="h3">
          {t('service_request_contact_person')}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Form.Field
            label={t('Firstname')}
            name="contactFirstName"
            type={FieldType.Text}
            rules={{ required: true }}
          />
          <Form.Field
            label={t('Lastname')}
            name="contactLastName"
            type={FieldType.Text}
            rules={{ required: true }}
          />
          <Form.Field
            label={t('Email')}
            name="contactEmail"
            type={FieldType.Email}
            rules={{ required: true }}
          />
        </Stack>
        {!empty(issueTypes) && (
          <Form.Field
            type={FieldType.Select}
            label={t('service_request_common_issues')}
            name="issueType"
            options={issueTypes.map((item) => ({ value: item, label: item }))}
          />
        )}
        {enableDescription && (
          <Form.Field
            label={description || t('service_request_problem_description')}
            name="issueDescription"
            type={FieldType.Text}
            rules={{ required: true }}
            multiline
            minRows={6}
          />
        )}
        {enableAttachments && (
          <Form.Field
            type={FieldType.File}
            uploadLabel={t('service_request_problem_attachments')}
            name="attachments"
            multiple
          />
        )}
        <Spacer size={2} />
      </FluentForm>
    </Section>
  );
};

export default ServiceRequestForm;
