import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Input, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'utils/translation';
import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon,
  },
  searchInput: {
    flexGrow: 1,
  },
  searchButton: {
    marginLeft: theme.spacing(2),
  },
}));

const Search = (props: any) => {
  const {
    initialValue,
    onSearch,
    sx,
    debounceTime = 300,
    placeholder,
    ...rest
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const searchRef = React.useRef<HTMLInputElement | null>(null);

  const handleSearch = debounce((phrase) => {
    onSearch(phrase);
  }, debounceTime);

  const handleSearchChange = () => {
    if (searchRef.current) {
      handleSearch(searchRef.current?.value);
    }
  };

  return (
    <Box {...rest} sx={sx} className={classes.root}>
      <Paper className={classes.search} elevation={1}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          disableUnderline
          placeholder={placeholder || t('Search')}
          onChange={handleSearchChange}
          inputRef={searchRef}
          defaultValue={initialValue}
        />
      </Paper>
    </Box>
  );
};

export default Search;
