import React from 'react';
import { TextField } from '@mui/material';
import { useIMask } from 'react-imask';

export type MaskedProps<Value = string> = {
  mask: string;
  name?: string;
  label?: string;
  value?: string;
  error?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  fullWidth?: boolean;
  onChange?: (event: any, value: Value) => void;
  onComplete?: (value: Value, maskRef: any, event?: any) => void;
};

const MaskedField: React.FC<MaskedProps> = (props) => {
  const {
    mask,
    name,
    label,
    variant,
    value,
    onChange,
    onComplete,
    error,
    fullWidth,
  } = props;

  const { ref } = useIMask(
    {
      mask,
      lazy: false,
      placeholderChar: 'X',
    },
    {
      onAccept: (value: string, ref: any, event: any) => {
        onChange && onChange(event, value);
      },
      onComplete,
    },
  );

  return (
    // @ts-ignore
    <TextField
      variant={variant || 'outlined'}
      name={name}
      label={label}
      inputRef={ref}
      InputLabelProps={{ shrink: Boolean(value) }}
      defaultValue={value}
      error={!!error}
      fullWidth={fullWidth}
    />
  );
};

export default MaskedField;
