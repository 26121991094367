import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FileResource } from 'modules/resources';
import { MoveForm } from 'modules/dirs/components/MoveForm';
import { useFilesActions } from 'modules/files/hooks';
import { ModalClose } from 'components';
import { useTranslation } from 'utils/translation';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      height: '70vh',
    },
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
  file: FileResource;
}

export const MoveFileFormModal = ({ file, open, onClose }: Props) => {
  const classes = useStyles();
  const fullScreen = useShouldModalBeFullscreen();
  const { t } = useTranslation();
  const { moveFile } = useFilesActions();

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = (dirId: number) => {
    moveFile({
      file,
      dirId,
    });
    onClose();
  };

  return (
    <Dialog
      aria-labelledby="move-file-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <DialogContent className={classes.content}>
        <ModalClose onClose={onClose} />
        <MoveForm
          title={t('Move file')}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};
