import {
  createResourceAction,
  createResourceActions,
  createResourceType
} from '../@common/actions/resource-actions';
import { DIRS_PREFIX } from './constants';

export const dirsActions = {
  ...createResourceActions(DIRS_PREFIX),
  load: createResourceAction(`${DIRS_PREFIX}/LOAD`),
  search: createResourceAction(`${DIRS_PREFIX}/SEARCH`),
  move: createResourceAction(`${DIRS_PREFIX}/MOVE`),
  download: createResourceAction(`${DIRS_PREFIX}/DOWNLOAD`),
  preload: createResourceAction(`${DIRS_PREFIX}/PRELOAD`),
  reset: createResourceAction(`${DIRS_PREFIX}/RESET`),
  MOVE: createResourceType(`${DIRS_PREFIX}/MOVE`)
};
