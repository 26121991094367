import React from 'react';
import { useFilesActions, useUploadingFileListIds } from '../hooks';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { colors, Divider, List, Typography } from '@mui/material';
import { FileUploadItem } from './FileUploadItem';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme';
import { useTranslation } from 'utils/translation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    queueContainer: {
      position: 'absolute',
      width: 400,
      left: theme.spacing(2),
      bottom: theme.spacing(3),
      zIndex: 25000,
    },
    header: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    list: {
      maxHeight: '40vh',
    },
    cancelButton: {
      backgroundColor: colors.red[600],
      // @ts-ignore
      color: theme.palette.white,
      '&:hover': {
        backgroundColor: colors.red[900],
      },
    },
  }),
);

const BaseList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const fileIds = useUploadingFileListIds();
  const { cancelUploadAll } = useFilesActions();

  if (!fileIds.length) {
    return null;
  }

  const chunk = fileIds.slice(0, 5);

  return (
    <div className={classes.queueContainer}>
      <Card>
        <Box className={classes.header} py={2} px={2}>
          <Typography variant="h5">
            {t(fileIds.length === 1 ? 'uploading_one' : 'uploading_other', {
              count: fileIds.length,
            })}
          </Typography>
          <Button
            className={classes.cancelButton}
            onClick={() => cancelUploadAll()}
            size="small"
          >
            {t('Cancel')}
          </Button>
        </Box>
        <Divider />
        {/* @ts-ignore */}
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <List className={classes.list}>
            {chunk.map((fileId: string) => (
              <FileUploadItem fileId={fileId} key={fileId} />
            ))}
          </List>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

const UploadingList = React.memo(BaseList);

export { UploadingList };
