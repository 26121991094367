import localeConfig from '../config/locale';

/**
 * @deprecated Since react-router no longer supports wildcard prefixes,
 * this function is no longer needed.
 * Please add the route to the routes definition inside src/router/routes.ts
 * and use new `route(name: string, params: object)` function.
 *
 * @param path
 */
const createRoute = (path: string) => {
  const locales = localeConfig.locales.join('|');

  return `${path}`;
};

export default createRoute;
