import validate from 'validate.js';

type RegexOptions = { message: string; pattern: string | RegExp };

const regex = (value: string | any, options: RegexOptions) => {
  const regExp = new RegExp(options.pattern);

  if (!regExp.test(value)) {
    return options.message;
  }
};

const checked = (value: any, options: { message?: string }) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

const isEmail = (value: any, options: RegexOptions) => {
  const pattern =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return regex(value, {
    ...options,
    pattern,
  });
};

validate.validators = {
  ...validate.validators,
  regex,
  checked,
  isEmail,
};
