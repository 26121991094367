import { Action } from '../../@common/actions/action';
import { handleActions } from 'redux-actions';
import { DirActionTypes, PaginationActionTypes } from '../actions';
import { UIError } from '../types';
import { dirsActions } from '../../dirs/actions';
import produce from 'immer';
import get from 'lodash/get';

interface UIState {
  selectedDirId: Nullable<number>;
  perPage: number;
  error: Nullable<UIError>;
}

const storageKey = '_quodoc-my-disk-table-per-page';
const defaultPerPage = localStorage.getItem(storageKey) || '';

const initialState: UIState = {
  selectedDirId: null,
  perPage:
    Number.isNaN(+defaultPerPage) || +defaultPerPage <= 0
      ? 50
      : +defaultPerPage,
  error: null
};

export const diskReducer = handleActions(
  {
    [dirsActions.read.FAILURE]: (state, action) => {
      const code = get(
        action,
        'meta.response.status',
        UIError.ErrorIntervalServerError
      );
      return produce(state, draft => {
        draft.error = code;
      });
    },
    [PaginationActionTypes.SET_PAGINATION_PER_PAGE]: (
      state,
      action: Action
    ) => {
      const { perPage } = action.payload;
      localStorage.setItem(storageKey, perPage);

      return {
        ...state,
        perPage: perPage
      };
    },
    [DirActionTypes.SET_CURRENT]: (state, action: Action) => {
      const { id } = action.payload;

      return {
        ...state,
        selectedDirId: id
      };
    },
    [DirActionTypes.RESET_CURRENT_DIR]: state => {
      return {
        ...state,
        selectedDirId: initialState.selectedDirId,
        error: null
      };
    }
  },
  initialState
);
