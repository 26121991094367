import en from './messages.en.json';
import pl from './messages.pl.json';
import nl from './messages.nl.json';
import fr from './messages.fr.json';

// we have to keep default "global" namespace to prevent BC
export default {
  en: { global: en },
  pl: { global: pl },
  nl: { global: nl },
  fr: { global: fr },
};
