import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  SWITCH_USER_KEY
} from '../constants';

type Credentials = {
  accessToken: string;
  refreshToken: string;
};

export function getCredentials() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  return {
    accessToken,
    refreshToken
  };
}

export function storeCredentials({ accessToken, refreshToken }: Credentials) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function removeCredentials() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

export function resetSwitchedUser() {
  return localStorage.removeItem(SWITCH_USER_KEY);
}

export function persistSwitchUser(username: string) {
  return localStorage.setItem(SWITCH_USER_KEY, username);
}

export function getSwitchedUser() {
  return localStorage.getItem(SWITCH_USER_KEY);
}
