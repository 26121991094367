import React from 'react';
import Form from './Form';
import { FormProps } from './types';
import { MutationFunction, useMutation } from '@tanstack/react-query';
import { useServerErrors } from './utils';

type Props<Values extends object, Result = unknown, Error = unknown> = {
  action?: MutationFunction<Result, Values>;
  onSuccess?(data: Result): void;
  onChange?(values: Values): void;
  onError?(error: Error, payload: Values): void;
  buttonAlign?: 'left' | 'center' | 'right';
};

function FluentForm<Values extends object, Result = unknown, Error = unknown>({
  action = () => Promise.resolve(true as any),
  onSuccess,
  onError,
  errors: defaultErrors,
  ...rest
}: React.PropsWithChildren<
  Omit<FormProps<Values>, 'onSubmit'> & Props<Values, Result>
>) {
  const mutation = useMutation<Result, Error, Values>({
    mutationFn: action,
    onSuccess,
    onError,
    throwOnError: false,
  });
  const errors = useServerErrors(mutation.error);

  const onSubmit = async (values: Values) => {
    await mutation.mutateAsync(values);
  };

  return (
    <Form
      {...rest}
      errors={{
        ...defaultErrors,
        ...errors,
      }}
      onSubmit={onSubmit}
    />
  );
}

export default FluentForm;
