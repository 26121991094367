import authSagas from 'modules/auth/sagas';
import usersSagas from 'modules/users/sagas';
import groupsSagas from 'modules/user-groups/sagas';
import dirsSagas from 'modules/dirs/sagas';
import dirAccessSagas from 'modules/dirs-access/sagas';
import fileSagas from 'modules/files/sagas';
import basketSagas from 'modules/basket/sagas';
import intelligentLayerSagas from 'modules/intelligent-layer/sagas';
import { all, fork } from 'redux-saga/effects';

export function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(usersSagas),
    fork(groupsSagas),
    fork(dirsSagas),
    fork(dirAccessSagas),
    fork(fileSagas),
    fork(basketSagas),
    fork(intelligentLayerSagas)
  ]);
}
