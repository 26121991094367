import React, { useEffect } from 'react';
import { Badge, Box } from '@mui/material';
import { ReactComponent as AssigmentReturned } from '../../../../../../assets/icons/assigment-returned.svg';
import { useTranslation } from 'utils/translation';
import {
  useBasketActions,
  useBasketItems,
} from '../../../../../../modules/basket/hooks';
import { useUiModal } from '../../../../../../modules/ui/hooks';
import { BASKET_VIEW_MODAL_ID } from '../../../../../../modules/ui';
import Nav from '../../Nav';

export const BasketNavItem = () => {
  const { t } = useTranslation();
  const { fetchBasket } = useBasketActions();
  const { files, directories } = useBasketItems();
  const { show } = useUiModal();

  useEffect(() => {
    fetchBasket();
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    show(BASKET_VIEW_MODAL_ID);
  };

  const itemsCount = files.length + directories.length;

  return (
    <Nav.Item
      title={
        <Badge
          badgeContent={itemsCount}
          max={99}
          color="primary"
          component="div"
        >
          <Box component="span" sx={{ pr: 2 }}>
            {t('Basket')}
          </Box>
        </Badge>
      }
      icon={<AssigmentReturned />}
      onClick={handleClick}
    />
  );
};
