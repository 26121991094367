import { Group } from '../../user-groups';
import { handleActions } from 'redux-actions';
import { dirsGroupAccessActions } from '../actions';
import produce from 'immer';
import { DirGroupAccessPayload } from '../index';
import { Action } from '../../@common/actions/action';

interface GroupsAccessState {
  [key: number]: {
    groups: Group[];
    isFetching: boolean;
  };
}

const initialState: GroupsAccessState = {};

export const groups = handleActions(
  {
    [dirsGroupAccessActions.fetch.TRIGGER]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId } = action.payload;
        if (!draft[dirId]) {
          draft[dirId] = {
            isFetching: false,
            groups: []
          };
        }
      });
    },
    [dirsGroupAccessActions.fetch.REQUEST]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId } = action.payload;
        if (draft[dirId]) {
          draft[dirId].isFetching = true;
        }
      });
    },
    [dirsGroupAccessActions.fetch.SUCCESS]: (
      state,
      action: Action<Group[], DirGroupAccessPayload>
    ) => {
      return produce(state, draft => {
        const { dirId } = action.meta;
        draft[dirId].groups = action.payload;
      });
    },
    [dirsGroupAccessActions.fetch.FULFILL]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId } = action.payload;
        if (draft[dirId]) {
          draft[dirId].isFetching = false;
        }
      });
    },
    [dirsGroupAccessActions.create.REQUEST]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId, group } = action.payload;
        if (draft[dirId]) {
          draft[dirId].groups.push(group);
        }
      });
    },
    [dirsGroupAccessActions.delete.REQUEST]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId, groupId } = action.payload;
        if (draft[dirId]) {
          draft[dirId].groups = draft[dirId].groups.filter(
            item => item.id !== groupId
          );
        }
      });
    }
  },
  initialState
);
