import React, { Fragment, useState, useCallback, ReactElement } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from 'theme';
import { useTranslation } from 'utils/translation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  actions: {
    justifyContent: 'space-around',
    padding: theme.spacing(2, 0),
  },
  button: {
    minWidth: 120,
  },
}));

const defaultOptions = {
  title: 'Are you sure?',
  description: '',
  confirmationText: 'Yes',
  cancellationText: 'No',
  dialogProps: {},
  onClose: () => {},
  onCancel: () => {},
  extraContent: null as unknown as ReactElement,
};

export interface WithConfirmProps {
  confirm<T extends Function>(
    onConfirm: T,
    options?: Partial<typeof defaultOptions>,
  ): T;
}

const withConfirm =
  <P extends WithConfirmProps = WithConfirmProps>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<Optionalize<P, WithConfirmProps>> =>
  (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [onConfirm, setOnConfirm] = useState<Nullable<Function>>(null);
    const [options, setOptions] = useState(defaultOptions);
    const {
      title,
      description,
      confirmationText,
      cancellationText,
      dialogProps,
      extraContent,
      onClose,
      onCancel,
    } = options;

    const handleClose = useCallback(() => {
      onClose();
      setOnConfirm(null);
    }, [onClose]);

    const handleCancel = useCallback(() => {
      onCancel();
      handleClose();
    }, [onCancel, handleClose]);

    const handleConfirm = useCallback(
      (...args: any[]) => {
        if (onConfirm) {
          onConfirm(...args);
          handleClose();
        }
      },
      [onConfirm, handleClose],
    );

    const confirm = useCallback(
      (onConfirm: Function, options = {}) =>
        () => {
          setOnConfirm(() => onConfirm);
          setOptions({ ...defaultOptions, ...options });
        },
      [],
    );

    return (
      <Fragment>
        <WrappedComponent {...(props as P)} confirm={confirm} />
        <Dialog
          fullWidth
          {...dialogProps}
          open={!!onConfirm}
          onClose={handleCancel}
        >
          {title && (
            <DialogTitle>
              <Typography variant="h3" align="center">
                {t(title)}
              </Typography>
            </DialogTitle>
          )}
          {description && (
            <DialogContent>
              <DialogContentText align="center">
                {description}
              </DialogContentText>
              {extraContent}
            </DialogContent>
          )}
          <DialogActions className={classes.actions}>
            <Button
              className={classes.button}
              onClick={handleCancel}
              size="large"
              variant="contained"
            >
              {t(cancellationText)}
            </Button>
            <Button
              className={classes.button}
              onClick={handleConfirm}
              size="large"
              color="primary"
              variant="contained"
            >
              {t(confirmationText)}
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  };

export default withConfirm;
