import { createResourceActions } from '../@common/actions/resource-actions';
import {
  DIRS_USER_ACCESS_PREFIX,
  DIRS_GROUP_ACCESS_PREFIX,
  DIRS_USER_BLOCKED_PREFIX
} from './constants';

export const dirsUserAccessActions = createResourceActions(
  DIRS_USER_ACCESS_PREFIX
);

export const dirsGroupAccessActions = createResourceActions(
  DIRS_GROUP_ACCESS_PREFIX
);

export const dirsUserBlockedActions = createResourceActions(
  DIRS_USER_BLOCKED_PREFIX
);
