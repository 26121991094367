import React, { Fragment, Suspense } from 'react';
import { renderRoutes, RouteConfig } from 'utils/render-routes';
import { LinearProgress, Box } from '@mui/material';

const Auth = (props: { route: { routes: RouteConfig[] } }) => {
  const { route } = props;

  return (
    <Fragment>
      <Box sx={{ height: '100%' }}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </Box>
    </Fragment>
  );
};

export default Auth;
