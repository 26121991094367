import React from 'react';
import {Dropdown} from '@mui/base';
import {Menu, MenuItem, MenuButton, MenuButtonSize} from '../Menu/Menu';

export type Action = {
  label: string | React.ReactElement;
  disabled?: boolean;
  divider?: boolean;
  action?(): void;
  icon?: React.ReactElement | null;
};

type Props = {
  actions: Action[];
  button?: React.ReactElement;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  size?: MenuButtonSize;
  variant?: string
};

const OptionsButton: React.FC<Props & React.ButtonHTMLAttributes<any>> = (props) => {
  const {
    actions,
    startIcon,
    endIcon,
    size,
    children
  } = props;

  return (
    <Dropdown>
      <MenuButton size={size} startIcon={startIcon} endIcon={endIcon}>{children}</MenuButton>
      <Menu>
        {actions.map(
          ({label, divider, disabled, action, icon}, index) => (
            <MenuItem
              key={index}
              divider={divider}
              disabled={disabled}
              onClick={action}
              icon={icon}
            >
              {label}
            </MenuItem>
          ),
        )}
      </Menu>
    </Dropdown>
  );
};

export default OptionsButton;
