import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'utils/router';

const NavListItem: React.FC<React.PropsWithChildren<any>> = ({
  children,
  title,
  icon,
  href = '',
  onClick: defaultOnClick,
  external = false,
  open: defaultOpen = false,
  disableOpenIcon = false,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<boolean>(defaultOpen);
  const isToggleable = Boolean(children);

  const onToggle = () => {
    setOpen((prev) => !prev);
  };

  const onClick = () => {
    if (defaultOnClick) {
      return defaultOnClick();
    }

    if (href) {
      if (external) {
        window.open(href);
      } else {
        navigate(href);
      }
    }
  };

  const onTitleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (href && isToggleable) {
      navigate(href);
    }
  };

  return (
    <>
      <ListItemButton
        sx={{
          pl: 3,
        }}
        onClick={isToggleable ? onToggle : onClick}
      >
        {icon && (
          <ListItemIcon sx={{ minWidth: '40px', color: 'text.secondary' }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          onClick={onTitleClick}
          primary={title}
          sx={{ color: 'text.secondary' }}
          primaryTypographyProps={{
            sx: { fontWeight: 500 },
          }}
        />
        {!disableOpenIcon &&
          isToggleable &&
          (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {children && (
        <Collapse in={open} timeout="auto">
          {children}
        </Collapse>
      )}
    </>
  );
};

const Nav: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <List sx={{ width: '100%', bgColor: 'background.paper' }} component="nav">
      {children}
    </List>
  );
};

export default Object.assign(Nav, {
  Item: NavListItem,
});
