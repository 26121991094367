import React from 'react';

export type ModalContentContextType = {
  scrollToTop(): void;
};

const ModalContentContext = React.createContext<ModalContentContextType | null>(
  {
    scrollToTop() {
      console.warn('ModalContentContext provider not found.');
    },
  },
);

export default ModalContentContext;
