import { createApiHandler } from '../@common/sagas/create-api-handler';
import {
  createGroup,
  deleteGroup,
  fetchGroups,
  updateGroup
} from './service/api';
import { transform } from '../../utils/transform-entities';
import { Group } from '.';
import { takeLatest } from '@redux-saga/core/effects';
import { groupsActions } from './actions';

const handleFetchGroups = createApiHandler({
  routine: groupsActions.fetch,
  provider: fetchGroups,
  responseMiddleware: groups => ({
    items: transform(groups),
    order: groups.map((group: Group) => group.id)
  })
});

const handleGroupCreate = createApiHandler({
  routine: groupsActions.create,
  provider: createGroup
});

const handleGroupUpdate = createApiHandler({
  routine: groupsActions.update,
  provider: updateGroup
});

const handleGroupDelete = createApiHandler({
  routine: groupsActions.delete,
  provider: deleteGroup
});

export default function*() {
  yield takeLatest(groupsActions.fetch.TRIGGER, handleFetchGroups);
  yield takeLatest(groupsActions.create.TRIGGER, handleGroupCreate);
  yield takeLatest(groupsActions.update.TRIGGER, handleGroupUpdate);
  yield takeLatest(groupsActions.delete.TRIGGER, handleGroupDelete);
}
