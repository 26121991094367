import clsx from 'clsx';
import * as React from 'react';
import css from './badge.module.scss';
import { Badge as BaseBadge } from '@mui/base/Badge';

function Badge({
  children,
  badgeContent,
  expand,
}: React.ComponentProps<typeof BaseBadge>) {
  return (
    <BaseBadge
      slotProps={{
        root: { className: clsx(css.root, expand && css['is-expanded']) },
        badge: { className: css.badge },
      }}
      badgeContent={badgeContent}
    >
      {children}
    </BaseBadge>
  );
}

export default Badge;
