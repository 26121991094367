import React, { useEffect, useState } from 'react';
import { validate } from 'validate.js';
import { ChipInput } from '../index';
import { Box } from '@mui/material';

const isValidEmail = (email: string) => {
  const errors = validate(
    { email },
    {
      email: {
        presence: true,
        isEmail: {
          message: 'Must be a valid e-mail',
        },
      },
    },
  );

  return Object.keys(errors || {}).length === 0;
};

type Email = string;

interface PickerProps {
  placeholder?: string;
  initialEmails?: Email[];
  onChange?(emails: Email[]): void;
}

const EmailsChipsPicker: React.FC<PickerProps> = ({
  initialEmails = [],
  onChange: onValueChange,
  placeholder,
}) => {
  const [inputValue, setInputVal] = useState('');
  const [error, setError] = useState(false);
  const [emails, setEmails] = useState<string[]>(initialEmails);

  useEffect(() => {
    if (onValueChange) {
      onValueChange(emails);
    }
  }, [emails, onValueChange]);

  const onChange = (event: any, emailAddress: string[]) => {
    const nextEmails = Array.from(emailAddress)
      .map((item) => (item || '').trim())
      .filter((item) => {
        const isValid = isValidEmail(item);

        if (!isValid) {
          setInputVal(item);
          setError(true);
        }

        return isValid;
      });

    setEmails(nextEmails);
  };

  return (
    <Box>
      <ChipInput value={emails} onChange={onChange} placeholder={placeholder} />
    </Box>
  );
};

export default EmailsChipsPicker;
