import React, { useCallback } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { FormValues, DirectoryForm } from './DirectoryForm';
import { dirsActions } from '../../../../../modules/dirs/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentDir } from '../../../../../modules/ui/hooks';
import { ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';
import { RootState } from '../../../../../store';
import { selectDir } from '../../../../../modules/dirs/selectors';
import { isObjectTypeDir } from '../../../../../modules/resources';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AddDirectoryModal = ({ open, onClose }: Props) => {
  const dispatch = useDispatch();
  const fullScreen = useShouldModalBeFullscreen();
  const { currentDirId } = useCurrentDir();
  const currentDir = useSelector((state: RootState) => {
    if (!currentDirId) {
      return null;
    }

    return selectDir(state, currentDirId);
  });

  const onSubmit = useCallback(
    (values: FormValues) => {
      dispatch(
        dirsActions.create.trigger({
          ...values,
          parent: currentDirId,
        }),
      );
    },
    [dispatch, currentDirId],
  );

  const defaultDirectoryType =
    currentDir && isObjectTypeDir(currentDir) ? 'inherited-type' : undefined;

  if (currentDirId && !currentDir) {
    return null;
  }

  return (
    <Dialog
      aria-labelledby="user-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <DialogContent>
        <ModalClose onClose={onClose} />
        <DirectoryForm
          onCancel={onClose}
          onSubmit={onSubmit}
          initialValues={{
            directoryType: defaultDirectoryType,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
