import React, { Component, Children, cloneElement } from 'react';
import styles from './TreeNodeRenderer.module.scss';
import { shallowEqual } from 'react-redux';

class TreeNodeRenderer extends Component<any, any> {
  static defaultProps = {
    swapFrom: null,
    swapDepth: null,
    swapLength: null,
    canDrop: false,
    draggedNode: null
  };

  shouldComponentUpdate(
    nextProps: Readonly<any>,
    nextState: Readonly<any>,
    nextContext: any
  ): boolean {
    return !shallowEqual(nextProps, this.props);
  }

  render() {
    const {
      children,
      listIndex,
      swapFrom,
      swapLength,
      swapDepth,
      scaffoldBlockPxWidth,
      lowerSiblingCounts,
      connectDropTarget,
      isOver,
      draggedNode,
      canDrop,
      treeIndex,
      treeId, // Delete from otherProps
      getPrevRow, // Delete from otherProps
      node, // Delete from otherProps
      path, // Delete from otherProps
      rowDirection,

      rowHeight,

      ...otherProps
    } = this.props;

    return connectDropTarget(
      <div {...otherProps} className={styles.node}>
        {Children.map(children, child =>
          cloneElement(child as any, {
            isOver,
            canDrop,
            draggedNode,
            lowerSiblingCounts,
            listIndex,
            swapFrom,
            swapLength,
            swapDepth
          })
        )}
      </div>
    );
  }
}

export default TreeNodeRenderer;
