import React from 'react';
import { AdapterProps } from '../types';
import DatePicker, { Value } from '../../DatePicker/DatePicker';
import InputLabel from '../../InputLabel/InputLabel';
import css from '../filters.module.scss';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import dayjs, { Dayjs } from 'dayjs';
import { toUTCTimestamp, safeParse } from '../../../../utils/date';

const DateAdapter = (props: AdapterProps<Value | null>) => {
  const { label, onChange, onRemove, value, ...rest } = props;

  const currentValue = React.useMemo(() => {
    return safeParse(value);
  }, [value]);

  const handleDateChange = (date: Dayjs | any) => {
    if (!onChange) {
      return;
    }

    onChange(toUTCTimestamp(date));
  };

  return (
    <div className={css.filter}>
      <InputLabel className={css.label}>{label}</InputLabel>
      <DatePicker
        format={dayjs.Ls[dayjs.locale()].formats.L}
        modifier={'filter'}
        value={currentValue}
        onChange={handleDateChange}
      />
      {onRemove && (
        <Button
          tabIndex={-1}
          onClick={onRemove}
          variant="text"
          startIcon={<Icon name="fa fa-times" />}
          className={css['remove-button']}
        />
      )}
    </div>
  );
};

export default DateAdapter;
