import React, { ChangeEvent } from 'react';
import { Input } from '@mui/base/Input';
import clsx from 'clsx';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import css from './search-field.module.scss';

type Size = 'default' | 'large';

type Props = {
  size?: Size;
  expand?: boolean;
  placeholder?: string;
  onChange?(query: string): void;
  value: string;
  isActive?: boolean;
};

const SearchField: React.FC<Props> = (props) => {
  const {
    size,
    expand,
    placeholder = 'Search',
    onChange,
    value,
    isActive = false,
    ...rest
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();
    const text = event.clipboardData?.getData('Text');

    if (inputRef.current) {
      inputRef.current.value = text.trim();
      onChange?.(inputRef.current.value);
    }
  };

  return (
    <div
      className={clsx(
        css.root,
        size && css[`size-${size}`],
        expand && css.expanded,
        isActive && css['is-active'],
      )}
      {...rest}
    >
      <div className={css.icon} onClick={() => onChange?.(value)}>
        <Icon name={'far fa-magnifying-glass'} />
      </div>
      <Input
        slotProps={{
          input: {
            value: value || '',
            ref: inputRef,
          },
        }}
        className={css.input}
        placeholder={placeholder}
        onChange={handleChange}
        onPaste={handlePaste}
      />
      {value && (
        <Button
          onClick={() => onChange?.('')}
          className={css['close-button']}
          startIcon={<Icon name={'far fa-times'} />}
          variant={'text'}
        />
      )}
    </div>
  );
};

export default SearchField;
