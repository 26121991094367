import React, { useRef } from 'react';
import Section from '../Section/Section';
import Typography from '../Typography/Typography';
import { useTranslation } from 'utils/translation';
import TextInput from '../Input/TextInput';
import Button, { ButtonGroup } from '../Button/Button';
import useInputCopyToClipboard from 'utils/use-input-copy-to-clipboard';
import Spacer from '../Spacer/Spacer';

type Props = {
  url: string;
};

const Share = ({ url }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const [onCopy, copied] = useInputCopyToClipboard(inputRef);

  return (
    <Section>
      <Typography variant="h2">{t('Shared link')}</Typography>
      <Spacer size={2} />
      <TextInput ref={inputRef} value={url} readOnly />
      <Spacer />
      {copied && <Typography>{t('Copied to clipboard!')}</Typography>}
      <Spacer size={2} />
      <ButtonGroup align="right">
        <Button onClick={onCopy}>{t('Copy to clipboard')}</Button>
      </ButtonGroup>
    </Section>
  );
};

export default Share;
