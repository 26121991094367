import { combineReducers } from 'redux';
import { groups } from './groups';
import { users } from './users';
import { blockedUsers } from './blockedUsers';

export const dirAccessReducer = combineReducers({
  groups,
  users,
  blockedUsers
});
