import React from 'react';
import { useFilePreview } from '../modules/files/providers/FilePreviewProvider';
import { RenderRegistry } from './RenderRegistry';
import DirectoryActions from '../modules/dirs/components/DirectoryActions';
import FileActions from '../modules/files/components/FileActions';
import ResourceIcon from '../components/v2/ResourceIcon/ResourceIcon';
import {
  transformDirectoryToResource,
  transformFileToResource,
} from '../modules/resources';

export const registry: RenderRegistry = {
  'directory.actions': DirectoryActions,
  'directory.icon': ({ dir }) => (
    <ResourceIcon item={transformDirectoryToResource(dir)} />
  ),
  'file.actions': FileActions,
  'file.icon': ({ file }) => {
    const { launchPreview } = useFilePreview();

    return (
      <ResourceIcon
        style={{ cursor: 'pointer' }}
        onClick={() => launchPreview(file)}
        item={transformFileToResource(file)}
      />
    );
  },
};
