import { TreeViewBaseItem } from '@mui/x-tree-view';
import React, { useRef } from 'react';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import TreeItem from './TreeItem';
import css from './tree-view.module.scss';

type TreeBaseItem = TreeViewBaseItem & {};

type Props<Item extends TreeBaseItem = TreeBaseItem> = {
  items: Item[];
  onItemDoubleClick?(event: React.MouseEvent, itemId: string): void;
  onItemClick?(event: React.MouseEvent, itemId: string): void;
  onItemExpand?(event: React.MouseEvent, itemId: string): void;
  slots?: React.ComponentProps<typeof RichTreeView>['slots'];
  slotProps?: {
    item?: ({ itemId }: { itemId: string }) => object;
  };
};

const TreeView = <Item extends TreeBaseItem>({
  items,
  onItemDoubleClick,
  onItemClick,
  onItemExpand,
  slots,
  slotProps: { item: createItemProps = () => ({}) } = {},
}: Props<Item>) => {
  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <div className={css.root} ref={rootRef}>
      <RichTreeView
        className={css.tree}
        items={items}
        slots={{
          item: TreeItem,
          ...slots,
        }}
        slotProps={{
          item: ({ itemId }) => ({
            ...createItemProps({ itemId }),
            onClick: (event) => onItemClick?.(event, itemId),
            onDoubleClick: (event) => onItemDoubleClick?.(event, itemId),
            onItemExpand: (event: any) => onItemExpand?.(event, itemId),
            isPlaceholder: +itemId < 0,
          }),
        }}
      />
    </div>
  );
};

export default TreeView;
