import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useMutation, useQuery as useQueryModern } from '@tanstack/react-query';
import { dirsLayerActions } from './actions';
import { useCallback } from 'react';
import { FetchFieldValuesPayload, Field, UpdateFieldValuesPayload } from '.';
import { Dir } from '../dirs';
import { selectDirFields } from './selectors';
import { RootState } from '../../store';
import http from 'utils/http';
import { useTranslation } from 'utils/translation';
import { File } from '../files';

export function useDirIntelligentLayerActions() {
  const dispatch = useDispatch();
  const actions = dirsLayerActions;

  const fetch = useCallback(
    (params: FetchFieldValuesPayload) =>
      dispatch(actions.fetch.trigger(params)),
    // eslint-disable-next-line
    [dispatch],
  );

  const update = useCallback(
    (params: UpdateFieldValuesPayload) =>
      dispatch(actions.update.trigger(params)),
    // eslint-disable-next-line
    [dispatch],
  );

  return {
    fetch,
    update,
  };
}

export function useDirIntelligenceFields(id: Dir['id']) {
  return useSelector(
    (state: RootState) => selectDirFields(state, id),
    shallowEqual,
  );
}

export function useFileIntelligenceFields(file: Pick<File, 'id'>) {
  const { i18n } = useTranslation();

  return useQueryModern({
    queryKey: ['app_intelligence_fields_file', file.id, i18n.language],
    queryFn: () =>
      http.get<never, Field[]>(`/api/intelligence-layer/file/${file.id}`),
    refetchOnWindowFocus: false,
    gcTime: 0,
    retry: false,
  });
}

export function useUpdateFileIntelligenceFields(file: Pick<File, 'id'>) {
  type ValidationResponse = {
    data: { message: string };
    status: number;
  };

  return useMutation<void, { response: ValidationResponse }, any>({
    mutationFn: (fields) =>
      http.put(`/api/intelligence-layer/file/${file.id}`, fields),
  });
}

export function useIntelligenceFields(groups: string[]) {
  const { i18n } = useTranslation();
  const key = groups.join('_');

  return useQuery(
    `app_intelligence_fields_groups-${key}-${i18n.language}`,
    () => {
      return http.get<never, Field[]>('/api/intelligence/group', {
        params: { groups },
      });
    },
  );
}
