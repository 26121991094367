import { Dir } from '../dirs';
import { RootState } from '../../store';

export function selectDirGroupAccess(state: RootState, dir: Dir) {
  const { groups = [], isFetching = false } =
    state.dirAccess.groups[dir.id] || {};

  return {
    isFetching,
    groups
  };
}

export function selectDirUsersAccess(state: RootState, dir: Dir) {
  return state.dirAccess.users[dir.id] || {};
}

export function selectDirUsersBlocked(state: RootState, dir: Dir) {
  return state.dirAccess.blockedUsers[dir.id] || {};
}
