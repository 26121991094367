import React from 'react';
import { useSortableQueryParams } from 'utils/use-data-params';
import Table from './Table';

const SortableTableHeader = ({
  name,
  label,
}: {
  name: string;
  label: string;
}) => {
  const { changeField, direction, field } = useSortableQueryParams();
  let Component = Table.Sortable;

  if (name === field) {
    Component = direction === 'asc' ? Table.SortAsc : Table.SortDesc;
  }

  return <Component onClick={() => changeField(name)}>{label}</Component>;
};

export default SortableTableHeader;
