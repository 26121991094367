import React from 'react';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { RowData } from '@tanstack/table-core';
import DefaultTable from './DefaultTable';
import { DataTableProps } from './types';

function DataTable<TData extends RowData>({
  data,
  columns,
  pagination,
  actions,
  ...rest
}: DataTableProps<TData>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DefaultTable
      {...rest}
      table={table}
      actions={actions}
      pagination={pagination}
    />
  );
}

export default DataTable;
