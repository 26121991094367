import * as React from 'react';
import css from './tabs.module.scss';
import clsx from 'clsx';
import { Tabs as BaseTabs, useTabsContext } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { Tab as BaseTab } from '@mui/base/Tab';
import { useTabPanel } from '@mui/base/useTabPanel/useTabPanel';
import { useCallback } from 'react';

type Props = {
  fullWidth?: boolean;
  rounded?: boolean;
} & React.ComponentProps<typeof BaseTabs>;

const Tabs: React.FC<Props> = (props) => {
  const {
    fullWidth = false,
    rounded = false,
    text = false,
    disabled = false,
    defaultValue = 0,
    ...rest
  } = props;

  return (
    <BaseTabs
      className={clsx(
        css.root,
        fullWidth && css['full-width'],
        rounded && css.rounded,
        text && css.text,
        disabled && css.disabled,
      )}
      defaultValue={defaultValue}
      {...rest}
    />
  );
};

const TabsList = (props: React.ComponentProps<typeof BaseTabsList>) => (
  <BaseTabsList {...props} className={css.list} />
);

const TabPanel = ({
  value,
  children,
}: React.ComponentProps<typeof BaseTabPanel>) => {
  const { hidden } = useTabPanel({ value });

  return (
    <div className={clsx(css.panel, !hidden && css.active)}>{children}</div>
  );
};

const Tab = (props: React.ComponentProps<typeof BaseTab>) => (
  <BaseTab {...props} className={css.tab} />
);

const Descriptor = ({
  icon,
  title,
}: {
  icon?: React.ReactNode;
  title: string;
}) => (
  <span className={css.descriptor}>
    {icon && <span className={css['descriptor-icon']}>{icon}</span>}
    <span className={css['descriptor-title']}>{title}</span>
  </span>
);

export const useChangeTab = () => {
  const context = useTabsContext();

  return useCallback(
    (index: number) => {
      context.onSelected({} as any, index);
    },
    [context.onSelected],
  );
};

export default Object.assign(Tabs, {
  List: TabsList,
  Panel: TabPanel,
  Tab: Tab,
  Descriptor: Descriptor,
});
