import { Dir } from '../dirs';
import { File } from '../files';

export * from './utils';
export * from './hooks/use-resource-context-menu';
export * from './hooks/use-resource-modal';

export const RESOURCE_TYPE = {
  DIRECTORY: 'directory',
  FILE: 'file'
};

export interface BaseResource {
  type: string;
}

export interface FileResource extends BaseResource, File {
  fileType: string;
  title: string;
  directoryId?: number;
  directory?: Dir;
}

export interface DirResource extends BaseResource, Dir {
  //
}

export type Resource = FileResource | DirResource;
