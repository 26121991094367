import React, { PropsWithChildren, useMemo, useState } from 'react';
import ContextBarContext from './ContextBarContext';
import { Options } from './types';

const ContextBarProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<Options | null>(null);

  const contextValue = useMemo(
    () => ({
      open(args: Options) {
        setValue(args);
        setOpen(true);
      },
      close() {
        setOpen(false);
        value?.onClose?.();
      },
      isOpen: open,
      options: value,
      reset: () => {
        setOpen(false);
        setValue(null);
      },
    }),
    [setValue, value, setOpen, open],
  );

  return (
    <ContextBarContext.Provider value={contextValue}>
      {children}
    </ContextBarContext.Provider>
  );
};

export default ContextBarProvider;
