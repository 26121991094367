import {
  createResourceAction,
  createResourceActions
} from '../@common/actions/resource-actions';
import { USERS_PREFIX } from './constants';
import { createPaginationActions } from '../@common/actions/pagination-actions';

export const usersActions = {
  ...createResourceActions(USERS_PREFIX),
  statusChange: createResourceAction(`${USERS_PREFIX}/STATUS_CHANGE`)
};

export const usersPaginateActions = createPaginationActions(USERS_PREFIX);
