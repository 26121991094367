import { Dir } from '.';

export function mapDirectoryTranslatedTitle(dir: Dir, locales: string[]) {
  const entries = Array.from(locales).map((locale) => [
    locale,
    dir?.translations?.[locale]?.title || '',
  ]);

  return Object.fromEntries(entries);
}
