import React, { PropsWithChildren, createContext, useContext } from 'react';

type SearchContextType = {
  query?: string;
};

const SearchContext = createContext<SearchContextType>({
  query: '',
});

export const useSearchContext = () => {
  return useContext(SearchContext);
};

export const SearchContextProvider = ({
  children,
  query,
}: PropsWithChildren<SearchContextType>) => (
  <SearchContext.Provider value={{ query }}>{children}</SearchContext.Provider>
);
