import { FieldsResponse, IntelligentLayerState } from './index';
import { handleActions } from 'redux-actions';
import { dirsLayerActions } from './actions';
import produce from 'immer';
import get from 'lodash/get';
import { Dir } from '../dirs';

const initialState: IntelligentLayerState = {};

interface Payload {
  id: Dir['id'];
  dirId: Dir['id'];
}

interface Meta extends Payload {
  action: any;
  error: any;
}

type PossiblePayload = Payload | FieldsResponse | any;

const initialSingleState = {
  isFetching: true,
  isSubmitting: false,
  didUpdate: false,
  error: null,
  fields: []
};

export const layerFieldsReducer = handleActions<
  IntelligentLayerState,
  PossiblePayload,
  Meta
>(
  {
    [dirsLayerActions.fetch.TRIGGER]: (state, action) => {
      return produce(state, draft => {
        const { id } = action.payload;

        if (!draft[id]) {
          draft[id] = initialSingleState;
        } else {
          draft[id].isFetching = true;
          draft[id].error = null;
        }
      });
    },
    [dirsLayerActions.fetch.SUCCESS]: (state, action) => {
      return produce(state, draft => {
        const { id } = action.meta;
        draft[id].fields = action.payload;
      });
    },
    [dirsLayerActions.fetch.FULFILL]: (state, action) => {
      return produce(state, draft => {
        const { id } = action.payload;
        draft[id].isFetching = false;
      });
    },
    [dirsLayerActions.update.TRIGGER]: (state, action) => {
      return produce(state, draft => {
        const { dirId } = action.payload;
        draft[dirId].isSubmitting = true;
      });
    },
    [dirsLayerActions.update.REQUEST]: (state, action) => {
      return produce(state, draft => {
        const { dirId } = action.payload;
        draft[dirId].isSubmitting = true;
        draft[dirId].error = null;
      });
    },
    [dirsLayerActions.update.SUCCESS]: (state, action) => {
      return produce(state, draft => {
        const { dirId } = action.meta;
        draft[dirId].didUpdate = true;
      });
    },
    [dirsLayerActions.update.FAILURE]: (state, action) => {
      return produce(state, draft => {
        const originalAction = action.meta.action;
        const { dirId } = originalAction.payload;
        draft[dirId].error = get(action, 'meta.response.data.message', null);
      });
    },
    [dirsLayerActions.update.FULFILL]: (state, action) => {
      return produce(state, draft => {
        const { dirId } = action.payload;
        draft[dirId].isSubmitting = false;
        draft[dirId].didUpdate = false;
      });
    }
  },
  initialState
);
