import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import Button from '../../../components/v2/Button/Button';
import useContextBar from '../../../components/v2/ContextBar/useContextBar';
import { useFiltersContext } from '../../../components/v2/Filters/FiltersContext';
import Icon from '../../../components/v2/Icon/Icon';
import { useCreateNewDeviceForm } from 'plugins/device/src/components/NewDeviceForm';
import { PopoverProvider } from '../../../components/v2/Popover/Popover';
import usePermissions from '../../auth/permissions';
import Filters from '../components/Filters';
import { ReportTableActions } from '../components/ReportTableActions';
import Results from '../components/Results';
import ReportProvider from '../providers/ReportProvider';
import Page from 'components/v2/Page/Page';
import { useReportContext } from '../../reports/context/ReportContext';
import Section from 'components/v2/Section/Section';
import { useTranslation } from 'utils/translation';
import ContextBar from 'components/v2/ContextBar/ContextBar';
import ReportTableContextProvider from '../providers/ReportTableContextProvider';
import { DeviceDetailsContextBar } from '../components/DeviceDetailsContextBar';
import { useReportItem } from '../../reports/hooks/use-report-item';

const ReportView = () => {
  const queryRef = useRef<any>();
  const { t } = useTranslation();
  const { can } = usePermissions();
  const { name } = useReportContext();
  const { hasActiveFilters } = useFiltersContext();
  const openNewDeviceForm = useCreateNewDeviceForm();
  const headerElements = [];

  if (can('create directory')) {
    headerElements.push(
      <Button
        onClick={() => {
          openNewDeviceForm({
            onSuccess() {
              queryRef.current?.refetch();
            },
          });
        }}
        variant="text-primary"
        size={'large'}
        startIcon={<Icon name="fa-regular fa-square-plus" />}
      />,
    );
  }

  return (
    <Page title={name}>
      <Section
        titleText={
          <span>
            {hasActiveFilters() ? `${t('Filter')}: ` : ''}
            <strong>{name}</strong>
          </span>
        }
      >
        <Filters />
      </Section>
      <Section
        titleText={t('Devices')}
        modifier={'light-background'}
        headerElements={headerElements}
        actions={[<ReportTableActions />]}
      >
        <Results queryRef={queryRef} />
      </Section>
    </Page>
  );
};

const usePersistedDrawerSelection = () => {
  return useLocalStorageState<{
    title: string;
    id: number;
  } | null>('_last_drawer_selection');
};

const ItemIdentifier = ({ id }: any) => {
  const { data, isLoading } = useReportItem(id);

  if (isLoading) {
    return null;
  }

  return <>ID: {data?.identifier}</>;
};

export default () => {
  const { i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const contextBar = useContextBar();
  const [lastDrawerSelection, setLastDrawerSelection] =
    usePersistedDrawerSelection();
  const hasPrevSelection = !contextBar.isOpen && lastDrawerSelection;

  return (
    <ReportProvider id={id!} key={`${id!}_${i18n.language}`}>
      <PopoverProvider>
        <ReportTableContextProvider
          itemDetailsAction={({ title, id }) => {
            setLastDrawerSelection({ id, title });
            contextBar.open({
              title: <ItemIdentifier id={id} />,
              icon: null,
              component: () => <DeviceDetailsContextBar id={id} />,
              onClose() {
                setLastDrawerSelection(null);
              },
            });
          }}
        >
          <ReportView />
          <ContextBar
            title={
              hasPrevSelection ? (
                <ItemIdentifier id={lastDrawerSelection.id} />
              ) : (
                ''
              )
            }
            onClose={() => {
              setLastDrawerSelection(null);
            }}
          >
            {hasPrevSelection ? (
              <DeviceDetailsContextBar id={lastDrawerSelection.id} />
            ) : null}
          </ContextBar>
        </ReportTableContextProvider>
      </PopoverProvider>
    </ReportProvider>
  );
};
