import Stack from '@mui/material/Stack';
import React, { PropsWithChildren } from 'react';
import { applyFilters, Filters } from '../../../core';
import { Device } from '../../../plugins/device/src/type';
import Section from '../Section/Section';
import css from './service-form.module.scss';
import Cover from '../Cover/Cover';

type Props = PropsWithChildren<Pick<Device, 'attributes'>> & {
  image?: string;
};

const Attributes = ({ attributes }: Pick<Props, 'attributes'>) => (
  <ul className={css.attributes}>
    {Object.entries(attributes).map(([key, value]) => (
      <li className={css.item} key={key}>
        <span className={css.label}>{key}</span>
        <span className={css.value}>{value}</span>
      </li>
    ))}
  </ul>
);

const DeviceServiceForm = ({ attributes, image, children }: Props) => {
  const deviceAttributes = applyFilters(
    Filters.reports.serviceRequestDeviceAttributes,
    attributes || {},
  );

  return (
    <Stack direction="column">
      <Stack direction="row">
        <Section>
          <Attributes attributes={deviceAttributes} />
        </Section>
        {image && (
          <Section>
            <Cover url={image} />
          </Section>
        )}
      </Stack>
      {children}
    </Stack>
  );
};

export default DeviceServiceForm;
