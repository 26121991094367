import React from 'react';
import css from './input-label.module.scss';
import clsx from 'clsx';

type Props = React.HTMLProps<HTMLLabelElement>;

const InputLabel: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  children,
  ...rest
}) => (
  <label {...rest} className={clsx(className, css.root)}>
    {children}
  </label>
);

export default InputLabel;
