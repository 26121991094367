import React, { PropsWithChildren } from 'react';
import { useUserHasRoles } from '../hooks';
import { ROLES } from '../constants';

interface Props {
  roles: string[];
  fallback?: React.ComponentType;
}

type DirectRoleCheckerProps = PropsWithChildren<Pick<Props, 'fallback'>>;

const HasRole: React.FC<React.PropsWithChildren<Props>> = ({
  roles = [],
  children,
  fallback: Fallback = null,
}) => {
  const grantAccess = useUserHasRoles(roles);

  if (grantAccess) {
    return children as React.ReactElement;
  }

  if (Fallback) {
    return <Fallback />;
  }

  return null;
};

const SuperAdminRole: React.FC<DirectRoleCheckerProps> = ({
  children,
  ...rest
}) => (
  <HasRole {...rest} roles={[ROLES.ROLE_SUPER_ADMIN]}>
    {children}
  </HasRole>
);

const EmployeeRole: React.FC<DirectRoleCheckerProps> = ({
  children,
  ...rest
}) => (
  <HasRole {...rest} roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_EMPLOYEE]}>
    {children}
  </HasRole>
);

const AdminRole: React.FC<DirectRoleCheckerProps> = ({ children, ...rest }) => (
  <HasRole
    {...rest}
    roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_EMPLOYEE, ROLES.ROLE_ADMIN]}
  >
    {children}
  </HasRole>
);

export { HasRole, AdminRole, EmployeeRole, SuperAdminRole };
