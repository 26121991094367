import { useQuery } from 'react-query';
import http from '../../../utils/http';

const useSystemStatus = () => {
  return useQuery('api_system_status', () =>
    http.get<any, any>('/system/status'),
  );
};

export default useSystemStatus;
