import { useAppFeatures } from '../../ui/selectors/app';
import { useAppSettings } from '../../ui/hooks';
import Tabs from '../../../components/v2/Tabs/Tabs';
import Form from '../../../components/v2/Form/Form';
import { FieldType } from '../../../components/v2/Form/types';
import React from 'react';
import { useTranslation } from 'utils/translation';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import FormError from '../../../components/v2/FormError/FormError';
import Spacer from 'components/v2/Spacer/Spacer';

const DirectoryTitleField = () => {
  const { t } = useTranslation();
  const { translatableDirectories } = useAppFeatures();
  const { settings } = useAppSettings();
  const {
    formState: { errors },
  } = useFormContext();

  let error = null;

  if (Object.keys(errors).length > 0) {
    settings.locales.find((locale: string, index: number) => {
      if (get(errors, `title.${locale}`)) {
        error = t('required_translation');
      }
    });
  }

  return translatableDirectories ? (
    <>
      {error && (
        <>
          <FormError error={error} />
          <Spacer size={2} />
        </>
      )}
      <Tabs fullWidth>
        <Tabs.List>
          {settings.locales.map((lang: string, index: number) => (
            <Tabs.Tab key={lang} value={index}>
              {lang}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {settings.locales.map((lang: string, index: number) => (
          <Tabs.Panel value={index} key={lang}>
            <Form.Field
              rules={{ required: true }}
              name={`title.${lang}`}
              type={FieldType.Text}
              label={t('Title')}
            />
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  ) : (
    <Form.Field
      name="title"
      type={FieldType.Text}
      label={t('Title')}
      rules={{ required: true }}
    />
  );
};

export default DirectoryTitleField;
