import { useEffect, useState, useCallback } from 'react';

export function useKeyboardKey(key: string) {
  const [isKeyDown, setIsKeyDown] = useState<boolean>(false);

  const handleUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        setIsKeyDown(false);
      }
    },
    [key]
  );

  const handleDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        setIsKeyDown(true);
      }
    },
    [key]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleUp, false);
    document.addEventListener('keydown', handleDown, false);

    return () => {
      document.removeEventListener('keyup', handleUp);
      document.removeEventListener('keydown', handleDown);
    };
  }, [handleUp, handleDown]);

  return {
    isKeyDown
  };
}
