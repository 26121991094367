import { Action } from '../../@common/actions/action';
import { handleActions } from 'redux-actions';
import { ModalActionTypes } from '../actions';

interface UIState {
  activeModalId: Nullable<string>;
}

const initialState: UIState = {
  activeModalId: null
};

export const modalReducer = handleActions(
  {
    [ModalActionTypes.SHOW]: (state, action: Action) => {
      const { id } = action.payload;
      return {
        ...state,
        activeModalId: id
      };
    },
    [ModalActionTypes.HIDE]: state => {
      return {
        ...state,
        activeModalId: null
      };
    }
  },
  initialState
);
