import { QueryConfig, useQuery, queryCache } from 'react-query';
import { getItemDetails } from '../api';
import i18n, { useTranslation } from 'utils/translation';
import { Details } from '../types';

export function useReportItem(
  id: number | string,
  queryConfig: QueryConfig<Details> = {},
) {
  const { i18n } = useTranslation();

  return useQuery<Details>(
    `api_report_item_${id}_${i18n.language}`,
    () => getItemDetails({ id: Number(id) }),
    queryConfig,
  );
}

export function refreshReportItem(id: number | string) {
  void queryCache.refetchQueries(`api_report_item_${id}_${i18n.language}`);
}
