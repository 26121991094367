import React from 'react';
import { Navigate } from 'react-router-dom';
import ReportView from '../../modules/assets/view/ReportView';
import { useUiVersion } from '../../modules/ui/UserInterfaceVersionProvider';

export default () => {
  const uiVersion = useUiVersion();

  if (uiVersion.is('default')) {
    return <Navigate to="/report" />;
  }

  return (
    <React.StrictMode>
      <ReportView />
    </React.StrictMode>
  );
};
