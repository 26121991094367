import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation, Trans } from 'react-i18next';
import translations from '../translations';
import localeConfig from 'config/locale';
import moment from 'moment';
import dayjs from 'dayjs';

const detector = new LanguageDetector(null, {
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
});

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(detector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      resources: translations,
      defaultNS: 'global',
      fallbackLng: localeConfig.fallbackLocale,
      supportedLngs: localeConfig.locales,
      debug: false,
      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      moment.locale(i18n.language);
      dayjs.locale(i18n.language);
    },
  );

i18n.on('languageChanged', () => {
  moment.locale(i18n.language);
  dayjs.locale(i18n.language);
});

export { useTranslation, Trans };

export default i18n;
