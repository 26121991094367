import React from 'react';
import css from './form-error.module.scss';

const FormError = ({ error }: { error?: string }) => {
  if (!error) {
    return null;
  }

  return <div className={css.error}>{error}</div>;
};

export default FormError;
