import { Dir } from '../dirs';
import { RootState } from '../../store';

function root(state: RootState) {
  return state.dirsFieldLayer;
}

export function selectDirFields(state: RootState, id: Dir['id']) {
  const dirState = root(state)[id] || {};
  const {
    isFetching = true,
    isSubmitting,
    fields,
    didUpdate,
    error
  } = dirState;

  return {
    isFetching,
    isSubmitting,
    didUpdate,
    error,
    fields
  };
}
