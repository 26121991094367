import React, { forwardRef } from 'react';
import { Input } from '@mui/base/Input';
import Textarea from '../Textarea/Textarea';
import css from './text-input.module.scss';
import clsx from 'clsx';
import FormError from '../FormError/FormError';

type Props<Multiline extends boolean = false> = {
  border?: boolean;
  error?: string;
  variant?: 'default' | 'filter';
  multiline?: Multiline;
} & Omit<
  React.ComponentProps<Multiline extends true ? typeof Textarea : typeof Input>,
  'error'
>;

const TextInput = forwardRef<any, Props>((props, ref) => {
  const {
    border = true,
    error,
    multiline = false,
    variant = 'default',
    inputRef,
    value,
    ...rest
  } = props;
  const Component = multiline ? Textarea : Input;

  const extraProps = multiline
    ? {}
    : {
        slotProps: {
          root: { className: css.wrapper },
          input: { className: css.input, ref, value },
        },
      };

  return (
    <div
      className={clsx(
        css.root,
        border && css.border,
        variant && css[`variant-${variant}`],
        !!error && css['is-invalid'],
      )}
    >
      <Component {...rest} {...extraProps} ref={multiline ? ref : undefined} />
      <FormError error={error} />
    </div>
  );
});

export default TextInput;
