import React from 'react';
import { useQuery } from '@tanstack/react-query';
import Loader from '../Loader';
import ServerDataError from '../Error/ServerDataError';
import Empty from '../Empty/Empty';
import { Pagination } from '../../../modules/@common/crud/types';

type Props<Data extends unknown> = Pick<
  ReturnType<typeof useQuery<Data>>,
  'data' | 'isLoading' | 'isError'
> & {
  render: (data: Defined<Props<Data>['data']>) => React.ReactNode;
};

const isPagination = <Data extends unknown>(
  data: any,
): data is Pagination<Data> =>
  Boolean(typeof data?.meta?.totalItems !== 'undefined');

const QueryResult = <Data extends unknown>({
  isError,
  isLoading,
  data,
  render,
  ...rest
}: Props<Data>) => {
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    if (process.env.NODE_ENV === 'development') {
      // @ts-ignore
      console.error(rest.error);
    }

    return <ServerDataError />;
  }

  if (Array.isArray(data) && data.length === 0) {
    return <Empty.General />;
  }

  if (data && isPagination(data) && data?.meta?.totalItems === 0) {
    return <Empty.General />;
  }

  return render(data as Defined<Data>);
};

export default QueryResult;
