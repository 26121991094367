import React, { Suspense } from 'react';
import Dashboard from '../../components/v2/Layout/Dashboard';
import { LinearProgress } from '@mui/material';
import DrawerSelectionProvider from '../../modules/v2/files/providers/DrawerSelectionProvider';
import { renderRoutes, RouteConfig } from '../../utils/render-routes';
import DashboardTopBar from '../AssetsDashboard/TopBar';
import ScheduledDownloadsProvider from '../../modules/export/ScheduledDownloadsProvider';
import { UploadingList } from '../../modules/files/components/UploadingList';
import { GatewayProvider, GatewayDest } from 'react-gateway';
import FilesSidebar from './Sidebar';

type Props = {
  route: {
    routes: RouteConfig[];
  };
};

const FilesDashboard = (props: Props) => {
  const { route } = props;

  return (
    // @ts-ignore
    <GatewayProvider>
      <DrawerSelectionProvider>
        <Dashboard
          topBar={<DashboardTopBar />}
          sidebar={<FilesSidebar />}
          shouldResetContextBarStateOnPageChange={false}
        >
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
          <UploadingList />
          <ScheduledDownloadsProvider />
          {/* @ts-ignore */}
          <GatewayDest name="footer" />
        </Dashboard>
      </DrawerSelectionProvider>
    </GatewayProvider>
  );
};

export default FilesDashboard;
