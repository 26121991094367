import { createAction, createType } from './action';
import { createResourceAction, createResourceType } from './resource-actions';

export function createPaginationActionTypes(prefix: string) {
  return {
    CHANGE_PAGE: createType(`${prefix}/PAGINATION/CHANGE_PAGE`),
    CHANGE_PER_PAGE: createType(`${prefix}/PAGINATION/CHANGE_PER_PAGE`),
    FETCH: createResourceType(`${prefix}/PAGINATION/FETCH`)
  };
}

export type PaginationActions = ReturnType<typeof createPaginationActions>;

export function createPaginationActions(prefix: string) {
  return {
    ...createPaginationActionTypes(prefix),
    changePage: createAction(`${prefix}/PAGINATION/CHANGE_PAGE`),
    changePerPage: createAction(`${prefix}/PAGINATION/CHANGE_PER_PAGE`),
    fetch: createResourceAction(`${prefix}/PAGINATION/FETCH`)
  };
}
