import React from 'react';
import { FieldProps } from './types';
import { FormControl, TextField } from '@mui/material';
import omit from 'lodash/omit';

const DefaultField: React.FC<FieldProps> = ({
  value,
  errorMessage,
  name,
  onChange,
  type,
  settings = {},
}) => {
  const inputProps = omit(settings, ['unique', 'required']);

  return (
    <FormControl fullWidth error={!!errorMessage} component="fieldset">
      <TextField
        error={!!errorMessage}
        helperText={errorMessage}
        variant="outlined"
        fullWidth
        inputProps={inputProps}
        value={value || ''}
        name={name}
        type={type}
        onChange={onChange}
        autoComplete="off"
      />
    </FormControl>
  );
};

export { DefaultField };
