import React from 'react';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import ViewListIcon from '@mui/icons-material/ViewList';
import { ReactComponent as WysiwygIcon } from '../../../../assets/icons/wysiwyg.svg';
import { IconButton, Theme, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ViewMode } from '../../../../modules/ui/types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'utils/translation';
import { applyFilters, Filters } from '../../../../core';

interface Props {
  onChange: (value: ViewMode) => void;
  value: ViewMode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
  },
}));

const icons = {
  [ViewMode.Grid]: ViewComfyIcon,
  [ViewMode.Table]: ViewListIcon,
  [ViewMode.Contextual]: WysiwygIcon,
};

const ViewIcon = ({ mode }: { mode: keyof typeof icons }) => {
  const Icon = icons[mode];

  if (Icon) {
    return <Icon />;
  }

  return null;
};

export const ViewSwitcher = ({ onChange, value }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (mode: ViewMode) => {
    onChange(mode);
    setAnchorEl(null);
  };

  const availableModes = [ViewMode.Table, ViewMode.Grid];

  const contextualModeActive = applyFilters(
    Filters.directories.contextualViewModelEnabled,
    false,
  );

  if (contextualModeActive) {
    availableModes.push(ViewMode.Contextual);
  }

  return (
    <>
      <IconButton className={classes.root} onClick={handleOpen} size="small">
        <ViewIcon mode={value} />
      </IconButton>
      <Menu
        id="dashboard-view-mode"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {availableModes.map((mode) => (
          <MenuItem onClick={() => handleChange(mode)} key={mode}>
            <Box mr={1}>
              <ViewIcon mode={mode} />
            </Box>
            <Typography>{t(mode).toUpperCase()}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
