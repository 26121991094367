import React from 'react';
import { FieldProps } from './types';
import { FormControl } from '@mui/material';
import { TextEditor } from '../../../../components';

const Editor: React.FC<FieldProps> = ({
  name,
  errorMessage,
  value,
  settings,
  form,
}) => {
  return (
    <FormControl fullWidth variant="outlined" error={!!errorMessage}>
      <TextEditor
        onEditorChange={(content) => {
          form.setFieldValue(name, content);
        }}
        initialValue={value}
      />
    </FormControl>
  );
};

export default React.memo(
  Editor,
  (prev, current) => prev.name === current.name,
);
