import ListItemIcon from '@mui/material/ListItemIcon';
import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Menu, MenuItem } from '../Menu/Menu';
import { ContextType, ContextState } from './types';
import css from './context-menu.module.scss';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const Context = createContext<ContextType | null>(null);

export const useContextMenuContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('ContextMenu Context Provider not found.');
  }

  return context;
};

const ContextMenuProvider = ({ children }: React.PropsWithChildren) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<ContextState | null>(null);
  const [xOffset, setXOffset] = useState(0);
  const [yOffset, setYOffset] = useState(0);

  useLayoutEffect(() => {
    if (containerRef.current && state?.x) {
      const xPos = state?.x - xOffset;
      if (window?.innerWidth < containerRef.current.clientWidth + xPos) {
        setXOffset(containerRef.current.clientWidth);
      }
    }

    if (containerRef.current && state?.y) {
      const yPos = state?.y - yOffset;
      if (window?.innerHeight < containerRef.current.clientHeight + yPos) {
        setYOffset(containerRef.current.clientHeight);
      }
    }
  }, [state, xOffset, yOffset]);

  const contextValue = useMemo(
    () => ({
      state,
      open: (args: ContextState) => {
        setXOffset(0);
        setYOffset(0);
        setState(args);
      },
    }),
    [state, setState],
  );

  const onClose = () => {
    setState(null);
  };

  return (
    <Context.Provider value={contextValue}>
      {children}
      {state !== null && (
        <ClickAwayListener onClickAway={onClose}>
          <div
            ref={containerRef}
            className={css.root}
            style={
              {
                ['--offset-x']: `${state.x - xOffset}px`,
                ['--offset-y']: `${state.y - yOffset}px`,
              } as React.CSSProperties
            }
          >
            <Menu>
              {Array.from(state.actions || [])
                .filter(({ visible = true }) => visible)
                .map(
                  ({ action, label, disabled, divider, icon: Icon }, index) => (
                    <MenuItem
                      onClick={() => {
                        action && action();
                        onClose();
                      }}
                      key={index}
                      disabled={disabled}
                      divider={divider}
                    >
                      {Icon !== null && <ListItemIcon>{Icon}</ListItemIcon>}
                      {label}
                    </MenuItem>
                  ),
                )}
            </Menu>
          </div>
        </ClickAwayListener>
      )}
    </Context.Provider>
  );
};

export default ContextMenuProvider;
