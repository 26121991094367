import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@mui/styles/makeStyles';
import {
  Card,
  CardContent,
  Checkbox,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useTranslation } from 'utils/translation';
import {
  ContextMenuState,
  isDir,
  isFile,
  Resource,
} from '../../../../modules/resources';
import { Theme } from 'theme';
import { DateFormat } from 'components';
import { Selection } from '../../../../utils/use-selection';
import { usePagination } from '../../../../utils/use-pagination';
import {
  useCurrentDir,
  useDiskPagination,
  useResultsViewMode,
} from '../../../../modules/ui/hooks';
import { useSortableDiskContext } from '../../utils/sortable.context';
import ResourceIcon from './ResourceIcon';
import config from '../../../../config/dirs';
import { applyFilters } from '../../../../core';
import { alpha } from '@mui/material/styles';
import { ViewMode } from '../../../../modules/ui/types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DirectoryBreadcrumbs from '../Breadcrumbs/DirectoryBreadcrumbs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 70,
  },
  content: {
    padding: 0,
    paddingBottom: '0 !important',
  },
  row: {
    cursor: 'pointer',
    '& td': {
      userSelect: 'none',
    },
  },
  iconType: {
    width: 30,
    paddingRight: 0,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  name: {
    width: 'calc(60% - 30px)',
  },
  type: {
    width: '15%',
  },
  createdOn: {
    width: '25%',
    [theme.breakpoints.up('lg')]: {
      width: '15%',
    },
  },
  timestamps: {
    whiteSpace: 'nowrap',
  },
  actions: {
    width: '5%',
  },
  inner: {},
  title: {},
  selected: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    '& $title': {
      fontWeight: 'bold',
    },
  },
}));

interface ResultsProps {
  className?: string;
  resources: Resource[];
  onClick: (resource: Resource, event: React.MouseEvent) => void;
  onDoubleClick: (resource: Resource, event: React.MouseEvent) => void;
  onDeleteMany: (resources: Resource[]) => void;
  selectedResource?: Nullable<Resource>;
  onItemRightClick(data: ContextMenuState): void;
  showResourcePath?: boolean;
}

const ResourceLabel = ({ item }: { item: Resource }) => {
  const { t } = useTranslation();
  let label = 'file';

  const availableTypes = React.useMemo(
    () => applyFilters('available_directory_types', config.allTypes),
    [],
  );

  if (isDir(item)) {
    label = availableTypes[item.directoryType] || 'folder';
  }

  if (isFile(item)) {
    label = item.fileType || item.type;
  }

  return <>{t(label)}</>;
};

const SHOW_ALL_MAX_VALUE = 1000;

const TableView = (props: ResultsProps & Selection<Resource>) => {
  const {
    className,
    resources = [],
    onDeleteMany,
    onClick,
    onDoubleClick,
    onItemRightClick,
    selectedResource,

    selectAll,
    deselectAll,
    selectOne,
    setLastSelected,
    resetLastSelected,
    selectedItems,
    isSelected,
    showResourcePath = false,

    ...rest
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { viewMode } = useResultsViewMode();
  const { currentDirId } = useCurrentDir();
  const { sortable, sort } = useSortableDiskContext();
  const { perPage, setPerPage } = useDiskPagination();
  const items = React.useMemo(() => sort(resources), [sort, resources]);
  const { page, setPage, currentPageItems } = usePagination<Resource>({
    items,
    perPage,
  });

  React.useEffect(() => {
    setPage(0);
    // eslint-disable-next-line
  }, [currentDirId]);

  React.useEffect(() => {
    resetLastSelected();
    // eslint-disable-next-line
  }, [page]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? selectAll() : deselectAll();
  };

  const handleSelectOne = (
    event: React.ChangeEvent<HTMLInputElement>,
    resource: Resource,
  ) => {
    selectOne(resource);
  };

  const handleRowClick = (event: React.MouseEvent, item: Resource) => {
    // @ts-ignore
    if (event.target && event.target.getAttribute('type') === 'checkbox') {
      return false;
    }

    onClick(item, event);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event: any) => {
    if (event.target.value === -1) {
      setPerPage(SHOW_ALL_MAX_VALUE);
    } else {
      setPerPage(+event.target.value);
    }
    setPage(0);
  };

  const handleContextMenuClick = (event: MouseEvent, resource: Resource) => {
    event.preventDefault();
    onItemRightClick({
      resource: resource,
      x: event.clientX,
      y: event.clientY,
    });
  };

  const isItemDrawerSelected = (item: Resource) =>
    selectedResource &&
    selectedResource.type === item.type &&
    selectedResource.id === item.id;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardContent className={classes.content}>
          {/* @ts-ignore */}
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedItems.length > 0 &&
                          selectedItems.length === resources.length
                        }
                        color="primary"
                        indeterminate={
                          selectedItems.length > 0 &&
                          selectedItems.length < resources.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell className={classes.iconType} />
                    <TableCell className={classes.name}>
                      <TableSortLabel
                        active={sortable.isActive('name')}
                        direction={sortable.direction}
                        onClick={() => sortable.setField('name')}
                      >
                        {t('Name')}
                      </TableSortLabel>
                    </TableCell>
                    <Hidden lgDown>
                      <TableCell className={classes.type}>
                        <TableSortLabel
                          active={sortable.isActive('type')}
                          direction={sortable.direction}
                          onClick={() => sortable.setField('type')}
                        >
                          {t('Type')}
                        </TableSortLabel>
                      </TableCell>
                    </Hidden>
                    <TableCell className={classes.createdOn}>
                      <TableSortLabel
                        active={sortable.isActive('createdAt')}
                        direction={sortable.direction}
                        onClick={() => sortable.setField('createdAt')}
                      >
                        {t('Created on')}
                      </TableSortLabel>
                    </TableCell>
                    {viewMode === ViewMode.Contextual && (
                      <TableCell
                        padding="checkbox"
                        className={classes.actions}
                      />
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageItems.map((item) => (
                    <TableRow
                      hover
                      className={clsx(classes.row, {
                        [classes.selected]: isItemDrawerSelected(item),
                      })}
                      key={`${item.type}_${item.id}`}
                      selected={isSelected(item)}
                      onClick={(event) => handleRowClick(event, item)}
                      onDoubleClick={(event) => onDoubleClick(item, event)}
                      onContextMenu={(event: any) =>
                        handleContextMenuClick(event, item)
                      }
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected(item)}
                          color="primary"
                          onChange={(event) => handleSelectOne(event, item)}
                          value={isSelected(item)}
                        />
                      </TableCell>
                      <TableCell className={classes.iconType}>
                        <ResourceIcon item={item} />
                      </TableCell>
                      <TableCell>
                        <div className={classes.title}>{item.title}</div>
                        {isFile(item) &&
                          showResourcePath &&
                          item.directory &&
                          item.directory.path && (
                            <DirectoryBreadcrumbs items={item.directory.path} />
                          )}
                        {isDir(item) && showResourcePath && item.path && (
                          <DirectoryBreadcrumbs items={item.path} />
                        )}
                      </TableCell>
                      <Hidden lgDown>
                        <TableCell>
                          <ResourceLabel item={item} />
                        </TableCell>
                      </Hidden>
                      <TableCell className={classes.timestamps}>
                        <DateFormat date={item.createdAt} withTime />
                      </TableCell>
                      {viewMode === ViewMode.Contextual && (
                        <TableCell
                          padding="checkbox"
                          className={classes.actions}
                        >
                          <IconButton
                            onClick={(event: any) =>
                              handleContextMenuClick(event, item)
                            }
                            size="large"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        25,
                        50,
                        100,
                        { value: -1, label: 'All' },
                      ]}
                      colSpan={5}
                      count={resources.length}
                      rowsPerPage={
                        perPage === SHOW_ALL_MAX_VALUE ? -1 : perPage
                      }
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  );
};

TableView.defaultProps = {
  onClick: () => {},
  onDoubleClick: () => {},
};

export { TableView };
