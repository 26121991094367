import { combineReducers } from 'redux';
import { diskReducer } from './reducers/disk';
import { modalReducer } from './reducers/modal';
import { appSettingsReducer } from './reducers/app';
import { resourceModalReducer } from './reducers/resourceModal';

export const uiReducer = combineReducers({
  app: appSettingsReducer,
  disk: diskReducer,
  modal: modalReducer,
  resourceModal: resourceModalReducer
});
