import React, { PropsWithChildren, useContext, useMemo } from 'react';

type TableContextType = {
  keyPrefix?: string;
  itemDetailsAction?(item: any): void;
  itemHoverAction?(item: any): void;
  attributes: string[];
  availableAttributes: {
    [key: string]: string;
  };
  setAttributes(attributes: string[]): void;
  getAttributeLabel(attribute: string): string;
};

const TableContext = React.createContext<TableContextType | null>(null);

export const useTableContext = () => {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error('TableContext not found!');
  }

  return context;
};

type Props = PropsWithChildren<
  Pick<
    TableContextType,
    | 'attributes'
    | 'availableAttributes'
    | 'keyPrefix'
    | 'itemDetailsAction'
    | 'itemHoverAction'
  >
>;

const TableContextProvider = ({
  children,
  attributes = [],
  availableAttributes = {},
  keyPrefix = '',
  itemDetailsAction,
  itemHoverAction,
}: Props) => {
  const [attrs, setAttrs] =
    React.useState<TableContextType['attributes']>(attributes);
  const key = attrs.join('-');

  const contextValue = useMemo(
    () => ({
      keyPrefix,
      itemDetailsAction,
      itemHoverAction,
      attributes: attrs,
      availableAttributes: availableAttributes,
      setAttributes: setAttrs,
      getAttributeLabel(name: string) {
        return availableAttributes[name] || '';
      },
    }),
    [key],
  );

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  );
};

export default TableContextProvider;
