import React, { useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { useTranslation } from 'utils/translation';
import { ProfileForm, Form } from '../components/ProfileForm';
import { Alert } from '../../../components';
import { useAuthActions, useCurrentUser } from '../../auth/hooks';
import { useProfileUpdate } from '../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  alert: {
    margin: theme.spacing(2, 0),
  },
}));

interface Props {
  onCancel: () => void;
}

export const UpdateProfile = ({ onCancel }: Props) => {
  const user = useCurrentUser();
  const { fetchUser } = useAuthActions();
  const classes = useStyles();
  const { t } = useTranslation();
  const bagRef = useRef<Form['Bag']>();
  const { request, error, validationErrors, success } = useProfileUpdate();

  useEffect(() => {
    if (bagRef.current) {
      bagRef.current.setErrors(validationErrors || {});
    }
  }, [validationErrors, bagRef]);

  useEffect(() => {
    if (success) {
      fetchUser();
    }
  }, [success, fetchUser]);

  const handleSubmit = async (values: Form['Values'], bag: Form['Bag']) => {
    bagRef.current = bag;
    await request(values);
    bag.setSubmitting(false);
  };

  if (success) {
    return (
      <Alert
        className={classes.alert}
        onClose={onCancel}
        variant="success"
        message={t('The profile has been updated')}
      />
    );
  }

  return (
    <div className={classes.root}>
      {error && (
        <Alert
          className={classes.alert}
          variant="error"
          message={t('Error occurred')}
        />
      )}
      <ProfileForm
        initialValues={user}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
