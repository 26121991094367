import React, { useCallback } from 'react';
import { useModal } from '../Modal/useModal';
import Confirmation from './Confirmation';

type ConfirmationOptions = {
  title?: string;
  question?: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm?(): void;
  onCancel?(): void;
};

const useConfirmation = () => {
  const modal = useModal();

  return useCallback(
    (options: ConfirmationOptions = {}) => {
      return new Promise<boolean>((resolve) => {
        const Component = () => (
          <Confirmation
            {...options}
            onConfirm={() => {
              options.onConfirm && options.onConfirm();
              modal.close(Component);
              resolve(true);
            }}
            onCancel={() => {
              options.onCancel && options.onCancel();
              modal.close(Component);
              resolve(false);
            }}
          />
        );

        modal.open(Component, { size: 'xs', disableClose: true });
      });
    },
    [modal.open],
  );
};

export default useConfirmation;
