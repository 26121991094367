import React from 'react';
import useLocalStorageState from 'use-local-storage-state';
import css from './context-bar.module.scss';

const STORAGE_KEY = 'quodoc_context_bar_width' as const;

type Args = {
  ref: React.RefObject<HTMLElement>;
  minWidth?: number;
};

export const MIN_WIDTHS = {
  DEFAULT: 280,
  LARGE: 450,
} as const;

export function useResizableContextBar({
  ref,
  minWidth = MIN_WIDTHS.DEFAULT,
}: Args) {
  const [width, setWidth] = useLocalStorageState<number>(
    `${STORAGE_KEY}_${minWidth}`,
  );
  const [isDragging, setIsDragging] = React.useState(false);

  if (!document) {
    return {
      isDragging,
      width: undefined,
      handle: null,
    };
  }

  const onResizePointerDown = (event: React.PointerEvent) => {
    setIsDragging(true);
    const startX = event.pageX;
    const initialWidth = width || ref?.current?.clientWidth;
    document.body.style.userSelect = 'none';

    const onPointerMove = (event: PointerEvent) => {
      const delta = startX - event.pageX;
      const maxWidth = Math.ceil(window.innerWidth / 3);

      if (delta < 0) {
        setWidth(Math.max(minWidth, (initialWidth || 0) + delta));
      } else {
        setWidth(Math.min(maxWidth, (initialWidth || 0) + delta));
      }
    };

    const onPointerUp = () => {
      setIsDragging(false);
      document.addEventListener('pointermove', onPointerMove);
      document.removeEventListener('pointermove', onPointerMove);
      document.body.style.userSelect = '';
    };

    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);
  };

  return {
    isDragging,
    width,
    handle: (
      <div
        className={css['resizable-handle']}
        onPointerDown={onResizePointerDown}
      />
    ),
  };
}
