import { useEffect, useState } from 'react';

type Args<T> = {
  perPage?: number;
  items: T[];
};

export function useLazyLoading<T>(
  { perPage = 50, items }: Args<T>,
  deps: any[] = []
) {
  const [page, setPage] = useState<number>(0);

  const increasePage = () => {
    if (items.slice(0, (page + 1) * perPage).length < items.length) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    setPage(0);
    // eslint-disable-next-line
  }, deps);

  return {
    items: items.slice(0, (page + 1) * perPage),
    increasePage,
    page,
    setPage
  };
}
