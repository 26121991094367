import { BasketState } from './index';
import { Action, handleActions } from 'redux-actions';
import { basketActions } from './actions';
import produce from 'immer';
import { File } from '../files';
import { Dir } from '../dirs';

const initialState: BasketState = {
  files: [],
  directories: [],
  isLoading: false,
  isDownloading: false
};

interface PossiblePayload {
  file: File;
  dir: Dir;
  files: File[];
  directories: Dir[];
}

const enableLoading = (state: BasketState) => {
  return {
    ...state,
    isLoading: true
  };
};

const mapBasketResponseToState = (
  state: BasketState,
  action: Action<PossiblePayload>
) => {
  const { files = [], directories = [] } = action.payload;

  return produce(state, draft => {
    draft.files = files.map(file => file.id);
    draft.directories = directories.map(dir => dir.id);
  });
};

export const basketReducer = handleActions<BasketState, PossiblePayload>(
  {
    [basketActions.fetch.REQUEST]: enableLoading,

    [basketActions.fetch.SUCCESS]: mapBasketResponseToState,
    //[basketActions.addFile.SUCCESS]: mapBasketResponseToState,
    //[basketActions.addDirectory.SUCCESS]: mapBasketResponseToState,

    [basketActions.fetch.FULFILL]: (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    },

    [basketActions.addFile.REQUEST]: (state, action) => {
      const { file } = action.payload;
      return produce(state, draft => {
        if (!draft.files.includes(file.id)) {
          draft.files.push(file.id);
        }
      });
    },

    [basketActions.removeFile.REQUEST]: (state, action) => {
      const { file } = action.payload;
      return produce(state, draft => {
        draft.files = draft.files.filter(id => id !== file.id);
      });
    },

    [basketActions.addDirectory.REQUEST]: (state, action) => {
      const { dir } = action.payload;
      return produce(state, draft => {
        if (!draft.directories.includes(dir.id)) {
          draft.directories.push(dir.id);
        }
      });
    },

    [basketActions.removeDirectory.REQUEST]: (state, action) => {
      const { dir } = action.payload;
      return produce(state, draft => {
        draft.directories = draft.directories.filter(id => id !== dir.id);
      });
    },

    [basketActions.download.TRIGGER]: state =>
      produce(state, draft => {
        draft.isDownloading = true;
      }),

    [basketActions.download.FULFILL]: state =>
      produce(state, draft => {
        draft.isDownloading = false;
      })
  },
  initialState
);
