import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useLocation } from 'utils/router';
import { Box, SxProps } from '@mui/material';
import { Theme } from 'theme';

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const APP_NAME = process.env.REACT_APP_APP_NAME;

interface PageProps {
  title: string;
  children: any;
  sx?: SxProps<Theme>;
  [key: string]: any;
}

const Page = (props: PageProps) => {
  const { title, children, ...rest } = props;
  const location = useLocation();

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    // @ts-ignore
    if (window.gtag) {
      // @ts-ignore
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: location.pathname,
        page_name: title,
      });
    }
  }, [title, location]);

  return (
    <Box {...rest}>
      {/* @ts-ignore */}
      <Helmet>
        <title>{`${APP_NAME} - ${title}`}</title>
      </Helmet>
      {children}
    </Box>
  );
};

export default Page;
