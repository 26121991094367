export function empty<Item = unknown>(
  data: Array<Item> | ArrayLike<Item> | undefined,
) {
  if (!data) {
    return true;
  }

  if (Array.isArray(data)) {
    return data.length === 0;
  }

  return Array.from(data || []).length === 0;
}

export function move(array: any[], moveIndex: number, toIndex: number) {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }

  return array;
}
