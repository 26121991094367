import { useQuery } from 'react-query';
import http from '../../../utils/http';
import { ReportResponse } from '../types';
import { useTranslation } from 'utils/translation';
import { useShallowEqualEffect } from '../../../utils/use-shallow-effect';
import { useUiVersion } from '../../ui/UserInterfaceVersionProvider';

type Args = {
  id: string;
  params?: object;
  page?: number;
  perPage?: number;
  sortableField?: string;
  sortableDirection?: string;
  queryKeyPrefix?: string;
};

const useReport = ({
  id,
  page,
  perPage,
  sortableField,
  sortableDirection,
  params = {},
  queryKeyPrefix = '',
}: Args) => {
  const { i18n } = useTranslation();
  const { version } = useUiVersion();
  const url = version === 'v2' ? `/api/reports/v2/${id}` : `/api/reports/${id}`;

  return useQuery(
    `${queryKeyPrefix}_${version}_${id}_report_results_${i18n.language}`,
    () =>
      http.get<never, ReportResponse>(url, {
        params: {
          page,
          perPage,
          pageSize: perPage,
          order: sortableField,
          direction: sortableDirection,
          params: JSON.stringify(params),
        },
      }),
    {
      refetchInterval: false,
    },
  );
};

export const useReportTotalResults = ({ id, params = {} }: Args) => {
  const { i18n } = useTranslation();

  const query = useQuery(
    `${id}_report_results_${i18n.language}_total_results`,
    () =>
      http.get<never, { total: number }>(`/api/reports/${id}/total`, {
        params: {
          params: JSON.stringify(params),
        },
      }),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );

  useShallowEqualEffect(() => {
    void query.refetch();
  }, [query.refetch, params]);

  return query;
};

export default useReport;
