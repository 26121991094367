import { shallowEqual, useSelector } from 'react-redux';
import { isDirInBasket, isFileInBasket, selectBasketItems } from '../selectors';
import { RootState } from '../../../store';
import { isDir, isFile, Resource } from '../../resources';
import { useMemo } from 'react';

export * from './use-basket-actions';

export function useBasketItems() {
  const { files, directories } = useSelector(selectBasketItems, shallowEqual);

  return useMemo(
    () => ({
      files,
      directories,
    }),
    [files, directories],
  );
}

export function useIsDirInBasket(id: number) {
  return useSelector(
    (state: RootState) => isDirInBasket(state, id),
    shallowEqual,
  );
}

export function useIsFileInBasket(id: number) {
  return useSelector(
    (state: RootState) => isFileInBasket(state, id),
    shallowEqual,
  );
}

export function useIsResourceInBasket(resource: Resource) {
  const dirInBasket = useIsDirInBasket(resource.id);
  const fileInBasket = useIsFileInBasket(resource.id);

  if (isDir(resource)) {
    return dirInBasket;
  }

  if (isFile(resource)) {
    return fileInBasket;
  }

  return false;
}

export function useIsBasketLoading() {
  return useSelector(
    (state: RootState) => state.basket.isLoading,
    shallowEqual,
  );
}

export function useIsBasketDownloading() {
  return useSelector(
    (state: RootState) => state.basket.isDownloading,
    shallowEqual,
  );
}
