import React from 'react';
import { useDirCoverQuery } from '../../../modules/dirs/hooks';
import Cover from '../Cover/Cover';
import { useFilesActions } from '../../../modules/files/hooks';
import { v4 as uuid } from 'uuid';
import useConfirmation from '../Confirmation/use-confirmation';
import wait from '../../../utils/wait';

export const DeviceCover: React.FC<{ id: number }> = ({ id }) => {
  const query = useDirCoverQuery(id);
  const { createFile, addToUploadQueue, deleteFile } = useFilesActions();
  const confirm = useConfirmation();

  const onUpload = async (file: File) => {
    const uploaded = new File([file], 'cover.png', { type: file.type });
    // @ts-ignore
    uploaded.id = uuid();
    addToUploadQueue([uploaded]);

    return new Promise<void>((resolve) => {
      createFile({
        file: uploaded,
        dirId: id,
        onSuccess: async () => {
          await query.refetch();
          resolve();
        },
      });
    });
  };

  const onDestroy = async () => {
    const current = query.data?.at(0);

    if (!current) {
      return;
    }

    await confirm({
      async onConfirm() {
        deleteFile({
          id: current.id,
        });
        await wait(1000);
        void query.refetch();
      },
    });
  };

  const url = query.data?.at(0)?.previewUrl;

  return (
    <Cover
      url={url}
      onUpload={onUpload}
      onDestroy={url ? onDestroy : undefined}
    />
  );
};

export default DeviceCover;
