import Stack from '@mui/material/Stack/Stack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import manifest from '../../../../package.json';
import Typography from '../../../components/v2/Typography/Typography';
import { formatNumber } from '../../../utils/format-number';
import useSystemStatus from '../../settings/hooks/use-system-status';

const SystemSummary = () => {
  const { t } = useTranslation();
  const { data = {} } = useSystemStatus();
  const storageSize = data?.storage?.total?.sizeFormatted;
  const objectsCount = data?.objects;

  return (
    <Typography align="center" variant="caption" component="div">
      <Stack direction="row" justifyContent="space-between">
        <span>
          {t('app_version')} {manifest.version}
        </span>
        {storageSize && <strong>{storageSize}</strong>}
        {objectsCount > 0 && <strong>{formatNumber(objectsCount)} ID's</strong>}
      </Stack>
    </Typography>
  );
};

export default SystemSummary;
