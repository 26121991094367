import { useCallback } from 'react';
import { ResourceModal } from '../../ui';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { hideResourceModal, showResourceModal } from '../../ui/actions';
import { Resource } from '../index';
import { selectActiveResourceModal } from '../../ui/selectors/resourceModal';

export function useResourceModal() {
  const dispatch = useDispatch();
  const openModal = useCallback(
    (id: ResourceModal, resource: Resource) => {
      dispatch(
        showResourceModal({
          id,
          resource
        })
      );
    },
    [dispatch]
  );
  const closeModal = useCallback(() => {
    dispatch(hideResourceModal());
  }, [dispatch]);

  const { resource, id } = useSelector(selectActiveResourceModal, shallowEqual);

  return {
    openModal,
    closeModal,
    activeModalId: id,
    resource
  };
}
