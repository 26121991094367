interface DirsConfig {
  types: {
    [key: string]: string;
  };
  allTypes: {
    [key: string]: string;
  };
}

export default {
  types: {
    general: 'General',
    'inherited-type': 'Inherited Type',
  },
  allTypes: {
    general: 'General',
    'object-type': 'Object Type',
    'unique-object': 'Object ID',
    'inherited-type': 'Inherited Type',
  },
} as DirsConfig;
