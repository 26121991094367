import React from 'react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import FormControl from 'components/v2/FormControl/FormControl';
import FileInput from '../../FileInput/FileInput';
import { AdapterProps } from '../types';
import InputLabel from '../../InputLabel/InputLabel';
import css from '../../Filters/filters.module.scss';
import { useFormError } from '../utils';
import FormError from '../../FormError/FormError';

export type FileAdapterProps = AdapterProps['file'];

const FileAdapter = (props: FileAdapterProps) => {
  const { label, uploadLabel, name, multiple, rules, accept } = props;
  const { formState, control } = useFormContext();
  const error = useFormError(get(formState.errors, name));

  return (
    <FormControl>
      {label && <InputLabel className={css.label}>{label}</InputLabel>}
      <Controller
        render={({ field: { onChange, disabled } }) => (
          <FileInput
            accept={accept}
            disabled={disabled}
            onChange={onChange}
            multiple={multiple}
            label={uploadLabel}
          />
        )}
        control={control}
        name={name}
        rules={rules}
      />
      <FormError error={error} />
    </FormControl>
  );
};

export default FileAdapter;
