import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SwipeableViews from 'react-swipeable-views';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/material';
import get from 'lodash/get';
import { Formik, Form, Field, FieldArray, FormikErrors } from 'formik';
import { useTranslation } from 'utils/translation';
import Button from '@mui/material/Button';
import {
  FieldUpdatePayload,
  Field as FieldType,
  FieldGroup,
} from 'modules/intelligent-layer';
import { FormField } from './FormFields/FormField';
import { Alert } from 'components';
import ConditionalFieldChecker from './ConditionalFieldChecker';
import { applyFilters, Filters } from '../../../core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 0),
  },
  alert: {
    marginBottom: theme.spacing(1),
  },
}));

interface FormValues {
  fields: FieldType[];
  extra?: object;
}

const useFieldValidator = (field: FieldType) => {
  const { t } = useTranslation();
  const { settings = {} } = field;

  return (value: string) => {
    if (settings.required && !value) {
      return t('Required');
    }
  };
};

const FieldWrapper = (props: any) => {
  return (
    <FormField
      errorMessage={props.error}
      form={props.form}
      type={props.fieldType}
      settings={props.settings}
      name={props.field.name}
      fieldName={props.fieldName}
      multiple={props.multiple}
      value={props.field.value}
      onChange={props.field.onChange}
    />
  );
};

interface RowProps {
  field: FieldType;
  name: string;
  error: string | null;
}

const useFieldRowStyles = makeStyles((theme: Theme) => ({
  groupTitleCell: {
    border: 0,
    fontWeight: 'bold',
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

const FormFieldRow: React.FC<RowProps> = ({ field, name, error }) => {
  const classes = useFieldRowStyles();
  const validator = useFieldValidator(field);
  const isMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const label = (
    <Typography>{get(field, 'settings.label', field.name)}</Typography>
  );

  const title = field.settings.title ? (
    <TableRow>
      <TableCell className={classes.groupTitleCell} colSpan={2}>
        <Typography className={classes.title} variant="h5">
          {field.settings.title}
        </Typography>
      </TableCell>
    </TableRow>
  ) : null;

  const formField = (
    <Field
      error={error}
      validate={validator}
      fieldType={field.type}
      fieldName={field.name}
      multiple={field.multiple}
      settings={field.settings}
      name={name}
      component={FieldWrapper}
    />
  );

  if (isMdUp) {
    return (
      <>
        {title}
        <TableRow>
          <TableCell>{label}</TableCell>
          <TableCell>{formField}</TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      {title}
      <TableRow>
        <TableCell colSpan={2}>
          <div>{label}</div>
          <div>{formField}</div>
        </TableCell>
      </TableRow>
    </>
  );
};

const FieldsTable = (props: {
  errors: FormikErrors<FormValues>;
  values: FormValues;
  group?: FieldGroup;
}) => {
  const { values, errors, group = null } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableBody>
        <FieldArray
          name="fields"
          render={() =>
            values.fields.map((field, index) => {
              if (
                group !== null &&
                field.group &&
                field.group.id !== group.id
              ) {
                return null;
              }

              return (
                <ConditionalFieldChecker
                  values={values}
                  field={field}
                  key={field.id}
                >
                  <FormFieldRow
                    field={field}
                    name={`fields.${index}.value`}
                    error={get(errors, `fields.${index}.value`)}
                  />
                </ConditionalFieldChecker>
              );
            })
          }
        />
      </TableBody>
    </Table>
  );
};

interface Props {
  error: any;
  fields: any[];
  onSubmit(payload: FieldUpdatePayload): void;
  isSubmitting: boolean;
  onCancel(): void;
}

const FieldsForm: React.FC<Props> = ({
  error,
  fields,
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const classes = useStyles();
  const [index, setIndex] = React.useState(0);
  const { t } = useTranslation();

  const resolveValue = (value: object | string | any) => {
    if (value && typeof value === 'object' && 'value' in value) {
      return value.value;
    }

    return value;
  };

  const handleSubmit = ({ fields, extra = {} }: FormValues) => {
    const payload: FieldUpdatePayload = {};

    fields.forEach((field) => {
      payload[field.name] = resolveValue(field.value);
    });

    onSubmit({
      ...extra,
      ...payload,
    });
  };

  const initialValues = applyFilters(Filters.intelligenceLayer.initialValues, {
    fields,
  });

  const groups = Object.values(
    Array.from(initialValues.fields).reduce((prev, current) => {
      if (current.group) {
        prev[current.group.id] = current.group;
      }

      return prev;
    }, {}),
  );

  return (
    <div className={classes.root}>
      {error && (
        <Alert className={classes.alert} variant="error" message={error} />
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        render={({ values, errors }) => (
          <Form>
            {groups.length > 1 && (
              <Tabs
                value={index}
                variant="scrollable"
                onChange={(event, i) => setIndex(i)}
              >
                {groups.map((item: any) => (
                  <Tab key={item.id} label={item.name} />
                ))}
              </Tabs>
            )}
            {groups.length > 1 ? (
              // @ts-ignore
              <SwipeableViews
                disableLazyLoading
                axis={'x'}
                index={index}
                onChangeIndex={(i) => setIndex(i)}
              >
                {groups.map((group: any) => (
                  <FieldsTable
                    key={group.id}
                    values={values}
                    errors={errors}
                    group={group}
                  />
                ))}
              </SwipeableViews>
            ) : (
              <FieldsTable values={values} errors={errors} />
            )}
            <Table>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Button
                      onClick={onCancel}
                      type="button"
                      variant="contained"
                    >
                      {t('Cancel')}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {t('Save')}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Form>
        )}
      />
    </div>
  );
};

export default FieldsForm;
