import React from 'react';
import classNames from 'classnames';
import css from './icon.module.scss';

type Props = {
  name: string;
  className?: string;
  color?: 'success' | 'warning' | 'error' | 'info' | 'primary';
};

const Icon: React.FC<Props> = (props) => {
  const {name, className, color} = props;

  return (
    <i
      className={classNames(
        name,
        className,
        css.root,
        color && css[`color-${color}`]
      )}
    />
  )
};

export default Icon;
