import http from 'utils/http';
import Listener from '../2fa/listener';

type LoginArgs = {
  email: string;
  password: string;
};

export async function login({ email, password }: LoginArgs) {
  const response = await http.post<any, any>('/token', {
    username: email,
    password: password,
  });

  if (response.error === '2FA_REQUIRED') {
    return Listener.requestTwoFactorAuth(response.provider || 'email');
  }

  return response;
}

export function resend2faCode() {
  return http.post('/token/2fa-resend');
}

export function fetchCurrentUser() {
  return http.get('api/user/me');
}

export function requestPasswordReset({ email }: { email: string }) {
  return http.post('auth/password/reset', {
    email,
  });
}

export function verifyResetPasswordToken({ token }: { token: string }) {
  return http.post('auth/password/verify-token', {
    token,
  });
}

export function changePasswordWithToken({
  token,
  password,
}: {
  token: string;
  password: string;
}) {
  return http.post('auth/password/change', {
    token,
    password,
  });
}
