import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Dir } from '../index';
import { useQuery } from 'react-query';
import http from 'utils/http';
import { useTranslation } from 'utils/translation';
import {
  List,
  ListItem,
  Box,
  Typography,
  FormControl,
  TextField,
  Chip,
  FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import FolderIcon from '@mui/icons-material/Folder';
import { Theme } from '../../../theme';
import ReadonlyBreadcrumbs from '../../../views/MyDisk/components/Breadcrumbs/ReadonlyBreadcrumbs';
import InputAdornment from '@mui/material/InputAdornment';
import { useDirsSearch } from '../hooks';
import debounce from 'lodash/debounce';
import { Loader } from '../../../components';
import castArray from 'lodash/castArray';
import HighlightText from 'components/Highlight/HighlightText';

const useStyles = makeStyles((theme: Theme) => ({
  item: {},
  selected: {
    fontWeight: 'bold',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
}));

type ItemProps = {
  label?: React.ReactNode;
  onClick?(item: Pick<Dir, 'title' | 'id'>): void;
  isSelected(item: Pick<Dir, 'id'>): boolean;
  expanded?: boolean;
  filter?(item: Pick<Dir, 'id'>): boolean;
};

const DirectoryListItem: React.FC<
  Pick<Dir, 'id' | 'title' | 'childrenCount'> & ItemProps
> = ({
  id,
  title,
  childrenCount,
  onClick,
  isSelected,
  expanded = false,
  filter,
  label,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(expanded);

  const handleItemClick = () => {
    onClick && onClick({ id, title });
  };

  return (
    <>
      <ListItem dense>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box
            className={classes.item}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              onClick={() => setOpen(!open)}
            >
              {childrenCount > 0 ? (
                <>
                  {open ? (
                    <KeyboardArrowDown fontSize="small" />
                  ) : (
                    <KeyboardArrowRight fontSize="small" />
                  )}
                </>
              ) : (
                <div style={{ width: 20 }} />
              )}
            </Box>
            <Typography
              className={clsx({
                [classes.title]: true,
                [classes.selected]: isSelected({ id }),
              })}
              variant="body2"
              onClick={handleItemClick}
            >
              <FolderIcon className={classes.icon} />
              {label || title}
            </Typography>
          </Box>
          <Box>
            {open && (
              <DirectoryList
                parentId={id}
                onItemClick={onClick}
                isItemSelected={isSelected}
                filter={filter}
              />
            )}
          </Box>
        </Box>
      </ListItem>
    </>
  );
};

const DirectoryList: React.FC<{
  parentId?: Dir['id'];
  onItemClick: ItemProps['onClick'];
  isItemSelected: ItemProps['isSelected'];
  filter: ItemProps['filter'];
}> = ({ parentId, onItemClick, isItemSelected, filter }) => {
  const { data, isLoading } = useQuery(
    `app_directories_${parentId || 'root'}`,
    () => {
      return http.get<never, { data: Dir[] }>('/api/directory/', {
        params: {
          perPage: 1000,
          parentId,
        },
      });
    },
    {
      refetchInterval: false,
    },
  );

  if (isLoading) {
    return null;
  }

  let items = (data && data.data) || [];

  if (filter) {
    items = items.filter(filter);
  }

  return (
    <List>
      {items.map((item) => (
        <DirectoryListItem
          {...item}
          key={item.id}
          onClick={onItemClick}
          isSelected={isItemSelected}
          filter={filter}
        />
      ))}
    </List>
  );
};

type Props = {
  placeholder?: string;
  value?: Nullable<Pick<Dir, 'id'>>;
  onChange?(dir: Nullable<Dir>): void;
  filter?(dir: Dir): boolean;
  error?: boolean;
  fullWidth?: boolean;
  expanded?: boolean;
};

const DirectoryPicker: React.FC<Props> = ({
  value,
  onChange,
  filter,
  error,
  fullWidth = true,
  expanded = false,
}) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(expanded);
  const selectedId = (value && value.id) || '';
  const [query, setQuery] = useState('');
  const searchQuery = useDirsSearch({ title: query });
  const shouldSearch = query && query.length > 2;

  useEffect(() => {
    if (shouldSearch) {
      searchQuery.refetch();
    }
  }, [query, searchQuery.refetch, shouldSearch]);

  const onSearchInputChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setQuery(event.target.value);
    }, 200),
    [setQuery],
  );

  const handleSelectedDirChange = (dir: Dir | any) => {
    if (!onChange) {
      return;
    }

    if (dir) {
      onChange(dir as Dir);
    } else {
      onChange(null);
    }

    setActive(false);
    searchQuery.clear();
    setQuery('');
  };

  const isItemSelected = (item: Dir) => {
    return Boolean(value && Number(item.id) === Number(value.id));
  };

  if (!active) {
    return (
      <FormControl
        error={error}
        fullWidth={fullWidth}
        onClick={() => setActive(true)}
      >
        <Chip label={<ReadonlyBreadcrumbs id={Number(selectedId)} />} />
      </FormControl>
    );
  }

  return (
    <Box>
      <FormControl fullWidth sx={{ mb: 1 }}>
        <TextField
          size="small"
          name="query"
          onChange={onSearchInputChange}
          InputProps={{
            type: 'search',
            autoFocus: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText>{t('search_help_text_min_length')}</FormHelperText>
      </FormControl>
      <List>
        {shouldSearch ? (
          <>
            {searchQuery.isFetching ? (
              <Loader />
            ) : (
              <>
                {searchQuery.isSuccess &&
                castArray(searchQuery.data).length > 0 ? (
                  (searchQuery.data || []).map((item) => (
                    <DirectoryListItem
                      id={item.id}
                      title={item.title}
                      label={
                        <HighlightText
                          text={
                            Array.from(item.path || [])
                              .map((i) => i.title)
                              .join(' / ') || item.title
                          }
                          highlight={query}
                        />
                      }
                      childrenCount={item.childrenCount}
                      onClick={handleSelectedDirChange}
                      isSelected={isItemSelected}
                      filter={filter}
                    />
                  ))
                ) : (
                  <Typography
                    variant="caption"
                    component="p"
                    sx={{ py: 1 }}
                    align="center"
                  >
                    {t('search_no_results')}
                  </Typography>
                )}
              </>
            )}
          </>
        ) : (
          <DirectoryListItem
            id={0}
            title={t('My Disk')}
            childrenCount={1}
            onClick={handleSelectedDirChange}
            isSelected={isItemSelected}
            expanded={expanded}
            filter={filter}
          />
        )}
      </List>
    </Box>
  );
};

export default DirectoryPicker;
