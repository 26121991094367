import { RefObject, useEffect, useState } from 'react';

const useInputCopyToClipboard = (ref: RefObject<HTMLInputElement>) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timeout: any;
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [copied]);

  const onCopy = () => {
    if (ref.current) {
      ref.current.select();
      document.execCommand('copy');
      setCopied(true);
      ref.current.blur();
    }
  };

  return [onCopy, copied];
};

export default useInputCopyToClipboard;
