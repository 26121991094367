import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';
import { useTreeItem2Utils } from '@mui/x-tree-view/hooks';
import { UseTreeItem2ContentSlotOwnProps } from '@mui/x-tree-view/useTreeItem2';
import {
  unstable_useTreeItem2 as useTreeItem2,
  UseTreeItem2Parameters,
} from '@mui/x-tree-view/useTreeItem2';
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2GroupTransition,
  TreeItem2Label,
  TreeItem2Root,
  TreeItem2Checkbox,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import Icon from '../Icon/Icon';
import css from './tree-view.module.scss';

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, 'rootRef'>,
    Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> {
  onItemExpand?(event: any, itemId: string): void;
  isPlaceholder?: boolean;
}

const TreeItem = React.forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>,
) {
  const {
    id,
    itemId,
    label,
    isPlaceholder = false,
    disabled,
    onItemExpand,
    children,
    ...rest
  } = props;

  const { interactions } = useTreeItem2Utils({
    itemId: props.itemId,
    children: props.children,
  });

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({
    id,
    itemId,
    children,
    label,
    disabled,
    rootRef: ref,
  });

  const handleContentClick: UseTreeItem2ContentSlotOwnProps['onClick'] = (
    event,
  ) => {
    event.defaultMuiPrevented = true;
    interactions.handleSelection(event);
  };

  const handleIconContainerClick = (event: React.MouseEvent) => {
    interactions.handleExpansion(event);
    event.stopPropagation();

    if (!status.expanded) {
      onItemExpand?.(event, itemId);
    }

    return false;
  };

  const rootProps = getRootProps(rest);
  const contentProps = getContentProps();
  const labelProps = getLabelProps();

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...rootProps} className={css.item}>
        <TreeItem2Content
          {...contentProps}
          onClick={handleContentClick}
          className={clsx(css.content, {
            [css.expanded]: status.expanded,
            [css.disabled]: status.disabled,
            [css.expandable]: status.expandable,
            [css.focused]: status.focused,
            [css.selected]: status.selected,
          })}
        >
          <TreeItem2IconContainer
            {...getIconContainerProps()}
            onClick={handleIconContainerClick}
          >
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <div className={css.labelWrapper}>
            {isPlaceholder ? (
              <>
                <Icon name="fa-solid fa-folder" />
                <Skeleton width={120} height={21} />
              </>
            ) : (
              <>
                <Icon name="fa-solid fa-folder" />
                <TreeItem2Checkbox {...getCheckboxProps()} />
                <TreeItem2Label
                  title={String(labelProps.children)}
                  className={css.label}
                  {...labelProps}
                />
              </>
            )}
          </div>
        </TreeItem2Content>
        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default TreeItem;
