/**
 * Transforms data to store it in Redux
 *
 * @param data
 * @param key
 */
export function transform(data: any = [], key = 'id') {
  const entities = {};

  data.forEach((entry: any) => {
    const id = entry[key];

    if (!id) {
      throw new Error(`Possible invalid identifier: ${key}`);
    }

    // @ts-ignore
    entities[entry[key]] = entry;
  });

  return entities;
}
