import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { Dir } from '../../dirs';
import { useDirsActions } from '../../dirs/hooks';
import { useAppFeatures } from '../../ui/selectors/app';
import { useFilesActions } from './use-files-actions';

function createDirectoryTree(
  directories: any[] = [],
  files: any,
  hasTranslatableDirs: boolean,
): any {
  return Array.from(directories)
    .filter((item) => Array.isArray(item))
    .map(([item, children = []]) => ({
      title: hasTranslatableDirs
        ? {
            nl: item.name,
            fr: item.name,
            en: item.name,
          }
        : item.name,
      name: item.name,
      path: item.path,
      files: files.filter((file: any) => {
        const filePath = file.path.replace(`/${file.name}`, '');

        return filePath === item.path;
      }),
      children: createDirectoryTree(children, files, hasTranslatableDirs),
    }));
}

export const useUploadViaDrop = (currentDirId: Dir['id'] | null) => {
  const { createDir } = useDirsActions();
  const { translatableDirectories } = useAppFeatures();
  const { createFile, addToUploadQueue } = useFilesActions();

  return useCallback(
    (acceptedFiles: File[] | any[]) => {
      const queuedFiles = acceptedFiles
        .filter((item) => item instanceof File)
        .map((file) => {
          // @ts-ignore
          file.id = uuid();
          return file;
        });

      addToUploadQueue(queuedFiles);

      const acceptedDirs = acceptedFiles.filter((item) => Array.isArray(item));

      const directories = createDirectoryTree(
        acceptedDirs,
        queuedFiles,
        translatableDirectories,
      );
      directories.map((dir: any) =>
        createDir({
          ...dir,
          parent: currentDirId,
        }),
      );

      // @ts-ignore
      const rootFiles = queuedFiles.filter((file) => file.name === file.path);
      rootFiles.map((file: any) =>
        createFile({
          file,
          dirId: currentDirId,
        }),
      );
    },
    [currentDirId, addToUploadQueue, createDir, createFile],
  );
};
