import React from 'react';
import TextInput from 'components/v2/Input/TextInput';
import TextAdapter from './TextAdapter';
import { AdapterProps } from '../types';

export type TextAdapterProps = AdapterProps['text'] &
  React.ComponentProps<typeof TextInput>;

const LongTextAdapter = (props: TextAdapterProps) => {
  return <TextAdapter minRows={2} {...props} multiline />;
};

export default LongTextAdapter;
