import { handleActions } from 'redux-actions';
import { createPaginationActionTypes } from '../actions/pagination-actions';

export interface PaginationState {
  items: number[];
  page: number;
  perPage: number;
  total: Nullable<number>;
  isFetching: boolean;
}

const initialState: PaginationState = {
  items: [],
  page: 1,
  perPage: 10,
  total: null,
  isFetching: false
};

export type PaginationReducer = ReturnType<typeof createPaginationReducer>;

export function createPaginationReducer(prefix: string) {
  const Types = createPaginationActionTypes(prefix);

  return handleActions(
    {
      [Types.CHANGE_PAGE]: (state, action) => ({
        ...state,
        page: action.payload.page
      }),
      [Types.CHANGE_PER_PAGE]: (state, action) => ({
        ...state,
        perPage: action.payload.perPage
      }),
      [Types.FETCH.REQUEST]: state => ({
        ...state,
        isFetching: true
      }),
      [Types.FETCH.SUCCESS]: (state, action) => ({
        ...state,
        ...action.payload
      }),
      [Types.FETCH.FULFILL]: state => ({
        ...state,
        isFetching: false
      })
    },
    initialState
  );
}
