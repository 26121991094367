import React, { useCallback } from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  TableCell,
  TableRow,
  Theme,
  Typography,
  TableBody,
  Table,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, FieldArray, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'utils/translation';
import Box from '@mui/material/Box';
import {
  Field as FieldType,
  FieldUpdatePayload,
} from '../../../../../modules/intelligent-layer';
import { FormField } from 'modules/intelligent-layer/components/FormFields/FormField';
import get from 'lodash/get';
import { useIntelligenceFields } from '../../../../../modules/intelligent-layer/hooks';
import {
  useCreateNewObjectType,
  useCreateNewObjectTypeMultiLang,
} from '../../../../../modules/dirs/hooks';
import { useNavigate } from '../../../../../utils/router';
import { Loader } from '../../../../../components';
import { useAppFeatures } from '../../../../../modules/ui/selectors/app';
import { useAppSettings } from '../../../../../modules/ui/hooks';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { updateIntelligentFields } from '../../../../../modules/intelligent-layer/service/api';

interface RowProps {
  field: FieldType & { choices?: any[] };
  name: string;
  error: string | null | undefined;
  choices?: any;
  onChange?: ((event: any) => void) | undefined;
}

const useFieldValidator = (field: FieldType) => {
  const { t } = useTranslation();
  const { settings = {} } = field;

  return () => {
    if (settings.required) {
      return t('Required');
    }
  };
};

const FieldWrapper = (props: any) => {
  let settings = {
    options: props.choices.map((choice: string) => ({
      label: choice,
      value: choice,
    })),
    ...props.settings,
  };
  let disabled = false;

  return (
    <FormField
      errorMessage={props.error}
      form={props.form}
      type={props.fieldType}
      settings={settings}
      disabled={disabled}
      name={props.field.name}
      fieldName={props.fieldName}
      multiple={props.multiple}
      value={props.field.value}
      onChange={props.onChange || props.field.onChange}
    />
  );
};

const FormFieldRow: React.FC<RowProps> = ({ field, name, error, onChange }) => {
  const validator = useFieldValidator(field);

  const label = (
    <Typography>{get(field, 'settings.label', field.name)}</Typography>
  );

  const formField = (
    <Field
      error={error}
      validate={validator}
      fieldType={field.type}
      fieldName={field.name}
      multiple={field.multiple}
      settings={field.settings}
      choices={field.choices}
      onChange={onChange}
      name={name}
      component={FieldWrapper}
    />
  );

  return (
    <TableRow>
      <TableCell padding="none" style={{ borderBottom: 'none' }}>
        <Box my={1}>
          <Box mb={1}>{label}</Box>
          <Box>{formField}</Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0,
  },
}));

export interface FormValues {
  title: string;
  cover?: File;
  fields: FieldType[];
}

export interface SubmitPayload {
  title:
    | string
    | {
        [key: string]: string;
      };
  cover?: File;
  fields: {
    [key: string]: any;
  };
}

interface OtherProps {
  onCancel: () => void;
  initialValues?: Partial<FormValues>;
}

const buildValidationSchema = (multiLanguage: boolean, locales: string[]) => {
  if (multiLanguage) {
    return Yup.object().shape({
      title: Yup.object().shape(
        Object.fromEntries(
          locales.map((item) => [item, Yup.string().required('Required')]),
        ),
      ),
      cover: Yup.string().nullable(),
    });
  }

  return Yup.object().shape({
    title: Yup.string().required('Required'),
    cover: Yup.string().nullable(),
  });
};

const TitleField: React.FC<{
  name: string;
  label: string;
  error?: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  touched: boolean;
}> = ({ name, label, error, value, onChange, touched }) => {
  const { t } = useTranslation([]);

  return (
    <Grid item xs={12}>
      <Box pb={2}>
        <FormControl error={Boolean(error)} fullWidth>
          <InputLabel>{label}</InputLabel>
          <Input
            name={name}
            value={value}
            onChange={onChange}
            autoComplete="off"
          />
          {error && touched && <FormHelperText>{t(`${error}`)}</FormHelperText>}
        </FormControl>
      </Box>
    </Grid>
  );
};

const BaseObjectTypeForm = (props: OtherProps) => {
  const { onCancel, initialValues = {} } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data = [], isSuccess } = useIntelligenceFields(['object-type']);
  const [mutate] = useCreateNewObjectType();
  const [mutateMultiLang] = useCreateNewObjectTypeMultiLang();
  const [index, setIndex] = React.useState(0);
  const { translatableDirectories } = useAppFeatures();
  const { settings } = useAppSettings();
  const hasTabs =
    translatableDirectories && (settings.locales || []).length > 0;

  const onSubmit = useCallback(
    async (values: SubmitPayload) => {
      const mutation = translatableDirectories ? mutateMultiLang : mutate;
      // @ts-ignore
      const response = await mutation(values);

      if (values.fields && response?.id) {
        await updateIntelligentFields({
          dirId: response.id,
          fields: values.fields,
        });
      }

      onCancel();

      return response;
    },
    [mutate, onCancel, mutateMultiLang, translatableDirectories],
  );

  const handleSubmit = React.useCallback(
    async (values: FormValues) => {
      const fieldsPayload: FieldUpdatePayload = {};

      values.fields.forEach((field) => {
        fieldsPayload[field.name] = field.value;
      });

      const payload = {
        title: values.title,
        cover: values.cover,
        fields: fieldsPayload,
      };

      const response = await onSubmit(payload);

      if (response && response.id) {
        navigate(`/directory/${response.id}`);
      }
    },
    [onSubmit, navigate],
  );

  if (!isSuccess) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  const getTitleErrorMessage = (errors: object, locale: string) => {
    if (get(errors, `title.${locale}`)) {
      return get(errors, `title.${locale}`);
    }

    if (Object.keys(get(errors, 'title') || {}).length > 0) {
      return 'required_translation';
    }

    return null;
  };

  return (
    <Formik
      validationSchema={() => buildValidationSchema(hasTabs, settings.locales)}
      initialValues={{
        fields: data as FieldType[],
        title: initialValues.title || '',
        cover: undefined,
      }}
      onSubmit={handleSubmit}
      render={({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleChange,
      }) => (
        <Form>
          <Typography align="center" gutterBottom variant="h3">
            {t('Create Object Type')}
          </Typography>
          {hasTabs ? (
            <Box>
              <Tabs
                value={index}
                variant="scrollable"
                onChange={(event, i) => setIndex(i)}
              >
                {settings.locales.map((item: string) => (
                  <Tab key={item} label={item} />
                ))}
              </Tabs>
              {/* @ts-ignore */}
              <SwipeableViews
                disableLazyLoading
                axis={'x'}
                index={index}
                onChangeIndex={(i) => setIndex(i)}
              >
                {settings.locales.map((locale: any) => (
                  <React.Fragment key={locale}>
                    <Grid className={classes.container} container>
                      <TitleField
                        key={locale}
                        name={`title.${locale}`}
                        value={get(values, `title.${locale}`, '')}
                        onChange={handleChange}
                        error={getTitleErrorMessage(errors, locale)}
                        label={t('Object Type Title')}
                        touched={get(touched, `title.${locale}`, false)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Table>
                        <TableBody>
                          <FieldArray
                            name="fields"
                            render={() =>
                              values.fields.map((field, index) => {
                                if (
                                  (field.group &&
                                    (field.group.name || '').toLowerCase()) ===
                                  locale
                                ) {
                                  return (
                                    <FormFieldRow
                                      key={field.id}
                                      field={field}
                                      name={`fields.${index}.value`}
                                      error={get(
                                        errors,
                                        `fields.${index}.value`,
                                      )}
                                      onChange={handleChange}
                                    />
                                  );
                                }

                                return null;
                              })
                            }
                          />
                        </TableBody>
                      </Table>
                    </Grid>
                  </React.Fragment>
                ))}
              </SwipeableViews>
            </Box>
          ) : (
            <Grid className={classes.container} container spacing={2}>
              <TitleField
                name="title"
                value={values.title}
                onChange={handleChange}
                error={errors.title}
                label={t('Object Type Title')}
                touched={!!touched.title}
              />
              <Grid item xs={12}>
                <Table>
                  <TableBody>
                    <FieldArray
                      name="fields"
                      render={() =>
                        values.fields.map((field, index) => (
                          <FormFieldRow
                            key={field.id}
                            field={field}
                            name={`fields.${index}.value`}
                            error={get(errors, `fields.${index}.value`)}
                            onChange={handleChange}
                          />
                        ))
                      }
                    />
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          )}
          <Grid className={classes.container} container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label htmlFor="contained-button-file">
                  <Input
                    style={{ display: 'none' }}
                    name="document"
                    id="contained-button-file"
                    type="file"
                    onChange={(event: any) => {
                      if (event.currentTarget && event.currentTarget.files) {
                        setFieldValue('cover', event.currentTarget.files[0]);
                      }
                    }}
                  />
                  <Box display="flex" flexDirection="column">
                    {values.cover && (values.cover as unknown as File).name && (
                      <Typography variant="caption" gutterBottom>
                        {(values.cover as unknown as File).name}
                      </Typography>
                    )}
                    <Button variant="contained" component="span">
                      {t('Cover file')}
                    </Button>
                  </Box>
                </label>
                {errors.cover && (
                  <FormHelperText>{t(`${errors.cover}`)}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <CardActions className={classes.actions}>
            <Button onClick={onCancel} variant="contained">
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
            >
              {isSubmitting ? <CircularProgress size={24} /> : t('Create')}
            </Button>
          </CardActions>
        </Form>
      )}
    />
  );
};

export const ObjectTypeForm = BaseObjectTypeForm;
