import {
  call,
  take,
  put,
  CallEffect,
  TakeEffect,
  PutEffect,
} from 'redux-saga/effects';
import { createUploadFileChannel } from './upload-channel';
import { filesActions } from '../actions';
import { QueuedFile } from '../index';

export function* handleFileUpload(
  file: File & QueuedFile,
  dirId?: number,
  onSuccess?: () => void,
  onError?: () => void,
): Generator<CallEffect | TakeEffect | PutEffect> {
  const id = file.id;
  const channel = yield call(createUploadFileChannel, file, dirId);

  yield put(filesActions.create.request({ id, file, dirId }));

  while (true) {
    const {
      // @ts-ignore
      progress = 0,
      // @ts-ignore
      error,
      // @ts-ignore
      success,
      // @ts-ignore
      response,
    } = yield take(channel as any) as any;

    if (error) {
      yield put(filesActions.create.failure({ id, file, error }));
      yield put(filesActions.create.fulfill({ id }));
      onError?.();
      return;
    }

    if (success) {
      yield put(filesActions.create.success(response));
      yield put(filesActions.create.fulfill({ id }));
      onSuccess?.();
      return;
    }

    yield put(
      filesActions.uploadProgress({
        id,
        file,
        progress,
      }),
    );
  }
}
