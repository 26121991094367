import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldArgs, AutocompleteField } from '../types';
import InputLabel from '../../InputLabel/InputLabel';
import { useFormError } from '../utils';
import FormError from '../../FormError/FormError';
import Autocomplete from '../../Autocomplete/Autocomplete';
import get from 'lodash/get';
import FormControl from '../../FormControl/FormControl';

export type AutocompleteAdapterProps = FieldArgs<AutocompleteField>;

type Value = {
  label: string;
  value: string | number;
};

const SelectCreatableAdapter = (props: AutocompleteAdapterProps) => {
  const {
    label,
    name,
    rules,
    options: defaultOptions,
    multiple = false,
  } = props;
  const {
    control,
    getValues,
    formState: { errors, defaultValues },
  } = useFormContext();
  const error = useFormError(errors[name]);
  const defaultValue = get(defaultValues, name) || (multiple ? [] : null);
  const currentValue = get(getValues(), name, []);
  const [options, setOptions] = useState(defaultOptions);

  const isOptionEqualToValue = (option: Value, value: Value) =>
    option.value === value.value;
  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field: { value, onChange, disabled } }) => (
          <Autocomplete
            value={value || defaultValue}
            onChange={(event, value) => {
              onChange(value);
            }}
            multiple={multiple}
            options={options}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionKey={(option) => option.value}
            creatable
            onCreate={(value) => {
              const newOption = {
                label: value,
                value,
              };

              if (options.find((opt) => isOptionEqualToValue(opt, newOption))) {
                return;
              }

              setOptions((prev) => [...prev, newOption]);
              onChange([...currentValue, newOption]);
            }}
          />
        )}
        control={control}
        name={name}
        rules={rules}
      />
      <FormError error={error} />
    </FormControl>
  );
};

export default SelectCreatableAdapter;
