import { RootState } from '../../store';
import { SelectUsersParams } from './index';
import { Group } from '../user-groups';

export function selectUsers(state: RootState, params: SelectUsersParams) {
  const orderIds = (state.users.order || []) as number[];
  const items = orderIds.map((id: number) => state.users.items[id]);

  if (params.search) {
    return items.filter(item =>
      [
        (item.username || '').toLowerCase(),
        (item.email || '').toLowerCase(),
        (item.firstName || '').toLowerCase(),
        (item.lastName || '').toLowerCase()
      ]
        .join('')
        .includes((params.search || '').toLowerCase())
    );
  }

  if (params.group) {
    return items.filter(item => {
      return item.groups.map((group: Group) => group.id).includes(params.group);
    });
  }

  return items;
}

export function isFetching(state: RootState) {
  return state.users.isFetching;
}

export function selectUser(state: RootState, id: number) {
  return state.users.items[id];
}

export function isUserFetching(state: RootState, id: number) {
  return state.users.pendingItemId === id;
}
