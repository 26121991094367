import React, { useContext, useMemo } from 'react';
import scheduleMicrotask from 'utils/schedule-microtask';
import wait from 'utils/wait';

type DataContextType = {
  refresh(): void;
};

const DataContext = React.createContext({
  refresh() {
    console.warn(
      'DataContextProvider not found, using default implementation, which does not refresh any data.',
    );
  },
});

export const useDataContext = () => {
  return useContext(DataContext);
};

export const DataContextProvider = ({
  refresh,
  children,
}: React.PropsWithChildren<DataContextType>) => {
  const value = useMemo(
    () => ({
      async refresh() {
        // Some Redux's actions do not return promise.
        // We have to wait a bit before we perform data refresh via the query
        await wait(200);
        scheduleMicrotask(refresh);
      },
    }),
    [refresh],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
