import React from 'react';
import 'date-fns';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { FormControl, InputLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FieldProps } from './types';
import makeStyles from '@mui/styles/makeStyles';
import dayjs, { Dayjs } from 'dayjs';

const useStyles = makeStyles(() => ({
  error: {
    transform: 'translateY(-50%)',
  },
}));

const DateField: React.FC<FieldProps> = ({
  value,
  name,
  form,
  errorMessage,
  settings = {},
}) => {
  const classes = useStyles();
  const attributes = pick(settings, [
    'disablePast',
    'disableFeature',
    'minDateMessage',
    'maxDateMessage',
  ]);
  const format = get(settings, 'format', 'DD-MM-YYYY');
  let currentValue = null;

  try {
    if (value && !Number.isNaN(+value)) {
      currentValue = dayjs.unix(+value);
    }
  } catch (e) {}

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      if (!date.isValid()) {
        return;
      }

      form.setFieldValue(name, date.startOf('day').utc().unix());
    } else {
      form.setFieldValue(name, null, false);
    }
  };

  return (
    <FormControl variant="outlined" error={!!errorMessage}>
      <DatePicker
        {...attributes}
        format={format}
        value={currentValue}
        onChange={handleDateChange}
        disabled={settings.readOnly}
      />
      {errorMessage && (
        <InputLabel className={classes.error}>{errorMessage}</InputLabel>
      )}
    </FormControl>
  );
};

export { DateField };
