import Section from 'components/v2/Section/Section';
import React from 'react';
import { useModal } from '../../../components/v2/Modal/useModal';
import { BasketTabs } from '../components/BasketTabs.modern';
import { BasketView } from '../components/BasketView';
import Spacer from 'components/v2/Spacer/Spacer';

const BasketModal = () => {
  return (
    <Section>
      <Spacer size={2} />
      <BasketTabs />
      <BasketView />
    </Section>
  );
};

const useBasketModal = () => {
  const modal = useModal();

  return () => {
    modal.open(BasketModal);
  };
};

export default useBasketModal;
