import http from '../../../utils/http';
import {
  DirGroupAccessPayload,
  DirUserAccessPayload,
  DirUserBlockedPayload
} from '../index';

export function getGroups({ dirId }: Pick<DirGroupAccessPayload, 'dirId'>) {
  return http.get(`api/directory/access/${dirId}/group`);
}

export function createGroupAccess({ dirId, groupId }: DirGroupAccessPayload) {
  return http.post(`api/directory/access/${dirId}/group/${groupId}`);
}

export function removeGroupAccess({ dirId, groupId }: DirGroupAccessPayload) {
  return http.delete(`api/directory/access/${dirId}/group/${groupId}`);
}

export function getUsers({ dirId }: Pick<DirUserAccessPayload, 'dirId'>) {
  return http.get(`api/directory/access/${dirId}/user`);
}

export function createUserAccess({ dirId, userId }: DirUserAccessPayload) {
  return http.post(`api/directory/access/${dirId}/user/${userId}`);
}

export function removeUserAccess({ dirId, userId }: DirUserAccessPayload) {
  return http.delete(`api/directory/access/${dirId}/user/${userId}`);
}

export function getBlockedUsers({
  dirId
}: Pick<DirUserBlockedPayload, 'dirId'>) {
  return http.get(`api/directory/access/${dirId}/user-blocked`);
}

export function createBlockedUser({ dirId, userId }: DirUserBlockedPayload) {
  return http.post(`api/directory/access/${dirId}/user-blocked/${userId}`);
}

export function removeBlockedUser({ dirId, userId }: DirUserBlockedPayload) {
  return http.delete(`api/directory/access/${dirId}/user-blocked/${userId}`);
}
