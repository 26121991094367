import { createAction, createType } from '../../@common/actions/action';
import { SET_PAGINATION_PER_PAGE } from '../constants/pagination';

type SetPerPagePayload = {
  perPage: number;
};

export const setPerPage = createAction<SetPerPagePayload>(
  SET_PAGINATION_PER_PAGE
);

export const PaginationActionTypes = {
  SET_PAGINATION_PER_PAGE: createType(SET_PAGINATION_PER_PAGE)
};
