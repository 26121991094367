import React from 'react';
import { ResultsProps } from './types';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import GridItem from './GridItem';
import { Resource } from '../../../../modules/resources';
import { Selection } from '../../../../utils/use-selection';
import { Theme } from '../../../../theme';
import { useMediaQuery } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: 75,
  },
  item: {
    [theme.breakpoints.up('lg')]: {
      width: 200,
      maxWidth: 200,
    },
  },
}));

type ExtraProps = {
  showResourcePath?: boolean;
};

const GridView = (props: ResultsProps & Selection<Resource> & ExtraProps) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  const {
    className,
    resources = [],
    onDeleteMany,
    onClick,
    onDoubleClick,
    onItemRightClick,
    selectedResource,

    selectAll,
    deselectAll,
    selectOne,
    setLastSelected,
    resetLastSelected,
    selectedItems,
    isSelected,
    showResourcePath,

    ...rest
  } = props;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={isDesktop ? 2 : 1}>
        {resources.map((item) => (
          <Grid
            key={`${item.type}_${item.id}`}
            item
            xs={6}
            sm={4}
            md={3}
            className={classes.item}
          >
            <GridItem
              resource={item}
              isSelected={isSelected(item)}
              selectedResource={selectedResource}
              onClick={(event: React.MouseEvent) => onClick(item, event)}
              onDoubleClick={(event: React.MouseEvent) =>
                onDoubleClick(item, event)
              }
              onContextMenu={onItemRightClick}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export { GridView };
