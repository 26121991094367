import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      fontSize: '12px',
      color: palette.text.secondary,
    },
  },
};
