import clsx from 'clsx';
import React from 'react';
import { Transition } from 'react-transition-group';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useSnackbar } from '@mui/base/useSnackbar';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import css from './snackbar.module.scss';
import { SnackVariant } from './types';

type Props = {
  title?: string;
  message: string | React.ReactNode;
  variant?: SnackVariant;
  action?: React.ReactNode;
  autoHideDuration?: number | null;
  hideOnClickAway?: boolean;
  showCloseButton?: boolean;
};

const Icons: Record<SnackVariant, React.ReactNode> = {
  info: <Icon className={css.icon} name="fa-regular fa-info-circle" />,
  error: <Icon className={css.icon} name="fa-regular fa-times-circle" />,
  warning: (
    <Icon className={css.icon} name="fa-regular fa-triangle-exclamation" />
  ),
  success: <Icon className={css.icon} name="fa-regular fa-check-circle" />,
};

const Snackbar = (props: Props) => {
  const {
    title,
    message,
    variant = 'info',
    action = null,
    autoHideDuration = 5000,
    hideOnClickAway = true,
    showCloseButton = false,
  } = props;
  const [open, setOpen] = React.useState(true);
  const nodeRef = React.useRef(null);

  const onClose = () => {
    setOpen(false);
  };

  const { getRootProps, onClickAway } = useSnackbar({
    onClose,
    open,
    autoHideDuration,
  });

  return (
    <ClickAwayListener onClickAway={hideOnClickAway ? onClickAway : () => {}}>
      {/* @ts-ignore */}
      <Transition
        timeout={{ enter: 400, exit: 400 }}
        in={open}
        appear
        unmountOnExit
        nodeRef={nodeRef}
      >
        {(status: string) => (
          <div
            {...getRootProps()}
            className={clsx(
              css.root,
              variant && css[variant],
              css[`state-${status}`],
            )}
            ref={nodeRef}
          >
            <div className={css.icon}>{Icons[variant]}</div>
            <div className={css.message}>
              {title && <p className={css.title}>{title}</p>}
              <div className={css.description}>{message}</div>
            </div>
            {action && <div className={css.action}>{action}</div>}
            {showCloseButton && (
              <Button
                color="inherit"
                onClick={onClose}
                className={css['close-button']}
                startIcon={<Icon name={'fas fa-times'} />}
                variant="text"
              />
            )}
          </div>
        )}
      </Transition>
    </ClickAwayListener>
  );
};

export default Snackbar;
