import React from 'react';
import { useModal } from '../../../components/v2/Modal/useModal';
import FluentForm from '../../../components/v2/Form/FluentForm';
import Section from '../../../components/v2/Section/Section';
import { File } from '..';
import Form from '../../../components/v2/Form/Form';
import { FieldType } from '../../../components/v2/Form/types';
import { refetchActiveQueries } from '../../../utils/QueryClientProvider';
import { updateFile } from '../service/api';
import { useTranslation } from 'utils/translation';

type Values = {
  filename: string;
};

type Options = {
  onSuccess?(): void;
};

function useRenameFile({ id, filename, ...file }: File, options: Options = {}) {
  const { t } = useTranslation();
  const modal = useModal();
  const onSubmit = async ({ filename }: Values) => {
    await updateFile({ ...file, id, filename });
    void refetchActiveQueries();
  };

  const Component = () => {
    const onSuccess = () => {
      modal.close(Component);
      options?.onSuccess && options?.onSuccess();
    };

    return (
      <Section>
        <FluentForm<Values>
          onSuccess={onSuccess}
          action={onSubmit}
          defaultValues={{
            filename,
          }}
        >
          <Form.Field
            name="filename"
            type={FieldType.Text}
            label={t('Filename')}
            rules={{ required: true }}
          />
        </FluentForm>
      </Section>
    );
  };

  return () => {
    modal.open(Component, { size: 'small' });
  };
}

export default useRenameFile;
