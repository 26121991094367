import { queryCache, useQuery } from 'react-query';
import http from '../../../utils/http';
import { Report } from '../types';
import i18n, { useTranslation } from 'utils/translation';

const useReports = () => {
  const { i18n } = useTranslation();

  return useQuery(`api_available_reports_${i18n.language}`, () =>
    http.get<never, Array<Report>>('/api/reports'),
  );
};

export const refetchReports = () => {
  return queryCache.refetchQueries(`api_available_reports_${i18n.language}`);
};

export default useReports;
