import { get } from 'lodash';

export default Object.freeze({
  myDiskPath: '/database',
  isTestEnv: get(process.env, 'REACT_APP_IS_TEST_ENV', false),
  isStorybookEnv: Boolean(process.env.STORYBOOK_ENV),
  googleAnalyticsId: get(process.env, 'REACT_APP_GA_MEASUREMENT_ID', null),

  enableObjectTypes: Boolean(
    +get(process.env, 'REACT_APP_ENABLE_OBJECT_TYPES', true),
  ),
  enableNewUiSwitcher: Boolean(
    +get(process.env, 'REACT_APP_ENABLE_NEW_UI_SWITCHER', false),
  ),

  enableAssetsModule: Boolean(
    +get(process.env, 'REACT_APP_ENABLE_ASSETS', true),
  ),
});
