import React from 'react';
import get from 'lodash/get';
import TextInput from 'components/v2/Input/TextInput';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import FormControl from 'components/v2/FormControl/FormControl';
import { AdapterProps } from '../types';
import InputLabel from '../../InputLabel/InputLabel';
import css from '../../Filters/filters.module.scss';
import DatePicker from '../../DatePicker/DatePicker';
import { safeParse, toUTCTimestamp } from 'utils/date';
import { useFormError } from '../utils';
import FormError from '../../FormError/FormError';
import { Stack } from '@mui/material';

export type DateAdapterProps = AdapterProps['date'] &
  React.ComponentProps<typeof TextInput>;

const DateAdapter = (props: DateAdapterProps) => {
  const { label, name, rules } = props;
  const { formState, control } = useFormContext();
  const defaultValue = get(formState.defaultValues, name);
  const error = useFormError(get(formState.errors, name));

  const parsedDefaultValue = React.useMemo(() => {
    return safeParse(defaultValue);
  }, [defaultValue]);

  return (
    <FormControl defaultValue={defaultValue}>
      <InputLabel className={css.label}>{label}</InputLabel>
      <Stack direction="row" alignItems="flex-end">
        <Controller
          render={({ field: { onChange, disabled } }) => (
            <DatePicker
              disabled={disabled}
              format={dayjs.Ls[dayjs.locale()].formats.L}
              defaultValue={parsedDefaultValue}
              onChange={(date: Dayjs | any) => {
                void onChange?.(toUTCTimestamp(date));
              }}
            />
          )}
          control={control}
          name={name}
          rules={rules}
        />
        <FormError error={error} />
      </Stack>
    </FormControl>
  );
};

export default DateAdapter;
