import React from 'react';
import { useNavigate } from 'react-router-dom';
import FixedBottomAction from '../../../components/v2/ContextBar/FixedBottomAction/FixedBottomAction';
import { DeviceAttributesVertical } from '../../../components/v2/DeviceDetails/DeviceAttributes/DeviceAttributes';
import Section from '../../../components/v2/Section/Section';
import Spacer from '../../../components/v2/Spacer/Spacer';
import { useTranslation } from 'utils/translation';
import { useReportContext } from '../../reports/context/ReportContext';
import Icon from 'components/v2/Icon/Icon';

export const DeviceDetailsContextBar = ({ id }: { id: number }) => {
  const { t } = useTranslation();
  const { id: reportId } = useReportContext();

  return (
    <Section>
      <DeviceAttributesVertical id={id}>
        <Spacer size={2} />
      </DeviceAttributesVertical>
    </Section>
  );
};
