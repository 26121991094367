import React, { useEffect } from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'utils/translation';
import { dirsActions } from '../../../../../modules/dirs/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import DirectoryTitleField from '../DirectoryTitleField';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0,
  },
}));

export interface FormValues {
  title: string | object;
  [key: string]: string | object;
}

interface OtherProps {
  onCancel: () => void;
  onSubmit: (values: FormValues, form: any) => void;
  initialValues?: Partial<FormValues>;
  multilingual?: boolean;
}

const BaseDirectoryForm = (props: OtherProps & FormikProps<FormValues>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { values, touched, errors, handleChange, handleSubmit, onCancel } =
    props;

  const {
    didCreate,
    didUpdate,
    isSubmitting,
    errors: stateErrors,
  } = useSelector((state: RootState) => state.dirs);

  useEffect(() => {
    if (didCreate || didUpdate) {
      onCancel && onCancel();
    }
    // eslint-disable-next-line
  }, [didCreate, didUpdate]);

  useEffect(() => {
    return () => {
      dispatch(dirsActions.clearErrors());
    };
    // eslint-disable-next-line
  }, []);

  const getError = (field: keyof FormValues) => {
    if (touched[field] && errors[field]) {
      return errors[field];
    }

    if (stateErrors && typeof stateErrors[field] === 'string') {
      return stateErrors[field];
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography align="center" gutterBottom variant="h3">
        {t('Update Directory')}
      </Typography>
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <DirectoryTitleField
            getError={getError}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
      <CardActions className={classes.actions}>
        <Button onClick={onCancel} variant="contained">
          {t('Cancel')}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : t('Save')}
        </Button>
      </CardActions>
    </form>
  );
};

const buildValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required('Required'),
  });
};

export const DirectoryUpdateForm = withFormik<OtherProps, FormValues>({
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      title: initialValues.title || '',
    };
  },
  validationSchema: buildValidationSchema,
  handleSubmit: (values, bag) => {
    const { onSubmit } = bag.props;
    onSubmit(values, bag);
  },
})(BaseDirectoryForm);
