import React, { useContext, useMemo, useRef, useState } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { Dialog, DialogContent } from '@mui/material';

type ModalConfig = Omit<DialogProps, 'open' | 'onClose' | 'children'>;

type ModalContextType = {
  open(element: React.ComponentType, props?: ModalConfig): void;
  close(element: React.ComponentType): void;
};

const ModalContext = React.createContext<ModalContextType | null>(null);

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error(
      'Modal context is null. Did you forget to wrap your component into ModalProvider?',
    );
  }

  return context;
};

export const ModalProvider = ({ children }: React.PropsWithChildren) => {
  const [stack, setStack] = useState<React.ComponentType[]>([]);
  const extraProps = useRef(new WeakMap());

  const value = useMemo(
    () => ({
      open: (component: React.ComponentType, props: ModalConfig = {}) => {
        extraProps.current.set(component, props);
        setStack((prev) => [...prev, component]);
      },
      close: (component: React.ComponentType) => {
        extraProps.current.delete(component);
        setStack(stack.filter((item) => item !== component));
      },
    }),
    [stack, setStack],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {stack.length > 0 &&
        stack.map((Component, index) => {
          const props = extraProps.current.get(Component) || {};

          return (
            <Dialog
              key={index}
              {...props}
              open
              onClose={() => value.close(Component)}
            >
              <DialogContent>
                <Component />
              </DialogContent>
            </Dialog>
          );
        })}
    </ModalContext.Provider>
  );
};
