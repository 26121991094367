import {
  createResourceReducer,
  ResourceState
} from '../@common/reducers/resource-reducer';
import { USERS_PREFIX } from './constants';
import { createPaginationReducer } from '../@common/reducers/pagination-reducer';
import { User } from './index';
import { usersActions } from './actions';
import produce from 'immer';
import { dirsUserAccessActions } from '../dirs-access/actions';

export const baseUserReducer = createResourceReducer(USERS_PREFIX);

export const userPaginationReducer = createPaginationReducer(USERS_PREFIX);

const initialState: ResourceState<User> = {
  items: {},
  order: [],
  isFetching: false,
  isSubmitting: false,
  didCreate: false,
  didUpdate: false,
  pendingItemId: null,
  errors: null
};

export const usersReducer: typeof baseUserReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case usersActions.statusChange.REQUEST: {
      return produce(state, draft => {
        const { user, status } = action.payload;
        const item = draft.items[user.id] as User;

        if (item) {
          item.enabled = status;
        }
      });
    }
    case usersActions.statusChange.SUCCESS: {
      return produce(state, draft => {
        const user = action.payload;

        if (draft.items[user.id]) {
          draft.items[user.id] = user;
        }
      });
    }

    case dirsUserAccessActions.delete.SUCCESS: {
      return produce(state, draft => {
        const { userId, dirId } = action.meta;
        const user = draft.items[userId] as User;

        if (user && user.directoryAccess) {
          user.directoryAccess = user.directoryAccess.filter(
            item => item.directory.id !== dirId
          );
        }
      });
    }
    default:
      return baseUserReducer(state, action);
  }
};
