import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from '../../utils/router';
import localeConfig from '../../config/locale';

const LocaleUpdater = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const lang = location.pathname.split('/');
    const currentLang = i18n.language.split('-')[0];

    if (typeof lang[1] !== undefined) {
      const code = lang[1];
      if (localeConfig.locales.includes(code) && code !== currentLang) {
        lang[1] = currentLang;
        navigate(lang.join('/'));
      }
    }
    // eslint-disable-next-line
  }, [i18n.language]);

  return null;
};

export default LocaleUpdater;
