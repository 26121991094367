import { Handler, Registry, TwoFactorProvider } from './types';

function create2FaListener() {
  const registry: Partial<Registry> = {};

  return {
    registerProvider(provider: TwoFactorProvider, handler: Handler) {
      registry[provider] = handler;
    },
    requestTwoFactorAuth(provider: TwoFactorProvider) {
      const driver = registry[provider];

      if (driver) {
        return driver();
      }
    },
  };
}

export default create2FaListener();
