import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import shallowEqual from './shallowEqual';

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!shallowEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useShallowEqualEffect(
  effect: EffectCallback,
  deps: DependencyList = [],
) {
  useEffect(effect, deps.map(useDeepCompareMemoize));
}
