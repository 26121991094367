import React, { useRef } from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { Dir } from '../../../../../modules/dirs';
import { useTranslation } from 'react-i18next';
import { useSingleDir } from '../../../../../modules/dirs/hooks';
import { IntelligentLayerForm } from 'modules/intelligent-layer/components/IntelligentLayerForm';
import { DirectoryIntelligentLayerValues } from './DirectoryIntelligentLayerValues';
import { Loader, ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';
import { DirContextProvider } from '../../../../../modules/dirs/dir-context';

interface Props {
  open: boolean;
  onClose: () => void;
  dir: Pick<Dir, 'id'>;
}

export const DirectoryIntelligenceLayer = ({
  dir,
  isFetching,
  onClose,
}: {
  dir: Dir | null;
  onClose(): void;
  isFetching: boolean;
}) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);

  const onError = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div ref={contentRef}>
      <Typography align="center" gutterBottom variant="h3">
        {t('Intelligence Layer')}
      </Typography>
      {!dir && isFetching && <Loader />}
      {dir && (
        <DirContextProvider dir={dir}>
          <IntelligentLayerForm dir={dir} />
          {dir.isIntelligentLayerEnabled && (
            <DirectoryIntelligentLayerValues
              dir={dir}
              onCancel={onClose}
              onSubmitError={onError}
            />
          )}
        </DirContextProvider>
      )}
    </div>
  );
};

export const DirectoryIntelligentLayerModal = ({
  open,
  onClose,
  dir: dirResource,
}: Props) => {
  const { dir, isFetching } = useSingleDir(dirResource.id);
  const fullScreen = useShouldModalBeFullscreen();

  return (
    <Dialog
      aria-labelledby="dir-intelligent-layer-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
    >
      <DialogContent>
        <ModalClose onClose={onClose} />
        <DirectoryIntelligenceLayer
          dir={dir}
          isFetching={isFetching}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};
