import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Toolbar, useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedLoader, Page } from '../../components';
import { useTranslation } from 'utils/translation';
import { Breadcrumbs, Drawer } from './components';
import {
  useDirs,
  useDirsActions,
  useSingleDir,
} from '../../modules/dirs/hooks';
import { useParams } from '../../utils/router';
import ResultsView from './components/Results';
import { EmptyDir } from '../../modules/dirs/components/EmptyDir';
import {
  isDir,
  mapResources,
  Resource,
  RESOURCE_TYPE,
} from '../../modules/resources';
import {
  useCurrentDir,
  useResultsViewMode,
  useUIError,
} from '../../modules/ui/hooks';
import { ViewSwitcher } from './components/ViewSwitcher/ViewSwitcher';
import { useFileList, useFilesActions } from '../../modules/files/hooks';
import { ViewMode } from '../../modules/ui/types';
import useDiskStyles from './MyDisk.styles';
import { useLazyLoading } from '../../utils/use-lazy-loading';
import ResourceModals from './components/ResourceModals';
import { Theme } from '../../theme';
import { UIErrorView } from '../../modules/ui/components/UIError';
import MyDiskProviders from './MyDiskProviders';
import { useSortableDiskContext } from './utils/sortable.context';
import { useDrawerContext } from './DrawerContext';
import config from '../../config/app';
import { Navigate } from 'react-router-dom';
import { useUiVersion } from '../../modules/ui/UserInterfaceVersionProvider';

const MyDisk = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const { t, i18n } = useTranslation();
  const { fetchDirs, readDir } = useDirsActions();
  const { fetchFiles } = useFilesActions();
  const { setCurrent, reset } = useCurrentDir();
  const { viewMode, changeViewMode } = useResultsViewMode();
  const uiError = useUIError();
  const params = useParams<'id'>();
  const dirId = Number(params.id);
  const { dir, isFetching: isSingleFetching } = useSingleDir(dirId);
  const { dirs, isFetching } = useDirs(dirId);
  const { files, isFetching: filesIsFetching } = useFileList(dirId);
  const isLoading = isFetching || filesIsFetching || isSingleFetching;
  const resources = mapResources({ dirs, files });
  const { selection: drawerSelection, setSelection: setDrawerSelection } =
    useDrawerContext();
  const { classes, drawerOptions } = useDiskStyles({
    isOpen: !!drawerSelection,
  });
  const { sort } = useSortableDiskContext();
  const { increasePage, items } = useLazyLoading<Resource>(
    {
      items: sort(resources),
    },
    [dirId],
  );

  const fetchDirectories = useCallback(() => {
    if (dirId) {
      fetchDirs({
        parentId: dirId,
      });
      readDir({
        id: dirId,
      });
      setCurrent(dirId);
    } else {
      reset();
      fetchFiles();
      fetchDirs();
    }
    // eslint-disable-next-line
  }, [dirId]);

  useEffect(() => {
    let timer = setInterval(() => {
      if (dirId) {
        fetchDirs({ parentId: dirId }, { refresh: true });
        readDir({ id: dirId }, { refresh: true });
      } else {
        fetchFiles({}, { refresh: true });
        fetchDirs({}, { refresh: true });
      }
    }, 60000);

    return () => {
      if (timer) {
        clearInterval(timer);
      }

      setDrawerSelection(null);
    };
  }, [dirId, readDir, fetchDirs, fetchFiles]);

  useEffect(() => {
    fetchDirectories();

    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, [fetchDirectories]);

  useEffect(() => {
    if (
      dir &&
      isDesktop &&
      viewMode !== ViewMode.Contextual &&
      !drawerSelection
    ) {
      setDrawerSelection({
        ...dir,
        type: RESOURCE_TYPE.DIRECTORY,
      });
    }
  }, []);

  const handleItemClick = (item: Resource) => {
    setDrawerSelection(item);

    if (isDir(item)) {
      readDir({
        id: item.id,
      });
    }
  };

  const handleYReachEnd = () => {
    increasePage();
  };

  const handleDrawerClose = useCallback(() => {
    setDrawerSelection(null);
  }, [setDrawerSelection]);

  const getResources = () => {
    if (viewMode === ViewMode.Table) {
      return resources;
    }

    return items;
  };

  const renderContent = () => {
    if (uiError) {
      return <UIErrorView error={uiError} />;
    }

    if (!isLoading && resources.length === 0) {
      return <EmptyDir />;
    }

    if (resources.length > 0) {
      return (
        <ResultsView
          viewMode={viewMode}
          selectedResource={drawerSelection}
          resources={getResources()}
          onClick={handleItemClick}
        />
      );
    }

    return null;
  };

  return (
    <Page className={classes.root} title={t('My Disk')}>
      {isLoading && <FixedLoader />}
      <div className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <Breadcrumbs />
          <ViewSwitcher onChange={changeViewMode} value={viewMode} />
        </Toolbar>
        <div className={classes.scrollbarContainer}>
          {/* @ts-ignore */}
          <PerfectScrollbar
            options={{
              suppressScrollX: true,
              wheelSpeed: 3,
            }}
            onYReachEnd={handleYReachEnd}
          >
            <div className={classes.content}>{renderContent()}</div>
          </PerfectScrollbar>
        </div>
      </div>
      <ResourceModals />
      <Drawer
        resource={drawerSelection}
        open={!!drawerSelection}
        onClose={handleDrawerClose}
        options={drawerOptions}
      />
    </Page>
  );
};

export default () => {
  const { i18n } = useTranslation();
  const { version } = useUiVersion();

  if (version === 'v2') {
    return <Navigate to="/v2" />;
  }

  return (
    <MyDiskProviders>
      <MyDisk key={i18n.language} />
    </MyDiskProviders>
  );
};
