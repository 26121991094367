import {
  createResourceAction,
  createResourceActions
} from '../@common/actions/resource-actions';
import { BASKET_PREFIX } from './constants';
import { BasketDirPayload, BasketFilePayload } from './index';

export const basketActions = {
  ...createResourceActions(BASKET_PREFIX),

  download: createResourceAction(`${BASKET_PREFIX}/DOWNLOAD`),
  addFile: createResourceAction<BasketFilePayload>(`${BASKET_PREFIX}/ADD_FILE`),
  removeFile: createResourceAction<BasketFilePayload>(
    `${BASKET_PREFIX}/REMOVE_FILE`
  ),
  addDirectory: createResourceAction<BasketDirPayload>(
    `${BASKET_PREFIX}/ADD_DIRECTORY`
  ),
  removeDirectory: createResourceAction<BasketDirPayload>(
    `${BASKET_PREFIX}/REMOVE_DIRECTORY`
  )
};
