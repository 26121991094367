import { queryCache, useMutation } from 'react-query';
import http from '../../../utils/http';
import { refetchScheduledDownloadsWithDelay } from '../../export/hooks';
import { Report } from '../types';

type ExportArgs = {
  id: Report['id'];
  params: object;
  attributes: object;
  order?: string;
  direction?: string;
};

const useReportServiceMatrix = ({
  id,
  params = {},
  attributes = {},
  order,
  direction,
}: ExportArgs) => {
  return useMutation<any, any, any>(({ format }) =>
    http
      .get<never, any>(`/api/reports/${id}/service-matrix`, {
        params: {
          params: JSON.stringify(params),
          format,
          order,
          direction,
          attributes,
        },
      })
      .then(async (data) => {
        refetchScheduledDownloadsWithDelay();

        return data;
      }),
  );
};

export default useReportServiceMatrix;
