import React, { useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import ListItem from './ListItem';
import SortableListItem from './SortableListItem';

type Item = {
  id: string | number;
};

type SortableProps<T extends Item> = {
  items: T[];
  onChange?(items: T[]): void;
  itemProps(item: T): object;
  disabled?: boolean;
};

const SortableList = <T extends Item>({
  items,
  onChange,
  itemProps,
  disabled = false,
}: SortableProps<T>) => {
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(PointerSensor),
    useSensor(TouchSensor),
  );

  function handleDragStart(event: any) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (!active || !over) {
      return;
    }

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((i) => i.id === active.id);
      const newIndex = items.findIndex((i) => i.id === over.id);

      onChange && onChange(arrayMove(items, oldIndex, newIndex));
    }

    setActiveId(null);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item) => (
          <SortableListItem
            {...item}
            {...itemProps(item)}
            disabled={disabled}
            key={item.id}
            id={item.id}
          />
        ))}
      </SortableContext>
      <DragOverlay modifiers={[restrictToVerticalAxis]}>
        {activeId ? (
          <ListItem {...items.find((i) => i.id === activeId)} id={activeId} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default SortableList;
