import React from 'react';
import { useModal } from '../../../components/v2/Modal/useModal';
import Section from '../../../components/v2/Section/Section';
import { useTranslation } from 'utils/translation';
import { refetchActiveQueries } from '../../../utils/QueryClientProvider';
import wait from '../../../utils/wait';
import { useFilesActions } from '../../files/hooks';
import { MoveForm } from '../components/MoveForm';
import { File } from 'modules/files';

function useFileMove({ id }: Pick<File, 'id'>) {
  const { t } = useTranslation();
  const modal = useModal();
  const { moveFile } = useFilesActions();

  const handleSubmit = async (dirId: number) => {
    moveFile({
      file: { id },
      dirId,
    });

    await wait(300);
    void refetchActiveQueries();
  };

  return () => {
    const Component = () => (
      <Section>
        <MoveForm
          title={t('Move file')}
          onCancel={() => modal.close(Component)}
          onSubmit={handleSubmit}
        />
      </Section>
    );

    modal.open(Component);
  };
}

export default useFileMove;
