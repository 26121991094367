import React from 'react';
import Lightbox from 'react-image-lightbox';

interface Props {
  src: string;
  onClose(): void;
}

const PhotoViewer: React.FC<Props> = ({ src, onClose }) => {
  return <Lightbox mainSrc={src} onCloseRequest={onClose} />;
};

export default PhotoViewer;
