import React, { Suspense } from 'react';
import { renderRoutes } from 'utils/render-routes';
import { Box, LinearProgress } from '@mui/material';

const Error = (props: any) => {
  const routes = props?.route?.routes || [];

  return (
    <Box component="main" sx={{ height: '100%' }}>
      <Suspense fallback={<LinearProgress />}>{renderRoutes(routes)}</Suspense>
    </Box>
  );
};

export default Error;
