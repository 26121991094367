import * as React from 'react';
import { useTranslation } from 'utils/translation';
import Button, { ButtonGroup, LockableButton } from '../Button/Button';
import Typography from '../Typography/Typography';
import { ExportFormat } from '../../../modules/reports/types';
import Spacer from '../Spacer/Spacer';

type Props = {
  onDataExportClick?(format: string): Promise<any>;
  onServiceMatrixExportClick?(format: string): Promise<any>;
  onFilesExportClick?(): void;
};

const TableActions = (props: Props) => {
  const { t } = useTranslation();
  const {
    onDataExportClick,
    onFilesExportClick: onFilesExportClickBase,
    onServiceMatrixExportClick,
  } = props;
  const hasResultExport = !!onDataExportClick;
  const hasFilesExport = !!onFilesExportClickBase;
  const hasServiceMatrix = !!onServiceMatrixExportClick;

  const createExportHandler = (format: ExportFormat) => async () => {
    if (!onDataExportClick) {
      return;
    }

    await onDataExportClick(format);
  };

  const onFilesExportClick = () => {
    if (!onFilesExportClickBase) {
      return;
    }

    onFilesExportClickBase();
  };

  const createServiceMatrixExportHandler =
    (format: ExportFormat) => async () => {
      if (!onServiceMatrixExportClick) {
        return;
      }

      await onServiceMatrixExportClick(format);
    };

  return (
    <>
      {hasResultExport && (
        <>
          <Typography variant="h3">{t('export.summary_list')}</Typography>
          <ButtonGroup>
            <LockableButton
              onClick={createExportHandler('pdf')}
              size="xs"
              fileFormat={'pdf'}
            >
              PDF
            </LockableButton>
            <LockableButton
              onClick={createExportHandler('xlsx')}
              size="xs"
              fileFormat={'excel'}
            >
              XLS
            </LockableButton>
            <LockableButton
              onClick={createExportHandler('csv')}
              size="xs"
              fileFormat={'csv'}
            >
              CSV
            </LockableButton>
          </ButtonGroup>
        </>
      )}
      {hasFilesExport && (
        <>
          <Spacer size={2} />
          <Typography variant="h3">{t('export.documents')}</Typography>
          <ButtonGroup>
            <Button onClick={onFilesExportClick} size="xs" fileFormat={'zip'}>
              ZIP
            </Button>
          </ButtonGroup>
        </>
      )}
      {hasServiceMatrix && (
        <>
          <Spacer size={2} />
          <Typography variant="h3">{t('export.service_matrix')}</Typography>
          <ButtonGroup>
            <LockableButton
              onClick={createServiceMatrixExportHandler('xlsx')}
              size="xs"
              fileFormat={'excel'}
            >
              XLS
            </LockableButton>
          </ButtonGroup>
        </>
      )}
    </>
  );
};

export default TableActions;
