import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import bytesToSize from '../../../utils/bytesToSize';
import { UploadProgress } from './UploadProgress';
import { useFilesActions, useUploadingFile } from '../hooks';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  fileId: string;
}

const FileUploadItem = (props: Props) => {
  const file = useUploadingFile(props.fileId);
  const { cancelUpload } = useFilesActions();
  const { progress } = file;

  if (!file) {
    return null;
  }

  return (
    <ListItem>
      <ListItemIcon>
        <UploadProgress progress={progress} />
      </ListItemIcon>
      <ListItemText
        primary={file.name}
        primaryTypographyProps={{ variant: 'h5' }}
        secondary={bytesToSize(file.size)}
      />
      <ListItemSecondaryAction>
        <IconButton onClick={() => cancelUpload({ id: file.id })} size="large">
          <CloseIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export { FileUploadItem };
