import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { selectFile } from '../files/selectors';
import { selectDir } from '../dirs/selectors';

export const selectBasketItems = createSelector(
  (state: RootState) => state.basket.files.map((id) => selectFile(state, id)),
  (state: RootState) =>
    state.basket.directories.map((id) => selectDir(state, id)),
  (files, directories) => {
    const selectedFiles = files.filter((item) => item);
    const selectedDirs = directories.filter((item) => item);

    return {
      files: selectedFiles,
      directories: selectedDirs,
    };
  },
);

export function isFileInBasket(state: RootState, id: number) {
  return state.basket.files.includes(id);
}

export function isDirInBasket(state: RootState, id: number) {
  return state.basket.directories.includes(id);
}
