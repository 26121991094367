import dayjs, { Dayjs, isDayjs } from 'dayjs';

export function safeParse(value: number | null | undefined) {
  try {
    if (value && !Number.isNaN(+value)) {
      return dayjs.unix(+value);
    }
  } catch (e) {
    return null;
  }

  return null;
}

export function toUTCTimestamp(date: Dayjs | null) {
  if (date && isDayjs(date)) {
    if (!date.isValid()) {
      return null;
    }

    return date.startOf('day').utc(true).unix();
  }

  return null;
}
