import React from 'react';
import { useModal } from 'components/v2/Modal/useModal';
import { refreshReportItem } from '../../reports/hooks/use-report-item';
import {
  DirectoryIntelligenceFieldsForm,
  Props,
} from '../forms/IntelligenceFieldsForm';
import { Dir } from '../../dirs';

type Options = Pick<Props, 'onSuccess'> & { enableTitle?: boolean };

export default function useDirectoryIntelligenceFieldsForm(
  id: Dir['id'],
  { onSuccess, enableTitle = false }: Options = {},
) {
  const modal = useModal();

  return () =>
    modal.open(({ close }) => (
      <DirectoryIntelligenceFieldsForm
        id={id}
        enableTitle={enableTitle}
        onSuccess={() => {
          close();
          onSuccess?.();
          refreshReportItem(id);
        }}
      />
    ));
}
