import React from 'react';
import css from './typography.module.scss';
import clsx from 'clsx';

type Props = {
  className?: string;
  variant: React.ComponentType | string;
  component?: React.ComponentType;
  color?:
    | 'primary'
    | 'inherit'
    | 'success'
    | 'error'
    | 'warning'
    | 'text-primary'
    | 'text-secondary';
  align?: 'left' | 'center' | 'right';
  uppercase?: boolean;
};

const Typography: React.FC<Props & React.ComponentProps<any>> = (props) => {
  const {
    variant = 'p',
    component: Component = variant,
    color,
    align,
    className,
    children,
    uppercase = false,
    noMargin = false,
    ...rest
  } = props;

  return (
    <Component
      {...rest}
      className={clsx(
        className,
        css.root,
        variant && css[variant],
        color && css[`color-${color}`],
        align && css[`align-${align}`],
        uppercase && css.uppercase,
        noMargin && css.noMargin,
      )}
    >
      {children}
    </Component>
  );
};

export default Typography;
