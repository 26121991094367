import { createAction, createType } from '../../@common/actions/action';
import * as actionTypes from '../constants/modal';

type ShowModalPayload = {
  id: string;
};

export const showModal = createAction<ShowModalPayload>(actionTypes.SHOW_MODAL);
export const hideModal = createAction(actionTypes.HIDE_MODAL);

export const ModalActionTypes = {
  SHOW: createType(actionTypes.SHOW_MODAL),
  HIDE: createType(actionTypes.HIDE_MODAL)
};
