import React from 'react';
import { Box, Fab, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as CreateDocumentIcon } from 'assets/icons/add-document.svg';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useCurrentDir, useUiModal } from '../../../../../modules/ui/hooks';
import {
  CREATE_DIRECTORY_MODAL_ID,
  CREATE_OBJECT_TYPE_MODAL_ID,
} from '../../../../../modules/ui';
import { AdminRole } from '../../../../../modules/auth';
import { applyFilters, Filters } from '../../../../../core';
import { useLocation } from 'utils/router';
import { useSingleDir } from '../../../../../modules/dirs/hooks';
import app from 'config/app';

const classes = {
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fab: {
    margin: 1.5,
  },
  icon: {
    marginRight: 1,
  },
  menu: {},
  menuItem: {
    minWidth: 27,
    minHeight: 4,
    py: 1,
    px: 4,
  },
};

const NavBarActions: React.FC = () => {
  const location = useLocation();
  const router = { location };
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { currentDirId } = useCurrentDir();
  const { dir } = useSingleDir(currentDirId as number);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const { show } = useUiModal();

  const handleCreateDirClick = () => {
    show(CREATE_DIRECTORY_MODAL_ID);
  };

  const handleCreateObjectTypeClick = () => {
    show(CREATE_OBJECT_TYPE_MODAL_ID);
  };

  const handleNewUploadClick = () => {
    const input = document.getElementById('file-upload-input');
    if (input) {
      input.click();
    }
  };

  const extraActions = app.enableObjectTypes
    ? [
        {
          id: 'New Object Type',
          icon: CreateDocumentIcon,
          action: handleCreateObjectTypeClick,
        },
      ]
    : [];

  const defaultActions = [
    {
      id: 'New folder',
      icon: CreateNewFolderIcon,
      action: handleCreateDirClick,
    },
    {
      id: 'New upload',
      icon: CloudUploadIcon,
      action: handleNewUploadClick,
    },
  ];

  const actions = applyFilters(Filters.navbar.actions, extraActions);
  const standardActions = applyFilters(
    Filters.navbar.defaultActions,
    defaultActions,
    {
      router,
      dir,
    },
  );

  const shouldShow = applyFilters(Filters.navbar.actionsVisibility, true, {
    router,
  });

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      <Box sx={classes.actions}>
        <Fab
          sx={classes.fab}
          color="primary"
          aria-label="add"
          size="small"
          onClick={handleClick}
        >
          <AddIcon />
        </Fab>
        <Menu
          slotProps={{
            paper: {
              sx: classes.menu,
            },
          }}
          id="navbar-actions"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {actions.map(({ id, icon: Icon, action }) => (
            <MenuItem
              sx={classes.menuItem}
              key={id}
              onClick={() => {
                action && action();
                handleClose();
              }}
            >
              <Icon style={classes.icon} />
              {t(id)}
            </MenuItem>
          ))}
          {actions.length > 0 && (
            <Box my={1} mx={4}>
              <Divider />
            </Box>
          )}
          {standardActions.map(({ id, icon: Icon, action }) => (
            <MenuItem
              sx={classes.menuItem}
              key={id}
              onClick={() => {
                action && action();
                handleClose();
              }}
            >
              <Icon sx={classes.icon} />
              {t(id)}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Divider />
    </>
  );
};

export default React.memo(() => (
  <AdminRole>
    <NavBarActions />
  </AdminRole>
));
