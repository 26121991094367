import React, { useCallback, useRef } from 'react';
import {
  useAutocomplete,
  UseAutocompleteProps,
  createFilterOptions,
} from '@mui/base/useAutocomplete';
import { Input } from '@mui/base/Input';
import { Popper } from '@mui/base/Popper';
import clsx from 'clsx';
import Icon from '../Icon/Icon';
import css from './autocomplete.module.scss';
import Button from '../Button/Button';
import { useTranslation } from 'utils/translation';
import useKeepScrollPosition from 'utils/use-keep-scroll-position';
import Loader from '../Loader';

type BaseValue = {
  label: string;
};

type Props<
  Value extends BaseValue,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined = false,
  Creatable extends boolean | undefined = false,
> = UseAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo> & {
  modifier?: 'default' | 'filter';
  emptyPlaceholder?: string;
  clearText?: string;
  border?: boolean;
  creatable?: Creatable;
  onCreate?(value: string): unknown | Promise<unknown>;
  limitTags?: number;
  getLimitTagsText?(more: number): string;
  getOptionKey?(option: Value): string | number;
  isLoading?: boolean;
};

const Autocomplete = function Autocomplete<
  Value extends BaseValue,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  Creatable extends boolean | undefined,
  FreeSolo extends boolean | undefined = false,
>(props: Props<Value, Multiple, DisableClearable, FreeSolo, Creatable>) {
  const {
    modifier = 'default',
    multiple,
    value,
    onChange,
    disableClearable = false,
    disabled = false,
    readOnly = false,
    disableCloseOnSelect = multiple,
    options = [],
    limitTags = 2,
    border = true,
    getLimitTagsText = (more) => `+${more}`,
    getOptionKey: customGetOptionKey,
    isOptionEqualToValue = (option, value) => option.label === value.label,
    emptyPlaceholder = '---',
    clearText = 'Clear',
    creatable,
    onCreate,
    inputValue: inputValueUnpacked,
    onInputChange,
    isLoading = false,
    ...other
  } = props;
  const { t } = useTranslation();
  const filterOptions = useCallback(createFilterOptions<Value>(), []);
  const listBoxRef = useRef<HTMLUListElement | null>(null);
  useKeepScrollPosition(listBoxRef);

  const {
    getRootProps,
    getInputProps,
    getPopupIndicatorProps,
    getClearProps,
    getListboxProps,
    getOptionProps,
    dirty,
    id,
    popupOpen,
    focused,
    anchorEl,
    setAnchorEl,
    getTagProps,
    groupedOptions,
  } = useAutocomplete<Value, Multiple, DisableClearable, FreeSolo>({
    ...props,
    isOptionEqualToValue,
    filterOptions,
    filterSelectedOptions: true,
    options,
    disableCloseOnSelect,
  });

  const handleCreate = (value: string) => {
    onCreate && onCreate(value);
  };

  const isFilter = modifier === 'filter';
  let placeHolder = emptyPlaceholder;
  const getOptionLabel = (option: Value) => String(option.label) || '';
  const getOptionKey = customGetOptionKey
    ? customGetOptionKey
    : (option: Value) => String(option.label);

  if (props.multiple && Array.isArray(props.value) && props.value.length > 0) {
    if (props.value.length == 1) {
      placeHolder = getOptionLabel((props.value as Value[])[0]);
    } else {
      placeHolder = props.value.length + ' geselecteerd';
    }
  }

  const hasClearIcon = !disableClearable && !disabled && dirty && !readOnly;
  const clearProps = getClearProps();
  const arrValue = Array.isArray(value) ? value : value ? [value] : [];
  const inputProps = getInputProps();
  const inputValue = (String(inputProps.value) || '').trim();
  const selectedOptions = filterOptions(arrValue as Value[], {
    inputValue: inputValue,
    getOptionLabel,
  });

  const onInputKeydown = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      if (creatable && inputValue) {
        handleCreate(String(inputProps.value));

        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }
  };

  const listboxProps = getListboxProps() as any;
  const listboxRef = (node: HTMLUListElement) => {
    listboxProps.ref(node);
    listBoxRef.current = node;
  };

  return (
    <div
      className={clsx(
        css.root,
        css[modifier],
        focused && css.focused,
        border && css.border,
      )}
    >
      <div {...getRootProps(other)} className={css.wrapper}>
        <Input
          id={id}
          ref={setAnchorEl}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeHolder}
          onKeyDown={onInputKeydown}
          slotProps={{
            root: {
              className: css['input-wrapper'],
            },
            input: {
              ...inputProps,
              className: css.input,
              style: isFilter
                ? {
                    width: placeHolder.length * 8,
                  }
                : undefined,
            },
          }}
        />
        <Button
          {...getPopupIndicatorProps()}
          startIcon={<Icon name={'far fa-chevron-down'} />}
          className={css['arrow-button']}
          variant={'text'}
          size={'xs'}
        />
      </div>
      {anchorEl ? (
        <Popper
          open={popupOpen}
          anchorEl={anchorEl}
          slotProps={{
            root: { className: css.dropdown },
          }}
          placement={'bottom-start'}
          modifiers={[
            { name: 'flip', enabled: false },
            { name: 'preventOverflow', enabled: false },
          ]}
        >
          <div className={css['dropdown-wrapper']}>
            <ul {...listboxProps} ref={listboxRef} className={css.list}>
              {hasClearIcon && (
                <li>
                  <Button
                    onClick={clearProps.onClick}
                    className={css['clear-button']}
                    variant={'text'}
                    expand={true}
                  >
                    {clearText}
                  </Button>
                </li>
              )}
              {isLoading && (
                <li className={clsx(css.item)}>
                  <span className={css['item-label']}>
                    <Loader size={14} />
                  </span>
                </li>
              )}
              {selectedOptions.map((option, index) => {
                const { onDelete } = getTagProps({ index });

                return (
                  <li
                    key={getOptionKey(option)}
                    className={clsx(css.item, css['is-selected'])}
                  >
                    <Button
                      onClick={onDelete}
                      startIcon={<Icon name={'fas fa-square-check'} />}
                      variant={'text'}
                      className={css['uncheck-button']}
                    />
                    <span className={css['item-label']}>{option.label}</span>
                  </li>
                );
              })}
              {selectedOptions.length > 0 && <hr className={css.divider} />}
              {(groupedOptions as Value[]).map((option, index) => {
                const optionProps = getOptionProps({ option, index });
                const isSelected = optionProps['aria-selected'];
                const itemProps = {
                  ...optionProps,
                  onClick: isSelected ? undefined : optionProps.onClick,
                };

                return (
                  <li
                    {...itemProps}
                    key={getOptionKey(option)}
                    className={clsx(
                      css.item,
                      optionProps['aria-selected'] && css['is-selected'],
                    )}
                  >
                    {/*
                        Selection from useAutocomplete is based on option index
                        So that we have to render all list elements to keep the correct index.
                        However, we can render the inner content only when the option is not selected,
                        so visually it'll be hidden
                       */}
                    {!optionProps['aria-selected'] && (
                      <>
                        <Icon
                          name={'far fa-square'}
                          className={css['check-icon']}
                        />
                        <span className={css['item-label']}>
                          {option.label}
                        </span>
                      </>
                    )}
                  </li>
                );
              })}
              {groupedOptions.length === 0 && selectedOptions.length === 0 && (
                <li className={css['no-options']}>
                  {creatable && inputValue ? (
                    <Button
                      onClick={() => handleCreate(inputValue)}
                      startIcon={<Icon name="fa-regular fa-plus-circle" />}
                      size="small"
                      variant="text"
                    >
                      {t('autocomplete.create_option', { value: inputValue })}
                    </Button>
                  ) : (
                    t('No results')
                  )}
                </li>
              )}
            </ul>
          </div>
        </Popper>
      ) : null}
    </div>
  );
};

export default Autocomplete;
