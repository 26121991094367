import { createAction, createType } from '../../@common/actions/action';
import { RESET_CURRENT_DIR, SET_CURRENT_DIR } from '../constants/dir';

type ShowModalPayload = {
  id: string | number;
};

export const setCurrentDir = createAction<ShowModalPayload>(SET_CURRENT_DIR);
export const resetCurrentDir = createAction(RESET_CURRENT_DIR);

export const DirActionTypes = {
  SET_CURRENT: createType(SET_CURRENT_DIR),
  RESET_CURRENT_DIR: createType(RESET_CURRENT_DIR)
};
