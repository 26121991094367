import React, { ChangeEvent } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { ModalClose } from '../../../components';
import { useShouldModalBeFullscreen } from '../../../utils/media-query';
import { useTranslation } from 'utils/translation';
import { Form, Formik } from 'formik';
import useExportFiles from '../hooks/use-export-files';
import { Report } from '../types';
import bytesToSize from '../../../utils/bytesToSize';

type Props = {
  onClose(): void;
  report: Report;
  params: object;
};

type Values = {
  type: 'flat' | 'structured';
};

const ExportFilesModal: React.FC<Props> = ({ onClose, report, params }) => {
  const { t } = useTranslation();
  const fullScreen = useShouldModalBeFullscreen();
  const { download, isLoading, loaded } = useExportFiles(report);

  const onSubmit = async (values: Values) => {
    await download({ type: values.type, params });
    onClose();
  };

  return (
    <Dialog open onClose={onClose} fullScreen={fullScreen} maxWidth="lg">
      <ModalClose onClose={onClose} />
      <DialogTitle>
        <Typography variant="h3">{t('report_export_files')}</Typography>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box
            pb={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box py={2} display="flex" justifyContent="center">
              <CircularProgress size={24} />
            </Box>
            <Typography variant="subtitle2" align="center">
              {t('report_downloading_files')}
            </Typography>
            <Box mb={1} />
            <Typography variant="caption" align="center">
              {bytesToSize(loaded, 2)}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography>{t('report_download_file_description')}</Typography>
            <Formik initialValues={{ type: 'structured' }} onSubmit={onSubmit}>
              {(props: any) => (
                <Form>
                  <Box pt={2} pb={3}>
                    <FormControl>
                      <RadioGroup
                        defaultValue={props.values.type}
                        name="type"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          props.setFieldValue(
                            'type',
                            event.currentTarget.value,
                          );
                        }}
                      >
                        <FormControlLabel
                          value="structured"
                          control={<Radio />}
                          label={t('report_download_structured_files')}
                        />
                        <FormControlLabel
                          value="flat"
                          control={<Radio />}
                          label={t('report_download_flat_files')}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box pb={2}>
                    <FormControl>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {t('report_download_files')}
                      </Button>
                    </FormControl>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ExportFilesModal;
