import React from 'react';
import Breadcrumbs from '../../../components/v2/Breadcrumbs/Breadcrumbs';
import DeviceCover from '../../../components/v2/DeviceDetails/DeviceCover';
import { DeviceTypeAssets } from '../../../components/v2/DeviceDetails/DeviceTypeAssets';
import Section from 'components/v2/Section/Section';
import Link from '../../../components/v2/Link/Link';
import Loader from '../../../components/v2/Loader';
import { route } from '../../../router';
import map from '../../../utils/map';
import { useTranslation } from 'utils/translation';
import { useNavigate } from 'utils/router';
import { useDirQuery } from '../../dirs/hooks';
import Spacer from 'components/v2/Spacer/Spacer';

const DeviceTypeDetails = ({ id }: { id: number }) => {
  const { t } = useTranslation();
  const [currentId, setCurrentId] = React.useState(id);
  const dirQuery = useDirQuery(currentId);
  const navigate = useNavigate();

  const onItemClick = (id: number) => {
    setCurrentId(id);
  };

  const breadCrumbs = map(dirQuery.data?.path, (item, index) => ({
    title: index === 0 ? t('breadcrumbs.start') : item.title,
    onClick:
      item.id === currentId
        ? undefined
        : () => {
            setCurrentId(item.id);
          },
  }));

  const onDetailsClick = () => {
    navigate(route('assets.types.details', { id }));
  };

  return (
    <>
      <Section padding="small" onClick={onDetailsClick}>
        <DeviceCover id={id} />
        <Spacer size={2} />
        <Link to={route('assets.types.details', { id })} size="small">
          {t('detail_page')}
        </Link>
      </Section>
      <Section
        padding="small"
        headerElements={[<Breadcrumbs items={breadCrumbs} limit={1} />]}
      >
        {dirQuery.isLoading ? (
          <Loader />
        ) : (
          <DeviceTypeAssets id={currentId} onItemClick={onItemClick} />
        )}
        <Spacer size={4} />
      </Section>
    </>
  );
};

export default DeviceTypeDetails;
