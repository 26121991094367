export enum ViewMode {
  Table = 'table',
  Grid = 'grid',
  Contextual = 'contextual'
}

export enum UIError {
  ErrorAccessDenied = 403,
  ErrorNotFound = 404,
  ErrorIntervalServerError = 500
}
