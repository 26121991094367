import { useCallback, useMemo } from 'react';
import {
  StringParam,
  NumberParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import { encode, decode } from './state-encoder';

export const useSortableQueryParams = () => {
  const [direction, setDirection] = useQueryParam<'asc' | 'desc'>('direction');
  const [field, setField] = useQueryParam<string>('sort');

  const changeField = useCallback(
    (nextField: string) => {
      if (nextField !== field) {
        setField(nextField);
      } else {
        setDirection(direction === 'asc' ? 'desc' : 'asc');
      }
    },
    [setField, setDirection, field, direction],
  );

  return {
    direction,
    setDirection,
    field,
    setField,
    changeField,
  };
};

export const usePaginationQueryParams = () => {
  const PageParam = withDefault(NumberParam, 1);
  const PerPageParam = withDefault(NumberParam, 25);
  const [page, setPage] = useQueryParam<number>('page', PageParam);
  const [perPage, setPerPage] = useQueryParam<number>('perPage', PerPageParam);

  return {
    page,
    setPage,
    perPage,
    setPerPage,
  };
};

export const useFiltersQueryParams = (
  defaultValues: any = {},
  name: string = 'filters',
) => {
  const FiltersParam = withDefault(StringParam, encode(defaultValues));
  const [params, setParams] = useQueryParam(name, FiltersParam);

  const decodedParams = useMemo(() => {
    return decode(params) as any;
  }, [params]);

  const updateParams = useCallback(
    (value: any) => {
      const nextValue = encode(value);

      setParams(nextValue);
    },
    [setParams],
  );

  return [decodedParams, updateParams];
};
