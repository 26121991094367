import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { groupsActions } from './actions';
import { selectFormState, selectGroups } from './selectors';
import { CreateGroupPayload, Group } from './index';
import { selectIsFetching } from '../dirs/selectors';
import { RootState } from '../../store';

export function useGroupsActions() {
  const dispatch = useDispatch();
  const fetch = () => dispatch(groupsActions.fetch.trigger());
  const create = (values: CreateGroupPayload) =>
    dispatch(groupsActions.create.trigger(values));
  const update = (group: Group) =>
    dispatch(groupsActions.update.trigger(group));
  const remove = (group: Group) =>
    dispatch(groupsActions.delete.trigger({ id: group.id }));
  const clearErrors = () => dispatch(groupsActions.clearErrors());

  return {
    fetch,
    create,
    update,
    remove,
    clearErrors
  };
}

export function useIsGroupsLoading() {
  return useSelector(selectIsFetching, shallowEqual);
}

export function useGroupsFormState() {
  return useSelector(selectFormState, shallowEqual);
}

export function useGroups(params: { search?: string } = {}) {
  return useSelector(
    (state: RootState) => selectGroups(state, params),
    shallowEqual
  );
}
