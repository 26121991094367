import React, { useContext, useMemo } from 'react';
import { Report } from '../types';

type Computed = {
  hasParams: boolean;
};

type ContextValue = Computed & Report;

const ReportContext = React.createContext<ContextValue | null>(null);

export const useOptionalReportContext = () => {
  const context = useContext(ReportContext);

  if (null === context) {
    return null;
  }

  return context;
};

export const useReportContext = () => {
  const context = useContext(ReportContext);

  if (null === context) {
    throw new Error(
      'Report provider not found. Did you forget to wrap your components in ReportContextProvider?',
    );
  }

  return context;
};

const ReportContextProvider: React.FC<React.PropsWithChildren<Report>> = ({
  children,
  ...values
}) => {
  const contextValue = useMemo(
    () => ({
      ...values,
      hasParams: values.params.length > 0,
    }),
    [values],
  );

  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
};

export default ReportContextProvider;
