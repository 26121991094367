import React from 'react';
import { Dir } from 'modules/dirs';
import { Select, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from 'theme';
import dirsConfig from 'config/dirs';
import { useDirsActions } from 'modules/dirs/hooks';
import { useTranslation } from 'utils/translation';
import { applyFilters } from '../../../../../core';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

interface Props {
  dir: Dir;
}

const useStyles = makeStyles((theme: Theme) => ({
  select: {
    fontSize: theme.spacing(1.75),
  },
  option: {
    fontSize: theme.spacing(1.75),
  },
}));

const DirectoryType: React.FC<Props> = ({ dir }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathUpdateDir } = useDirsActions();

  const handleChange = (event: SelectChangeEvent<string>) => {
    pathUpdateDir({
      id: dir.id,
      directoryType: event.target.value as string,
    });
  };

  const availableTypes = applyFilters(
    'available_directory_types',
    dirsConfig.types,
  );

  return (
    <div>
      <Select
        variant="standard"
        className={classes.select}
        id="directory-type"
        value={dir.directoryType || ''}
        onChange={handleChange}
      >
        {Object.keys(availableTypes).map((type) => (
          <MenuItem key={type} value={type} className={classes.option}>
            {t(availableTypes[type])}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export { DirectoryType };
