import http from 'utils/http';
import { File } from '../../files';
import { Dir } from '../../dirs';

export function fetch() {
  return http.get('api/basket/');
}

export function addFile(file: Pick<File, 'id'>) {
  return http.put(`api/basket/file/${file.id}`);
}

export function removeFile(file: Pick<File, 'id'>) {
  return http.delete(`api/basket/file/${file.id}`);
}

export function addDirectory(dir: Pick<Dir, 'id'>) {
  return http.put(`api/basket/directory/${dir.id}`);
}

export function removeDirectory(dir: Pick<Dir, 'id'>) {
  return http.delete(`api/basket/directory/${dir.id}`);
}

export function shareBasket(emails: string[]) {
  return http.post('api/share/basket', {
    emails
  });
}
