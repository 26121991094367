import { Dir } from '../../dirs';
import http from '../../../utils/http';
import { Field, UpdateFieldValuesPayload } from '../index';

export function fetchIntelligentFields({ id }: Pick<Dir, 'id'>) {
  return http.get<never, Field[]>(`api/intelligence-layer/${id}`);
}

export function updateIntelligentFields({
  dirId,
  fields,
}: UpdateFieldValuesPayload) {
  return http.put<never, void>(`api/intelligence-layer/${dirId}/sync`, fields);
}
