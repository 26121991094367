import React, { useCallback, useState } from 'react';
import { AdapterProps, SelectAdapterProps } from '../types';
import Autocomplete from '../../Autocomplete/Autocomplete';
import InputLabel from '../../InputLabel/InputLabel';
import css from '../filters.module.scss';
import Button from 'components/v2/Button/Button';
import Icon from '../../Icon/Icon';
import { useOptionalReportContext } from '../../../../modules/reports/context/ReportContext';
import useSelectAdapterOptionsQuery from '../../../../modules/reports/hooks/use-select-adapter-options-query';
import { useTranslation } from 'utils/translation';
import { ClickAwayListener } from '@mui/material';
import debounce from 'lodash/debounce';

type Value = {
  id: string;
  label: string;
};

type AutocompleteProps = React.ComponentProps<typeof Autocomplete>;

export const SelectAdapter = (
  props: AdapterProps<Value> &
    SelectAdapterProps & {
      hasPagination?: boolean;
      queryParams?: {
        id: string;
      };
    },
) => {
  const {
    name,
    label,
    onChange,
    onRemove,
    settings,
    value = [],
    queryParams,
    hasPagination = false,
  } = props;
  const { optionsUrl = null, dependencies = [] } = settings;
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');

  const query = useSelectAdapterOptionsQuery({
    ...queryParams,
    name,
    searchQuery,
    url: optionsUrl,
    dependencies,
    hasPagination,
  });

  const debouncedRefetch = useCallback(
    debounce(() => {
      void query.refetch();
    }, 250),
    [query.refetch],
  );

  const options = Array.from(query.data || []);

  const mapValueToSelectedOptions = () => {
    if (!query.data) {
      return [];
    }

    return query.data.filter(
      (item) => value && value.map((i) => String(i)).includes(item.id),
    );
  };

  const onInputChange: AutocompleteProps['onInputChange'] = (
    event,
    value,
    reason,
  ) => {
    if (reason !== 'reset') {
      setSearchQuery(value);
      debouncedRefetch();
    }
  };

  const resetInput = () => {
    setSearchQuery('');
  };

  return (
    <ClickAwayListener onClickAway={resetInput}>
      <div className={css.filter} onBlur={resetInput}>
        <InputLabel className={css.label}>{label}</InputLabel>
        <Autocomplete
          border={false}
          modifier={'filter'}
          clearText={t('filters.clear_filter')}
          multiple
          options={options}
          value={mapValueToSelectedOptions()}
          onChange={(event, value) => {
            onChange!(value.map((i) => i.id));
          }}
          getOptionKey={(option) => option.id}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onInputChange={onInputChange}
          inputValue={searchQuery}
        />
        <Button
          tabIndex={-1}
          onClick={onRemove ? onRemove : () => onChange?.([])}
          variant="text"
          startIcon={<Icon name="fa fa-times" />}
          className={css['remove-button']}
        />
      </div>
    </ClickAwayListener>
  );
};

export default (props: AdapterProps<Value> & SelectAdapterProps) => {
  const report = useOptionalReportContext();
  const { id = undefined } = report || {};

  return (
    <SelectAdapter
      {...props}
      queryParams={
        id
          ? {
              id,
            }
          : undefined
      }
    />
  );
};
