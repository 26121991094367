import React, { PropsWithChildren, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import { Theme, Toolbar } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  toolbar: {
    padding: 0,
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tabLabel: {},
}));

interface TabPanelProps {
  className?: string;
  dir?: string;
  index: any;
  value: any;
}

const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" align="left" {...other}>
      <Box>{children}</Box>
    </Typography>
  );
};

type Props = {
  defaultIndex?: number;
  tabs: string[];
  contents: React.ReactElement[];
};

const TabsComponent: React.FC<Props> = ({
  defaultIndex = 0,
  tabs,
  contents,
}) => {
  const [index, setIndex] = useState<number>(defaultIndex);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar} disableGutters>
        <Tabs
          value={index}
          variant="standard"
          onChange={(event, tab) => setIndex(tab)}
        >
          {tabs.map((item) => (
            <Tab key={item} label={item} className={classes.tabLabel} />
          ))}
        </Tabs>
      </Toolbar>
      {/* @ts-ignore */}
      <SwipeableViews
        index={index}
        ignoreNativeScroll={false}
        disabled
        onChangeIndex={setIndex}
      >
        {React.Children.map(contents, (child, i) => (
          <TabPanel className={classes.content} value={i} index={i}>
            {child}
          </TabPanel>
        ))}
      </SwipeableViews>
    </div>
  );
};

export default TabsComponent;
