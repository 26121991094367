import React from 'react';
import { Resource } from '../../../../components/v2/DeviceDetails/utils';

type ResourcePayload = Pick<Resource, 'id' | 'type'>;

type ContextType = {
  item: ResourcePayload | null;
  setItem(item: ResourcePayload): void;
  reset(): void;
};

const Context = React.createContext<ContextType | null>(null);

export const useDrawerSelection = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('DrawerSelectionProvider not found!');
  }

  return context;
};

const DrawerSelectionProvider = ({ children }: React.PropsWithChildren) => {
  const [item, setItem] = React.useState<ResourcePayload | null>(null);

  const contextValue = React.useMemo(() => {
    return {
      item,
      setItem,
      reset() {
        setItem(null);
      },
    };
  }, [item, setItem]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default DrawerSelectionProvider;
