import React from 'react';
import { FilesDropzone } from '../../../components';
import { useCurrentDir } from '../../ui/hooks';
import { useUploadViaDrop } from '../hooks/use-upload-via-drop';

const FileUploaderDropzone: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { currentDirId } = useCurrentDir();
  const handleDrop = useUploadViaDrop(currentDirId);

  return <FilesDropzone onDrop={handleDrop}>{children}</FilesDropzone>;
};

export { FileUploaderDropzone };
