import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Collapse, colors, Button, ListItem, Typography } from '@mui/material';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'utils/translation';
import { useQuery } from 'react-query';
import http from '../../../../../../utils/http';
import { Report } from '../../../../../../modules/reports/types';
import { useLocation } from '../../../../../../utils/router';
import FilterListIcon from '@mui/icons-material/FilterList';
import { applyFilters, Filters } from '../../../../../../core';
import RemoveReportButton from '../../../../../../modules/reports/components/RemoveReport';
import { UpdateReportNameButton } from '../../../../../../modules/reports/components/SaveReport';
import Nav from '../../Nav';

const classes = {
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    position: 'relative',
    color: colors.blueGrey[800],
    borderRadius: 0,
    padding: '12px 24px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  icon: {
    color: 'icon',
    display: 'flex',
    alignItems: 'center',
    marginRight: 1,
  },
  children: {
    padding: '8px 0',
  },
  reportItem: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 12px 8px 24px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontSize: '0.75em',
    fontWeight: 400,

    '& span': {
      lineHeight: '21px',
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
    },
  },
  activeItem: {
    fontWeight: 800,
  },
  expandIcon: {
    height: 24,
    width: 24,
    position: 'absolute',
    left: 0,
  },
};

export const ReportNav = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isOpen = location.pathname.startsWith('/report');
  const { data = [], isLoading } = useQuery(
    `api_available_reports_${i18n.language}`,
    () => http.get<never, Array<Report>>('/api/reports'),
  );

  if (isLoading || data.length === 0) {
    return null;
  }

  const navLabel = applyFilters(Filters.navbar.reportsTitle, 'Lists');

  const onReportNavItemClick = (report: Report) => {
    if (localStorage) {
      localStorage.removeItem(`_report_params_${report.id}`);
    }
  };

  return (
    <>
      <Nav.Item
        disableOpenIcon
        open={isOpen}
        title={t(navLabel)}
        icon={<FilterListIcon />}
      >
        <Box sx={classes.children}>
          {data.map((report) => (
            <Stack
              key={report.id}
              direction="row"
              sx={{
                alignItems: 'center',
                py: 0.25,
                '& .actions': {
                  opacity: 0,
                },

                '&:hover': {
                  '& .actions': {
                    opacity: 1,
                  },
                },
              }}
            >
              <Typography
                // @ts-ignore
                component={RouterLink}
                to={`/report/${report.id}`}
                onClick={() => onReportNavItemClick(report)}
                variant="button"
                sx={{
                  ...classes.reportItem,
                  ...(location.pathname.startsWith(`/report/${report.id}`)
                    ? classes.activeItem
                    : {}),
                }}
              >
                <span>{report.name}</span>
              </Typography>
              <Box
                className="actions"
                sx={{
                  pl: 0.5,
                  pr: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'no-wrap',
                }}
              >
                {report.isEditable && (
                  <UpdateReportNameButton report={report} />
                )}
                {report.isRemovable && <RemoveReportButton id={report.id} />}
              </Box>
            </Stack>
          ))}
        </Box>
      </Nav.Item>
    </>
  );
};
