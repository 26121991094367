import { get } from 'lodash';
import invariant from 'invariant';
import app from './app';

function createConfig() {
  if (app.isStorybookEnv) {
    console.log('Detected Storybook env, using fake API');

    return Object.freeze({
      url: '/_test_api_/',
      clientId: '12345',
      clientSecret: 's3cr3T',
    });
  }

  const config = Object.freeze({
    url: get(process.env, 'REACT_APP_API_URL', ''),
    clientId: get(process.env, 'REACT_APP_API_CLIENT_ID', ''),
    clientSecret: get(process.env, 'REACT_APP_API_CLIENT_SECRET', ''),
  });

  invariant(
    config.url,
    'API url is not defined. Did you forget to setup your environment?',
  );

  invariant(
    config.clientId,
    'API Client ID is not defined. Did you forget to setup your environment?',
  );

  invariant(
    config.clientSecret,
    'API Client Secret is not defined. Did you forget to setup your environment?',
  );

  return config;
}

const config = createConfig();

export default config;
