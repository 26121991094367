import React, { useState } from 'react';
import { useTranslation } from 'utils/translation';
import { useConditionalTimeout } from '../../../utils/use-timeout';
import { Button, ButtonGroup } from '../Button/Button';
import Section from '../Section/Section';
import Spacer from '../Spacer/Spacer';
import Typography from '../Typography/Typography';
import { InputOTP, InputOTPGroup, InputOTPSlot } from './InputOTP';
import { useMutation } from '@tanstack/react-query';
import Icon from '../Icon/Icon';
import css from './tw-factor.module.scss';

type Props = {
  onCancel?(): void;
  onResend?(): Promise<any>;
  onSuccess?(result: any): void;
  verification(code: string): boolean | Promise<boolean | any>;
};

const TwoFactorAuthCodeForm = ({
  onCancel,
  verification,
  onSuccess,
  onResend,
}: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const resend = useMutation({
    mutationFn: onResend || (() => Promise.resolve()),
  });

  useConditionalTimeout(
    resend.isSuccess,
    () => {
      resend.reset();
    },
    3000,
  );

  const onComplete = async (code: string) => {
    setError(false);
    const result = await verification?.(code);

    if (result) {
      return onSuccess?.(result);
    }

    setError(true);
  };

  const onCodeResend = async () => {
    await resend.mutateAsync();
  };

  return (
    <Section className={css.root}>
      <Section padding="small">
        <Typography variant="h2" align="center">
          {t('2fa_code_from_title')}
        </Typography>
        <Spacer size={2} />
        <Typography align="center">{t('2fa_code_from_description')}</Typography>
        {onResend && (
          <Typography align="center">
            {t('2fa_code_from_code_not_received')}{' '}
            {(resend.isIdle || resend.isError) && (
              <a className={css.link} onClick={onCodeResend}>
                {t('2fa_code_from_code_resend')}
              </a>
            )}
            {resend.isPending && (
              <span>{t('2fa_code_from_code_resending')}</span>
            )}
            {resend.isSuccess && (
              <span>
                {t('2fa_code_from_code_resend_success')}{' '}
                <Icon name="fa-regular fa-check" />
              </span>
            )}
          </Typography>
        )}
      </Section>
      <InputOTP
        maxLength={6}
        autoFocus
        onComplete={onComplete}
        onChange={() => setError(false)}
      >
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
      {error && (
        <>
          <Spacer size={1} />
          <Typography align="center" color="error">
            {t('2fa_code_from_error_invalid_code')}
          </Typography>
        </>
      )}
      <Spacer size={2} />
      <ButtonGroup align="center">
        <Button onClick={onCancel}>{t('form.cancel')}</Button>
      </ButtonGroup>
    </Section>
  );
};

export default TwoFactorAuthCodeForm;
