export default function map<Value extends unknown, Result extends unknown>(
  items: Value[] | undefined,
  callback: (item: Value, index: number, array: Value[]) => Result,
) {
  if (!items) {
    return [];
  }

  return items.map<Result>(callback);
}
