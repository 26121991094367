import * as React from 'react';
import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useImperativeHandle } from 'react';
import Typography from '../Typography/Typography';

type Props = React.PropsWithChildren<{
  content?: React.ReactElement;
  actionsRef?: any;
}> &
  Pick<React.ComponentProps<typeof Popper>, 'placement'>;

type PopoverContextState = {
  element: HTMLElement | null;
  content: React.ReactNode;
};

type PopOverContextActions = {
  open(args: PopoverContextState): void;
  close(): void;
};

const defaultState = {
  element: null,
  content: null,
};

const PopoverContext = React.createContext<
  PopoverContextState & PopOverContextActions
>({
  ...defaultState,
  open(args: PopoverContextState) {},
  close() {},
});

export const PopoverProvider = (props: React.PropsWithChildren) => {
  const [value, setValue] = React.useState<PopoverContextState>(defaultState);

  const contextValue = React.useMemo(
    () => ({
      ...value,
      open: (args: PopoverContextState) => setValue(args),
      close: () => setValue(defaultState),
    }),
    [setValue, value],
  );

  return (
    <PopoverContext.Provider value={contextValue}>
      {props.children}
      <ClickAwayListener onClickAway={contextValue.close}>
        <Popper
          onMouseLeave={contextValue.close}
          open={Boolean(contextValue.element)}
          anchorEl={contextValue.element}
        >
          <div>{contextValue.content}</div>
        </Popper>
      </ClickAwayListener>
    </PopoverContext.Provider>
  );
};

export function usePopover() {
  const context = React.useContext(PopoverContext);

  if (!context) {
    throw new Error('PopoverContext provider not found');
  }

  return context;
}

function Popover(props: Props) {
  const { children, content, actionsRef } = props;
  const context = usePopover();

  useImperativeHandle(actionsRef, () => ({
    close() {
      context.close();
    },
  }));

  const handlePopoverOpen = (event: any) => {
    context.open({
      element: event.currentTarget,
      content,
    });
  };

  return <Typography onMouseEnter={handlePopoverOpen}>{children}</Typography>;
}

export default Popover;
