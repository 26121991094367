import { useNavigate } from 'react-router-dom';
import {
  Sidebar,
  SidebarNotice,
  SidebarWrapper,
} from '../../components/v2/Sidebar/Sidebar';
import React from 'react';
import SidebarSearch from '../../components/v2/Sidebar/SidebarSearch';
import BasketButton from '../../modules/basket/components/BasketButton';
import TreeNavigation from '../../modules/v2/files/components/TreeNavigation/TreeNavigation';
import SystemSummary from 'modules/ui/components/SystemSummary';
import Spacer from 'components/v2/Spacer/Spacer';
import { useLocation } from 'utils/router';
import { useTranslation } from 'utils/translation';

const FilesSidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path: string) => {
    return location.pathname.startsWith(`/v2/${path}`);
  };

  const handleSearch = (search: string) => {
    navigate(search ? `/v2/search/${search}` : '/v2');
  };

  return (
    <Sidebar>
      <SidebarSearch
        isActive={isActive('search')}
        placeholder={t('report_search')}
        onChange={handleSearch}
      />
      <SidebarWrapper>
        <BasketButton />
      </SidebarWrapper>
      <Spacer size={2} />
      <TreeNavigation />
      <Spacer size={2} />
      <SidebarNotice>
        <SystemSummary />
      </SidebarNotice>
    </Sidebar>
  );
};

export default FilesSidebar;
