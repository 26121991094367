import { useQuery } from '@tanstack/react-query';
import { File } from '../index';
import { shareFile } from '../service/api';

const useFileShareQuery = (file: File) => {
  return useQuery({
    queryFn: () => shareFile(file),
    queryKey: ['api_file_share', file.id],
  });
};

export default useFileShareQuery;
