import React from 'react';
import { useCurrentUser } from '../hooks';
import { Avatar, IconButton } from '@mui/material';
import getInitials from 'utils/getInitials';
import { useTranslation } from 'utils/translation';
import Menu from '@mui/material/Menu';
import InputIcon from '@mui/icons-material/Input';
import PersonIcon from '@mui/icons-material/Person';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';
import { logout } from '../actions';
import { EditProfileModal } from '../../users/containers/EditProfileModal';

const classes = {
  button: {
    paddingRight: 0,
  },
  avatar: {
    height: 40,
    width: 40,
    fontSize: 16,
    letterSpacing: '0.1em',
    backgroundColor: 'transparent',
    border: '2px solid',
  },
  menuItem: {
    py: 1,
    minWidth: '200px',
  },
  listIcon: {
    marginRight: 0.5,
  },
};

const CurrentUserActions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const [isProfileEdit, setIsProfileEdit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const fullName = [user.firstName, user.lastName].filter((i) => i).join(' ');

  const handleLogout = () => {
    dispatch(logout.trigger());
  };

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleShowProfileEdit() {
    setIsProfileEdit(true);
    setAnchorEl(null);
  }

  function handleCloseProfileEdit() {
    setIsProfileEdit(false);
  }

  return (
    <div>
      <IconButton sx={classes.button} onClick={handleClick} size="large">
        <Avatar variant="circular" sx={classes.avatar}>
          {getInitials(fullName || user.email)}
        </Avatar>
      </IconButton>
      <Menu
        id="app-current-user-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem sx={classes.menuItem} onClick={handleShowProfileEdit}>
          <PersonIcon sx={classes.listIcon} />
          {t('Edit Profile')}
        </MenuItem>
        <MenuItem sx={classes.menuItem} onClick={handleLogout}>
          <InputIcon sx={classes.listIcon} />
          {t('Logout')}
        </MenuItem>
      </Menu>
      {isProfileEdit && (
        <EditProfileModal open={true} onClose={handleCloseProfileEdit} />
      )}
    </div>
  );
};

export { CurrentUserActions };
