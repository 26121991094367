import React from 'react';
import { Dialog, DialogContent, Theme, Typography } from '@mui/material';
import { SwipeableTabs } from 'components';
import { useTranslation } from 'utils/translation';
import { useModal } from '../../../components/v2/Modal/useModal';
import Section from '../../../components/v2/Section/Section';
import { UpdateProfile } from './UpdateProfile';
import { ProfilePasswordChange } from './ProfilePasswordChange';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import { ModalClose } from '../../../components';
import { useShouldModalBeFullscreen } from '../../../utils/media-query';

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    padding: theme.spacing(0, 1),
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export const useEditProfileModal = () => {
  const modal = useModal();
  const Component = () => (
    <Section>
      <EditProfileForm onClose={() => modal.close(Component)} />
    </Section>
  );

  return () => modal.open(Component, { size: 'small' });
};

const EditProfileForm = ({ onClose }: Pick<Props, 'onClose'>) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography align="center" variant="h3" gutterBottom>
        {t('Edit Profile')}
      </Typography>
      <SwipeableTabs
        tabs={
          <Tabs value={0} variant="standard">
            <Tab label={t('Account information')} />
            <Tab label={t('Change password')} />
          </Tabs>
        }
      >
        <div className={classes.tab}>
          <UpdateProfile onCancel={onClose} />
        </div>
        <div className={classes.tab}>
          <ProfilePasswordChange onCancel={onClose} />
        </div>
      </SwipeableTabs>
    </>
  );
};

export const EditProfileModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const fullScreen = useShouldModalBeFullscreen();
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="profile-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <DialogContent>
        <ModalClose onClose={onClose} />
        <EditProfileForm onClose={onClose} />
      </DialogContent>
    </Dialog>
  );
};
