import { useState } from 'react';
import { Resource } from '../index';

export interface ContextMenuState {
  resource: Nullable<Resource>;
  x: Nullable<number>;
  y: Nullable<number>;
}

export default function useResourceContextMenu() {
  const initialState = {
    resource: null,
    x: null,
    y: null
  };

  const [state, setState] = useState<ContextMenuState>(initialState);

  const openContextMenu = (data: ContextMenuState) => {
    setState(data);
  };

  const closeContextMenu = () => {
    setState(initialState);
  };

  return {
    openContextMenu,
    closeContextMenu,
    isOpen: state.resource !== null,
    resource: state.resource,
    x: state.x,
    y: state.y
  };
}
