import React from 'react';
import css from './form-control.module.scss';
import { FormControl as FormControlBase } from '@mui/base/FormControl';

type Props = React.ComponentProps<typeof FormControlBase> & {
  as?: React.ComponentType;
};

const FormControl = ({ children, as, ...props }: Props) => {
  const Component = as || FormControlBase;

  return (
    <Component {...props} className={css.root}>
      {children}
    </Component>
  );
};

export default FormControl;
