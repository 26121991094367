import React from 'react';
import { FormControl, InputLabel } from '@mui/material';
import { FieldProps } from './types';
import DirectoryFilePicker from 'components/DirectoryFilePicker';
import { File } from 'modules/files';
import { useDirContext } from 'modules/dirs/dir-context';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  control: {
    maxWidth: '400px',
  },
}));

export const FileField: React.FC<FieldProps> = ({
  value,
  errorMessage,
  name,
  form,
}) => {
  const classes = useStyles();
  const dir = useDirContext();

  const handleChange = (file: File) => {
    form.setFieldValue(name, file ? file.id : null, false);
  };

  if (!dir) {
    return null;
  }

  return (
    <FormControl
      className={classes.control}
      fullWidth
      variant="outlined"
      error={!!errorMessage}
    >
      {errorMessage && <InputLabel>{errorMessage}</InputLabel>}
      <DirectoryFilePicker
        directoryId={dir.id}
        value={value}
        onChange={handleChange}
      />
    </FormControl>
  );
};
