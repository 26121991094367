import React from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useTranslation } from 'utils/translation';
import Empty from '../../../components/v2/Empty/Empty';
import {
  useBasketActions,
  useBasketItems,
  useIsBasketDownloading,
} from '../hooks';
import { CircularProgress, Stack } from '@mui/material';
import { ShareBasketView } from './ShareBasketView';
import Tabs from 'components/v2/Tabs/Tabs';
import Spacer from 'components/v2/Spacer/Spacer';
import Button from 'components/v2/Button/Button';
import Icon from 'components/v2/Icon/Icon';

export function BasketTabs() {
  const { t } = useTranslation();
  const { download } = useBasketActions();
  const { files, directories } = useBasketItems();
  const isDownloading = useIsBasketDownloading();
  const hasResources = files.length + directories.length > 0;

  const handleDownloadClick = () => {
    download();
  };

  const DownloadIcon = isDownloading ? CircularProgress : GetAppIcon;

  return (
    <Tabs defaultValue={0} fullWidth text disabled={!hasResources}>
      <Tabs.List>
        <Tabs.Tab value={0}>{t('Download')}</Tabs.Tab>
        <Tabs.Tab value={1}>{t('E-mail')}</Tabs.Tab>
        <Tabs.Descriptor
          title={t('Basket')}
          icon={<Icon name="fa-solid fa-copy" />}
        />
      </Tabs.List>
      {hasResources ? (
        <>
          <Tabs.Panel value={0}>
            <Spacer size={2} />
            <Stack alignItems="flex-start">
              <Button disabled={isDownloading} onClick={handleDownloadClick}>
                {t('Download')}
              </Button>
            </Stack>
            <Spacer size={2} />
          </Tabs.Panel>
          <Tabs.Panel value={1}>
            <Spacer size={2} />
            <ShareBasketView useModernButton />
          </Tabs.Panel>
        </>
      ) : (
        <>
          <Spacer size={4} />
          <Empty message={t('basket_empty_message')} />
          <Spacer size={2} />
        </>
      )}
    </Tabs>
  );
}
