import React from 'react';

interface Props {
  config: any;
}

const GlobalDynamicStyles: React.FC<Props> = ({ config }) => {
  React.useLayoutEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `:root {
      --primary-color: ${config.newWebColor}
    }`;
    document.head.appendChild(style);

    return () => {
      if (style) {
        document.head.removeChild(style);
      }
    };
  }, [config]);

  return null;
};

export default GlobalDynamicStyles;
