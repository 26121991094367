import clsx from 'clsx';
import * as React from 'react';
import { OTPInput, OTPInputContext, REGEXP_ONLY_DIGITS } from 'input-otp';
import css from './inout-otp.module.scss';

const InputOTP = React.forwardRef<
  React.ElementRef<typeof OTPInput>,
  React.ComponentPropsWithoutRef<typeof OTPInput>
>(({ className, ...props }, ref) => (
  <OTPInput
    ref={ref}
    pattern={REGEXP_ONLY_DIGITS}
    containerClassName={css.root}
    {...props}
  />
));

const InputOTPGroup = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={css.group} {...props} />
));

const InputOTPSlot = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & { index: number }
>(({ index, className, ...props }, ref) => {
  const inputOTPContext = React.useContext(OTPInputContext);
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];

  return (
    <div
      ref={ref}
      className={clsx(css.slot, isActive && css.active, className)}
      {...props}
    >
      {char}
      {hasFakeCaret && (
        <div className={css.caret}>
          <div className={css.caretInner} />
        </div>
      )}
    </div>
  );
});

const InputOTPSeparator = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ ...props }, ref) => (
  <div ref={ref} role="separator" {...props}>
    {' - '}
  </div>
));

export { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator };
