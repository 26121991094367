import { useQuery, queryCache } from 'react-query';
import http from '../../../utils/http';
import i18n, { useTranslation } from 'utils/translation';
import { Report } from '../types';

type Args = {
  id: string;
};

const useReportSpec = ({ id }: Args) => {
  const { i18n } = useTranslation();

  return useQuery(`${id}_report_spec_${i18n.language}`, () =>
    http.get<never, Report>(`/api/reports/${id}/spec`),
  );
};

export const refetchReport = ({ id }: Args) => {
  return queryCache.refetchQueries(`${id}_report_spec_${i18n.language}`);
};

export default useReportSpec;
