import { queryCache, useMutation, useQuery } from 'react-query';
import http from 'utils/http';
import scheduleMicrotask from 'utils/schedule-microtask';
import wait from 'utils/wait';
import { Item } from './types';

export function useScheduledDownloads() {
  return useQuery(
    'api_scheduled_downloads',
    () => http.get<never, Item[]>('/api/export'),
    {
      refetchInterval: 15_000,
    },
  );
}

export function refetchScheduledDownloads() {
  return queryCache.refetchQueries('api_scheduled_downloads');
}

export async function refetchScheduledDownloadsWithDelay(
  delay: number = 1000,
  times = 10,
) {
  await refetchScheduledDownloads();

  scheduleMicrotask(async () => {
    for (let i = 1; i < times; i++) {
      await wait(delay);
      await refetchScheduledDownloads();
    }
  });
}

export function useCancelDownload() {
  return useMutation((id: Item['id']) =>
    http.delete(`/api/export/cancel/${id}`),
  );
}
