import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  onClose(): void;
}

const ModalClose: React.FC<Props> = ({ onClose }) => {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 0.5,
        padding: 2,
        zIndex: 50,
      }}
      onClick={onClose}
      size="large"
    >
      <CloseIcon />
    </IconButton>
  );
};

export default ModalClose;
