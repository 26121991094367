import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { fromEvent } from 'file-selector';
import makeStyles from '@mui/styles/makeStyles';
import { DropHere } from './components';
import { useUserIsAdmin } from '../../modules/auth/hooks';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  dropZone: {
    flex: 1,
    outline: 0,
  },
}));

interface Props {
  className?: string;
  onDrop<T extends File>(
    acceptedFiles: T[],
    rejectedFiles: FileRejection[],
    event: DropEvent,
  ): void;
}

function traverseDirectory(entry: any) {
  const reader = entry.createReader();

  return new Promise((resolve, reject) => {
    const iterationAttempts: any[] = [];

    const dirEntry = {
      type: 'directory',
      name: entry.name,
      path: entry.fullPath,
    };

    iterationAttempts.push(new Promise((resolve) => resolve(dirEntry)));

    function readEntries() {
      reader.readEntries(
        (entries: any) => {
          if (!entries.length) {
            resolve(Promise.all(iterationAttempts));
          } else {
            iterationAttempts.push(
              Promise.all(
                entries
                  .filter((entry: any) => entry.isDirectory)
                  .map((dirEntry: any) => {
                    return traverseDirectory(dirEntry);
                  }),
              ),
            );
            readEntries();
          }
        },
        (error: any) => reject(error),
      );
    }
    readEntries();
  });
}

export const getFilesFromEvent = (event: any) => {
  if (!event.dataTransfer) {
    return fromEvent(event);
  }

  const items = event.dataTransfer.items || [];
  const directories: any = [];

  Array.from(items).forEach((file: any) => {
    const isDir =
      file.webkitGetAsEntry &&
      file.webkitGetAsEntry() &&
      file.webkitGetAsEntry().isDirectory;

    if (isDir) {
      const entry = file.webkitGetAsEntry();
      directories.push(traverseDirectory(entry));
    }
  });

  return Promise.all([Promise.all(directories), fromEvent(event)]).then(
    ([dirs, files]) => [...dirs, ...files],
  );
};

const FilesDropzone: React.FC<PropsWithChildren<Props>> = ({
  className,
  onDrop,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const canUpload = useUserIsAdmin();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !canUpload,
    // @ts-ignore
    getFilesFromEvent,
  });
  const rootProps = getRootProps();
  delete rootProps.onClick;
  const baseProps = getInputProps();
  const inputProps = {
    ...baseProps,
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div
        className={clsx({
          [classes.dropZone]: true,
        })}
        {...rootProps}
      >
        <input id="file-upload-input" {...inputProps} />
        {children}
        {isDragActive && <DropHere />}
      </div>
    </div>
  );
};

export default FilesDropzone;
