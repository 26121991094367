import React, { HTMLProps } from 'react';
import css from './link.module.scss';
import clsx from 'clsx';

type Props = {
  color?: 'primary';
  icon: React.ReactNode;
} & HTMLProps<HTMLAnchorElement>;

const NativeLink = ({ color, icon, href, children, ...rest }: Props) => (
  <a
    {...rest}
    href={href}
    className={clsx(css.root, color && css[`color-${color}`])}
  >
    {icon && <span className={css.icon}>{icon}</span>}
    {children}
  </a>
);

export default NativeLink;
