import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import GetAppIcon from '@mui/icons-material/GetApp';
import MessageIcon from '@mui/icons-material/Email';
import {
  useBasketActions,
  useBasketItems,
  useIsBasketDownloading,
} from '../hooks';
import { Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '../../../theme';
import { ShareBasketView } from './ShareBasketView';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      align="center"
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    fontSize: 24,
    marginRight: 4,
  },
  tabs: {
    '& button span': {
      flexDirection: 'row',
    },
  },
  tabIcon: {
    margin: theme.spacing(0, 1),
    marginBottom: '0 !important',
  },
}));

export function BasketTabs() {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { download } = useBasketActions();
  const { files, directories } = useBasketItems();
  const isDownloading = useIsBasketDownloading();
  const hasResources = files.length + directories.length > 0;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleDownloadClick = () => {
    download();
  };

  if (!hasResources) {
    return null;
  }

  const DownloadIcon = isDownloading ? CircularProgress : GetAppIcon;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          className={classes.tabs}
          value={value}
          variant="standard"
          onChange={handleChange}
          aria-label="basket actions"
        >
          <Tab
            icon={<GetAppIcon className={classes.tabIcon} />}
            iconPosition="start"
            label="Download"
            {...a11yProps(0)}
          />
          <Tab
            icon={<MessageIcon className={classes.tabIcon} />}
            iconPosition="start"
            label="E-mail"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      {/* @ts-ignore */}
      <SwipeableViews
        animateHeight
        disableLazyLoading
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Button
            color="primary"
            variant="outlined"
            size="large"
            disabled={isDownloading}
            onClick={handleDownloadClick}
          >
            <DownloadIcon size={24} className={classes.icon} /> Download
          </Button>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ShareBasketView />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
