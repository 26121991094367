import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Resource } from '../../modules/resources';

type DrawerContextType = {
  selection: Nullable<Resource>;
  setSelection: Dispatch<SetStateAction<DrawerContextType['selection']>>;
};

const DrawerContext = React.createContext<DrawerContextType | null>(null);

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('DrawerContext Provider missing.');
  }

  return context;
};

export const DrawerContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [selection, setSelection] = useState<Nullable<Resource>>(null);

  const value = React.useMemo(
    () => ({
      selection,
      setSelection,
    }),
    [selection, setSelection],
  );

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};
