import React from 'react';
import { useDirectoryActions } from '../../../modules/dirs/components/DirectoryActions';
import useContextMenu from '../ContextMenu/useContextMenu';
import Table from '../Table/Table';
import { DirectoryResource, FileResource, Resource } from './utils';
import { useFileActions } from 'modules/files/components/FileActions';

const FileNameCellContent = ({ original, icon, name }: FileResource) => {
  const actions = useFileActions(original);
  const { ref } = useContextMenu<HTMLDivElement>({ actions });

  return (
    <Table.CellContent ref={ref}>
      {icon}
      {name}
    </Table.CellContent>
  );
};

const DirectoryNameCellContent = ({
  original,
  icon,
  name,
}: DirectoryResource) => {
  const actions = useDirectoryActions(original);
  const { ref } = useContextMenu<HTMLDivElement>({ actions });

  return (
    <Table.CellContent ref={ref}>
      {icon}
      {name}
    </Table.CellContent>
  );
};

export const NameCellContent = (item: Resource) => {
  const { type } = item;

  if (type === 'file') {
    return <FileNameCellContent {...item} />;
  }

  if (type === 'directory') {
    return <DirectoryNameCellContent {...item} />;
  }
};
