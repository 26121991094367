import { handleActions } from 'redux-actions';
import { dirsUserBlockedActions } from '../actions';
import produce from 'immer';
import { DirUserAccessPayload } from '../index';
import { Action } from '../../@common/actions/action';
import { User } from '../../users';

interface UsersAccessState {
  [key: number]: {
    users: User[];
    isFetching: boolean;
  };
}

const initialState: UsersAccessState = {};
const actions = dirsUserBlockedActions;

export const blockedUsers = handleActions(
  {
    [actions.fetch.TRIGGER]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId } = action.payload;
        if (!draft[dirId]) {
          draft[dirId] = {
            isFetching: false,
            users: []
          };
        }
      });
    },
    [actions.fetch.REQUEST]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId } = action.payload;
        if (draft[dirId]) {
          draft[dirId].isFetching = true;
        }
      });
    },
    [actions.fetch.SUCCESS]: (
      state,
      action: Action<User[], DirUserAccessPayload>
    ) => {
      return produce(state, draft => {
        const { dirId } = action.meta;
        draft[dirId].users = action.payload;
      });
    },
    [actions.fetch.FULFILL]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId } = action.payload;
        if (draft[dirId]) {
          draft[dirId].isFetching = false;
        }
      });
    },
    [actions.create.REQUEST]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId, user } = action.payload;
        if (draft[dirId]) {
          draft[dirId].users.push(user);
        }
      });
    },
    [actions.delete.REQUEST]: (state, action: any) => {
      return produce(state, draft => {
        const { dirId, userId } = action.payload;
        if (draft[dirId]) {
          draft[dirId].users = draft[dirId].users.filter(
            item => item.id !== userId
          );
        }
      });
    }
  },
  initialState
);
