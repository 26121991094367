import {
  call,
  put,
  takeLatest,
  takeEvery,
  delay
} from '@redux-saga/core/effects';
import { basketActions } from './actions';
import {
  fetch,
  addFile,
  removeFile,
  addDirectory,
  removeDirectory
} from './service/api';
import { createApiHandler } from '../@common/sagas/create-api-handler';
import { BasketDirPayload, BasketFilePayload } from './index';
import { downloadWithAuth } from '../@common/sagas/file-downloader';
import { Action } from '../@common/actions/action';

const handleBasketFetch = createApiHandler({
  routine: basketActions.fetch,
  provider: fetch
});

const handleAddFileToBasket = createApiHandler({
  routine: basketActions.addFile,
  provider: ({ file }: BasketFilePayload) => addFile(file)
});

const handleRemoveFileFromBasket = createApiHandler({
  routine: basketActions.removeFile,
  provider: ({ file }: BasketFilePayload) => removeFile(file)
});

const handleAddDirectoryToBasket = createApiHandler({
  routine: basketActions.addDirectory,
  provider: ({ dir }: BasketDirPayload) => addDirectory(dir)
});

const handleRemoveDirectoryFromBasket = createApiHandler({
  routine: basketActions.removeDirectory,
  provider: ({ dir }: BasketDirPayload) => removeDirectory(dir)
});

function* handleBasketDownload(action: Action) {
  const url = '/api/download/basket';

  try {
    yield call(downloadWithAuth, url);
  } catch (e) {
    yield put(basketActions.download.failure(action.payload, action.meta));
  } finally {
    yield put(basketActions.download.fulfill());
  }
}

function* handleBasketRefresh(action: any) {
  yield delay(100);
  yield handleBasketFetch(action);
}

export default function*() {
  yield takeLatest(
    [
      basketActions.fetch.TRIGGER,
      basketActions.addFile.SUCCESS,
      basketActions.addDirectory.SUCCESS
    ],
    handleBasketRefresh
  );
  yield takeLatest(basketActions.download.TRIGGER, handleBasketDownload);
  yield takeEvery(basketActions.addFile.TRIGGER, handleAddFileToBasket);
  yield takeEvery(basketActions.removeFile.TRIGGER, handleRemoveFileFromBasket);
  yield takeEvery(
    basketActions.addDirectory.TRIGGER,
    handleAddDirectoryToBasket
  );
  yield takeEvery(
    basketActions.removeDirectory.TRIGGER,
    handleRemoveDirectoryFromBasket
  );
}
