import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '../../../components/v2/Badge/Badge';
import Button from '../../../components/v2/Button/Button';
import Icon from '../../../components/v2/Icon/Icon';
import { useBasketActions, useBasketItems } from '../hooks';
import useBasketModal from '../hooks/useBasketModal';

const BasketButton = () => {
  const { t } = useTranslation();
  const { fetchBasket } = useBasketActions();
  const { files, directories } = useBasketItems();
  const openBasketModal = useBasketModal();
  const itemsCount = files.length + directories.length;

  useEffect(() => {
    fetchBasket();
    // eslint-disable-next-line
  }, []);

  const onClick = () => {
    openBasketModal();
  };

  return (
    <Badge expand badgeContent={itemsCount}>
      <Button
        onClick={onClick}
        startIcon={<Icon name={'fas fa-files'} />}
        expand
      >
        {t('Basket')}
      </Button>
    </Badge>
  );
};

export default BasketButton;
