import React from 'react';
import clsx from 'clsx';
import {
  isDir,
  isFile,
  isInheritedDir,
  isObjectTypeDir,
  isUniqueObjectDir,
  Resource,
} from '../../../../modules/resources';
import { ReactComponent as InheritedDirIcon } from '../../../../assets/icons/inherited.svg';
import { ReactComponent as UniqueDirIcon } from '../../../../assets/icons/object-id.svg';
import { ReactComponent as ObjectTypeIcon } from '../../../../assets/icons/object.svg';
import FolderIcon from '@mui/icons-material/Folder';
import { FileIcon } from '../../../../components';

interface Props {
  item: Resource;
  className?: string;
  style?: any;
}

const ResourceIcon: React.FC<Props> = ({ item, className, style }) => {
  if (isDir(item)) {
    if (isObjectTypeDir(item)) {
      return (
        <ObjectTypeIcon
          style={style}
          className={clsx('MuiSvgIcon-root', className)}
        />
      );
    }

    if (isUniqueObjectDir(item)) {
      return (
        <UniqueDirIcon
          style={style}
          className={clsx('MuiSvgIcon-root', className)}
        />
      );
    }

    if (isInheritedDir(item)) {
      return (
        <InheritedDirIcon
          style={style}
          className={clsx('MuiSvgIcon-root', className)}
        />
      );
    }

    return (
      <FolderIcon
        style={style}
        className={clsx('MuiSvgIcon-root', className)}
      />
    );
  }

  if (isFile(item)) {
    return (
      <FileIcon style={style} className={className} type={item.fileType} />
    );
  }

  return <FolderIcon className={className} style={style} />;
};

export default ResourceIcon;
