export function encode<T extends object>(state = {} as T): string {
  try {
    if (Object.keys(state).length === 0) {
      return '';
    }
    return btoa(JSON.stringify(state));
  } catch {
    return '';
  }
}

export function decode<T extends object>(stateRaw: string): T {
  try {
    return JSON.parse(atob(stateRaw));
  } catch {
    return {} as T;
  }
}

export default {
  decode,
  encode,
};
