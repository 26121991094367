import React, { useCallback, useEffect } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { FormValues, ResourceForm } from './ResourceForm';
import { useDispatch } from 'react-redux';
import { FileResource } from '../../../../../modules/resources';
import { FormikBag } from 'formik';
import {
  useFilesActions,
  useFilesFormState,
} from '../../../../../modules/files/hooks';
import { ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';

interface Props {
  open: boolean;
  onClose: () => void;
  resource: FileResource;
}

export const FileFormModal = ({ resource, open, onClose }: Props) => {
  const dispatch = useDispatch();
  const fullScreen = useShouldModalBeFullscreen();
  let form = React.useRef<FormikBag<any, FormValues>>();
  const { clearErrors, updateFile } = useFilesActions();
  const { didUpdate, errors } = useFilesFormState();

  useEffect(() => {
    if (didUpdate) {
      onClose();
    }

    if (errors && form.current) {
      form.current.setErrors(errors);
      form.current.setSubmitting(false);
    }

    return () => {
      clearErrors();
    };
    // eslint-disable-next-line
  }, [didUpdate, errors]);

  const onSubmit = useCallback(
    (values: FormValues, formBag: any) => {
      form.current = formBag;
      updateFile({
        id: resource.id,
        filename: values.name,
      });
    },
    // eslint-disable-next-line
    [dispatch],
  );

  const getInitialValues = () => {
    return {
      // @ts-ignore
      name: resource.filename,
    };
  };

  const validate = (values: FormValues) => {
    if (String(values.name).trim().length === 0) {
      return {
        name: 'Required',
      };
    }
  };

  return (
    <Dialog
      aria-labelledby="file-form-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <DialogContent>
        <ModalClose onClose={onClose} />
        <ResourceForm
          onCancel={onClose}
          onSubmit={onSubmit}
          validate={validate}
          initialValues={getInitialValues()}
        />
      </DialogContent>
    </Dialog>
  );
};
