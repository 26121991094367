import React, { ChangeEvent, useState } from 'react';
import { ChipInput } from 'components';
import { validate } from 'validate.js';
import { useTranslation } from 'utils/translation';
import {
  Theme,
  Typography,
  Button as ButtonLegacy,
  CircularProgress,
  Chip,
  Stack,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import makeStyles from '@mui/styles/makeStyles';
import Icon from '../../../components/v2/Icon/Icon';
import { useBasketShare } from '../hooks/use-basket-share';
import { Button } from 'components/v2/Button/Button';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  error: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  actions: {
    display: 'flex',
    margin: theme.spacing(2, 0, 4),
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: theme.spacing(2, 0),
  },
  resultItem: {
    margin: theme.spacing(2, 1),
  },
  sendButton: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

const ShareBasketView = ({
  useModernButton = false,
}: {
  useModernButton?: boolean;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [inputValue, setInputVal] = useState('');
  const [error, setError] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const {
    isLoading,
    response,
    request: shareRequest,
    reset,
  } = useBasketShare();

  const isValidEmail = (email: string) => {
    const errors = validate(
      { email },
      {
        email: {
          presence: true,
          isEmail: {
            message: 'Must be a valid e-mail',
          },
        },
      },
    );

    return Object.keys(errors || {}).length === 0;
  };

  const handleReset = () => {
    reset();
    setInputVal('');
    setError(false);
    setEmails([]);
  };

  const onChange = (event: any, emailAddress: string[]) => {
    const nextEmails = Array.from(emailAddress)
      .map((item) => (item || '').trim())
      .filter((item) => {
        const isValid = isValidEmail(item);

        if (!isValid) {
          setInputVal(item);
          setError(true);
        }

        return isValid;
      });

    setEmails(nextEmails);
  };

  const handleDeleteEmail = (email: string) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputVal(event.target.value);
    setError(false);
  };

  const handleButtonClick = () => {
    shareRequest(emails);
  };

  if (response) {
    const emails = response?.emails || [];
    const count = Array.from(emails).length;

    return (
      <Stack alignItems="center">
        <Typography>
          {t('Sent share notification to {{ count }} receiver(s)', { count })}
        </Typography>
        <div>
          {emails.map((email: string) => (
            <Chip className={classes.resultItem} label={email} key={email} />
          ))}
        </div>
        <div>
          <ButtonLegacy
            onClick={handleReset}
            variant="outlined"
            color="primary"
            size="small"
          >
            {t('Ok')}
          </ButtonLegacy>
        </div>
      </Stack>
    );
  }

  if (!useModernButton && isLoading) {
    return (
      <div className={classes.container}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {error && (
        <Typography className={classes.error} variant="inherit" color="error">
          {t('{{ email }} is not a valid e-mail address', {
            email: inputValue,
          })}
        </Typography>
      )}
      <div className={classes.actions}>
        {useModernButton ? (
          <Button
            disabled={emails.length === 0}
            isBusy={isLoading}
            onClick={handleButtonClick}
          >
            {t('Share')}
          </Button>
        ) : (
          <ButtonLegacy
            className={classes.sendButton}
            disabled={emails.length === 0 || isLoading}
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleButtonClick}
          >
            <SendIcon /> {t('Share')}
          </ButtonLegacy>
        )}
      </div>
      <ChipInput
        value={emails}
        onChange={onChange}
        placeholder={t('Enter e-mail address')}
      />
      <div className={classes.info}>
        <Typography>
          {t('add_multiple_email_addresses_with_enter_key')}
        </Typography>
      </div>
    </div>
  );
};

export { ShareBasketView };
