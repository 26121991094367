import i18n from './translation';

const formatters: {
  [key: string]: Intl.NumberFormat;
} = {};

export function formatNumber(value: number | bigint) {
  const locale = i18n.language;
  const formatter =
    formatters[locale] ||
    (formatters[locale] = new Intl.NumberFormat(locale, { style: 'decimal' }));

  return formatter.format(value);
}
