import { createContext } from 'react';
import { SnackBar } from './types';

type ContextType = {
  add(item: SnackBar): void;
  remove(item: SnackBar): void;
};

const SnackbarContext = createContext<ContextType | null>(null);

export default SnackbarContext;
