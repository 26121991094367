import React, { memo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'utils/translation';
import config from 'config/app';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiBreadcrumbs-separator': {
        marginLeft: '3px',
        marginRight: '3px',
        fontSize: '0.75em',
        lineHeight: 1,
      },
    },
    link: {
      fontSize: '0.75em',
      lineHeight: 1,
      color: 'inherit',
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

type Breadcrumb = {
  id: number;
  title: string;
};

function DirectoryBreadcrumbs({ items = [] }: { items: Breadcrumb[] }) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (items.length === 0) {
    return (
      <Breadcrumbs className={classes.root}>
        <Link className={classes.link} to={config.myDiskPath}>
          <Typography variant="inherit" color="textPrimary">
            {t('My Disk')}
          </Typography>
        </Link>
      </Breadcrumbs>
    );
  }

  return (
    <Breadcrumbs className={classes.root} maxItems={5} aria-label="breadcrumb">
      <Link className={classes.link} to={config.myDiskPath}>
        <Typography variant="inherit">{t('My Disk')}</Typography>
      </Link>
      {items.map((item: Breadcrumb) => (
        <Link
          key={item.id}
          className={classes.link}
          to={`/directory/${item.id}`}
        >
          <Typography variant="inherit">{item.title}</Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
}

export default memo(DirectoryBreadcrumbs);
