import { takeLatest } from '@redux-saga/core/effects';
import {
  dirsGroupAccessActions,
  dirsUserAccessActions,
  dirsUserBlockedActions
} from './actions';
import { createApiHandler } from '../@common/sagas/create-api-handler';
import {
  getGroups,
  getUsers,
  createGroupAccess,
  removeGroupAccess,
  createUserAccess,
  removeUserAccess,
  getBlockedUsers,
  createBlockedUser,
  removeBlockedUser
} from './service/api';
import {
  AccessGroupsResponse,
  DirGroupAccessPayload,
  AccessUsersResponse,
  BlockedUsersResponse
} from './index';

const handleFetchGroups = createApiHandler({
  routine: dirsGroupAccessActions.fetch,
  provider: async (data: Pick<DirGroupAccessPayload, 'dirId'>) => {
    const groupsAccess = ((await getGroups(
      data
    )) as unknown) as AccessGroupsResponse[];

    return groupsAccess.map(item => item.group);
  }
});

const handleCreateGroupAccess = createApiHandler({
  routine: dirsGroupAccessActions.create,
  provider: createGroupAccess
});

const handleDeleteGroupAccess = createApiHandler({
  routine: dirsGroupAccessActions.delete,
  provider: removeGroupAccess
});

const handleFetchUsers = createApiHandler({
  routine: dirsUserAccessActions.fetch,
  provider: async (data: Pick<DirGroupAccessPayload, 'dirId'>) => {
    const usersAccess = ((await getUsers(
      data
    )) as unknown) as AccessUsersResponse[];

    return usersAccess.map(item => item.user);
  }
});

const handleCreateUserAccess = createApiHandler({
  routine: dirsUserAccessActions.create,
  provider: createUserAccess
});

const handleDeleteUserAccess = createApiHandler({
  routine: dirsUserAccessActions.delete,
  provider: removeUserAccess
});

const handleFetchBlockedUsers = createApiHandler({
  routine: dirsUserBlockedActions.fetch,
  provider: async (data: Pick<DirGroupAccessPayload, 'dirId'>) => {
    const usersAccess = ((await getBlockedUsers(
      data
    )) as unknown) as BlockedUsersResponse[];

    return usersAccess.map(item => item.user);
  }
});

const handleCreateBlockedUser = createApiHandler({
  routine: dirsUserBlockedActions.create,
  provider: createBlockedUser
});

const handleDeleteBlockedUser = createApiHandler({
  routine: dirsUserBlockedActions.delete,
  provider: removeBlockedUser
});

export default function*() {
  // Groups
  yield takeLatest(dirsGroupAccessActions.fetch.TRIGGER, handleFetchGroups);
  yield takeLatest(
    dirsGroupAccessActions.create.TRIGGER,
    handleCreateGroupAccess
  );
  yield takeLatest(
    dirsGroupAccessActions.delete.TRIGGER,
    handleDeleteGroupAccess
  );

  // Users
  yield takeLatest(dirsUserAccessActions.fetch.TRIGGER, handleFetchUsers);
  yield takeLatest(
    dirsUserAccessActions.create.TRIGGER,
    handleCreateUserAccess
  );
  yield takeLatest(
    dirsUserAccessActions.delete.TRIGGER,
    handleDeleteUserAccess
  );

  // Blocked Users
  yield takeLatest(
    dirsUserBlockedActions.fetch.TRIGGER,
    handleFetchBlockedUsers
  );
  yield takeLatest(
    dirsUserBlockedActions.create.TRIGGER,
    handleCreateBlockedUser
  );
  yield takeLatest(
    dirsUserBlockedActions.delete.TRIGGER,
    handleDeleteBlockedUser
  );
}
