import React from 'react';
import pick from 'lodash/pick';
import Slider from '@mui/material/Slider';
import { FieldProps } from './types';

const SliderField: React.FC<FieldProps> = ({
  value,
  name,
  form,
  settings = {},
}) => {
  const attributes = pick(settings, ['min', 'max', 'step']);

  const handleChange = (event: Event, value: number | number[]) => {
    form.setFieldValue(name, value);
  };

  return (
    <Slider
      {...attributes}
      name={name}
      value={+(value || 0)}
      onChange={handleChange}
      valueLabelDisplay="auto"
    />
  );
};

export { SliderField };
