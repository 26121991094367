interface DownloadArgs {
  url: string;
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export default function({ url }: DownloadArgs) {
  return new Promise(resolve => {
    if (isSafari) {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
    } else {
      const frame = document.createElement('iframe');
      frame.style.display = 'none';
      frame.src = url;
      document.body.appendChild(frame);
    }

    setTimeout(() => resolve(true), 2000);
  });
}
