import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Box,
} from '@mui/material';
import { Page } from 'components';

const classes = {
  root: {
    padding: 3,
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  imageContainer: {
    marginTop: 6,
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    width: '560px',
    maxHeight: '300px',
    height: 'auto',
  },
  buttonContainer: {
    marginTop: 6,
    display: 'flex',
    justifyContent: 'center',
  },
};

type Props = {
  img: string;
  title: string;
  description: string;
};

const ErrorPage = (props: Props) => {
  const { title, description, img } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Page sx={classes.root} title="Error 401">
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        {title}
      </Typography>
      <Typography align="center" variant="subtitle2">
        {description}
      </Typography>
      <Box sx={classes.imageContainer}>
        <Box
          component="img"
          alt="Under development"
          sx={classes.image}
          src={img}
        />
      </Box>
      <Box sx={classes.buttonContainer}>
        <Button
          color="primary"
          component={RouterLink}
          to="/"
          variant="outlined"
        >
          Back to home
        </Button>
      </Box>
    </Page>
  );
};

export default ErrorPage;
