import { Action } from '../../@common/actions/action';
import { handleActions } from 'redux-actions';
import { ResourceModalActionTypes, ShowResourceModalPayload } from '../actions';
import { ResourceModal } from '..';
import { Resource } from '../../resources';

interface State {
  activeModalId: Nullable<ResourceModal>;
  resource: Nullable<Resource>;
}

const initialState: State = {
  activeModalId: null,
  resource: null
};

export const resourceModalReducer = handleActions(
  {
    [ResourceModalActionTypes.SHOW]: (
      state,
      action: Action<ShowResourceModalPayload>
    ) => {
      const { id, resource } = action.payload;
      return {
        ...state,
        activeModalId: id,
        resource
      };
    },
    [ResourceModalActionTypes.HIDE]: state => {
      return {
        ...state,
        activeModalId: null,
        resource: null
      };
    }
  },
  initialState
);
