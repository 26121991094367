import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const selectAppSetting = (state: RootState, name: string) => {
  return state.ui.app[name];
};

type AppFeatures = {
  translatableDirectories: boolean;
  deviceGroups: boolean;
  devices: boolean;
  usersDirectoryPermissions: boolean;
  usersApiCredentials: boolean;
  usersExtraApiFilters: boolean;
};

const defaultFeatures: Partial<AppFeatures> = {
  translatableDirectories: false,
};

export const useAppFeatures = () => {
  const features = useSelector<RootState, AppFeatures>(
    (state) => state.ui.app.features,
  );

  return useMemo(
    () => ({
      ...defaultFeatures,
      ...features,
    }),
    [features],
  );
};
